import { useEffect, useState, useContext } from 'react'
import { FormGroup, Row, Col, Button, Alert } from 'react-bootstrap'
import sheetsConfig from '../../../../../src/config/sheetsConfig'
import {
  ShowAlertCPF,
  ShowAlertCep,
  ShowAlertEmail,
  ShowAlertTel,
  ShowAlertCNPJ
} from './cadastro-alerts'
import {
  SelectSexo,
  SelectTipo,
  SelectCategoria,
  ImputName,
  SobrenomeImput,
  NomeEmpresaInput,
  InputCPForCNPJ,
  TelefoneImput,
  EmailImput,
  CepImput,
  EnderecoImput,
  NumeroImput,
  ComplementoImput,
  BairroImput,
  MunicipiosSelect,
  EstadosSelect,
  filterMunicipiosByCidade
} from './cadastro-fields.jsx'
import { useTodosMunicipiosBrasil } from '../../../../utils/municipios.js'
import { getUfValueByName } from '../../../../utils/address-utils'
import { AuthContext } from '../../../../../src/context/AuthContext'
import Banner from '../../../pages/CadastroGeral/Banner'
import AlertMessage from '../../../pages/CadastroPlotagem/AlertMessage'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SalvarAssociacaoClienteServico } from '../../../../../src/components/pages/AssociacaoClienteServico/associacao-cliente-servico'
import { handleForm } from '../../../../../src/components/pages/CadastroGeral/cadastro/cadastro-fields.js'
import {
  getAllUfs,
  lookupAddress,
  lookupCep,
  checkEnderecoExists,
  verificaBairroNaCidade,
  getUfNameByValue
} from '../../../../utils/address-utils.js'
import { SelectAllFromTablePorId } from '../../../../../src/backend/model'

function CadastroGeral({
  showCategory = true,
  isPlotagem = false,
  isCorte = false,
  isModelagem = false,
  isCadastroGeral = false,
  isEditing = false,
  tipoPessoaProp = ''
}) {
  CadastroGeral.propTypes = {
    showCategory: PropTypes.bool,
    isPlotagem: PropTypes.bool,
    isCorte: PropTypes.bool,
    isModelagem: PropTypes.bool,
    isCadastroGeral: PropTypes.bool,
    isEditing: PropTypes.bool,
    tipoPessoaProp: PropTypes.string
  }

  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [isValidCpf, setisValidCpf] = useState(false)
  const [tipoPessoa, setTipoPessoa] = useState('')
  const [categoria, setCategoria] = useState('')
  const [sexo, setSexo] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [originalEmail, setOriginalEmail] = useState('')
  const [isValidEmail, setisValidEmail] = useState(false)
  const [cep, setCep] = useState('')
  const [isValidCep, setisValidCep] = useState(false)
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [uf, setUf] = useState('')
  const [ufs, setUfs] = useState([])
  const [isBlurred, setisBlurred] = useState(false)
  const [showAlert_cpf, setShowAlertCPF] = useState(false)
  const [showAlert_cep, setShowAlertCep] = useState(false)
  const [showAlert_email, setShowAlertEmail] = useState(false)
  const [showAlert_tel, setShowAlertTel] = useState(false)
  const [buscarCepAtivo, setBuscarCepAtivo] = useState(false)
  const [Municipios, setMunicipios] = useState([])
  const [codigoUf, setCodigoUf] = useState('')
  const [todosMunicipios, setTodosMunicipios] = useState([])
  const [isValidTel, setisValidTel] = useState(false)
  const [MunicipiosParaOSelectSource, setMunicipiosParaOSelectSource] = useState([])
  const [title, setTitle] = useState('')
  const [pegarCep, setpegarCep] = useState(false)
  const [cidadeSelecionada, setCidadeSelecionada] = useState('')
  const [searchType, setsearchType] = useState('')
  const [CepUpdateCounter, setCepUpdateCounter] = useState(0)
  const [selectedSector, setSelectedSector] = useState('')
  const [isValidCnpj, setisValidCnpj] = useState(false)
  const [showAlert_cnpj, setShowAlertCNPJ] = useState(false)
  const [nomeEmpresa, setNomeEmpresa] = useState('')
  const [clienteEditando, setClienteEditando] = useState(null)
  const [clienteId, setClienteId] = useState('')
  const [clienteData, setClienteData] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertGravando, setShowAlertGravando] = useState(false)

  const [AddressUpdateStatus, setAddressUpdateStatus] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [tipoPessoa2, setTipoPessoa2] = useState('')
  const [showAlertGravandoState, setShowAlertGravandoState] = useState(false)
  const dispatch = useDispatch()

  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  const handleTipoPessoaChange = e => {
    setTipoPessoa2(e.target.value)
  }

  useEffect(() => {
    if (tipoPessoaProp) {
      setTipoPessoa(tipoPessoaProp)
    }
  }, [tipoPessoaProp])

  useEffect(() => {
    async function setAddress(data) {
      setpegarCep(true)
      setEndereco(data.logradouro)
      setBairro(data.bairro)
      setUf(getUfNameByValue(data.uf))
      setCidade({ cidade: data.localidade, source: 'function' })
      setCidadeSelecionada(data.localidade)
      const municipiosDaCidade = filterMunicipiosByCidade(todosMunicipios, data.localidade, ufs)

      setMunicipios(municipiosDaCidade)
      setTitle(data.localidade)
      setpegarCep(false)
    }

    async function fetchData() {
      if (isValidCep && cep.length === 9 && searchType === 'cep') {
        const addressPromise = lookupAddress(cep)
        addressPromise
          .then(data => setAddress(data))
          .catch(error => console.error('erro aqui ', error))
      }
    }

    fetchData()
  }, [cep, isValidCep, searchType])

  useEffect(() => {
    const fetchCep = async () => {
      try {
        if (searchType === 'address' && endereco !== '' && cidade.cidade !== '' && uf !== '') {
          const data = await lookupCep(getUfValueByName(uf), cidade.cidade, endereco, setpegarCep)
          if (!data.erro) {
            setCep(data[0].cep)
            setBairro(data[0].bairro)
            setisValidCep(true)
          }
        }
      } catch (error) {
        setisValidCep(false)
        setCep('')
        console.error(`Erro ao buscar CEP: ${error}`)
      }
    }

    if (endereco !== '' && cidade && cidade.cidade !== '' && uf !== '') {
      fetchCep()
    }
  }, [endereco, cidade, uf, searchType])

  useEffect(() => {
    async function handleMunicipioChange(cidade) {
      const enderecoExists = await checkEnderecoExists(uf, cidade.cidade, endereco)
      if (!enderecoExists) {
        setEndereco('')
        setCep('')
      } else {
        if (cep !== '') {
          const cepExists = await checkEnderecoExists(uf, cidade.cidade, cep)
          if (!cepExists) {
            setCep('')
          }
        }
      }
      if (bairro !== '') {
        const bairroExiste = await verificaBairroNaCidade(uf, cidade.cidade, bairro)
        if (!bairroExiste) {
          setBairro('')
        }
      }
    }

    if (cidade && cidade.source === 'user') handleMunicipioChange(cidade)
  }, [cidade])

  const todosMunicipiosBrasil = useTodosMunicipiosBrasil() // Mover a chamada do hook para fora do useEffect

  useEffect(() => {
    setTodosMunicipios(todosMunicipiosBrasil)
    const ufs = getAllUfs()

    setUfs(ufs)
  }, [todosMunicipiosBrasil])

  useEffect(() => {
    setMunicipiosParaOSelectSource(() => {
      const nomesMunicipios = Municipios.map(municipio => [municipio.nome])
      return nomesMunicipios
    })
    setTitle('Escolha a cidade')
  }, [Municipios, cidadeSelecionada])

  useEffect(() => {
    if (isPlotagem) {
      setCategoria('Plotagem')
      setSelectedSector('plotagem')
    }
  }, [isPlotagem])

  useEffect(() => {
    if (isCorte) {
      setCategoria('Corte')
      setSelectedSector('cortes')
    }
  }, [isCorte])

  useEffect(() => {
    if (isModelagem) {
      setCategoria('Modelagem')
      setSelectedSector('modelagem')
    }
  }, [isModelagem])

  useEffect(() => {
    if (isCadastroGeral) {
      setSelectedSector('cadastroClienteG')
    }
  }, [isCadastroGeral])
  useEffect(() => {
    if (clienteEditando) {
      setNome(clienteEditando.nome || '')
      setSobrenome(clienteEditando.sobrenome || '')
      setCpf(clienteEditando.cpf || '')
      setCnpj(clienteEditando.cnpj || '')
      setTipoPessoa(clienteEditando.tipoPessoa || '')
      setTipoPessoa2(clienteEditando.tipoPessoa || '') // Adicione esta linha
      setCategoria(clienteEditando.categoria_cliente || '') // Altere para clienteEditando.categoria_cliente
      setSexo(clienteEditando.sexo || '')
      setTelefone(clienteEditando.telefone || '')
      setEmail(clienteEditando.email || '')
      setOriginalEmail(clienteEditando.email || '')
      setCep(clienteEditando.cep || '')
      setEndereco(clienteEditando.endereco || '')
      setNumero(clienteEditando.numero || '')
      setComplemento(clienteEditando.complemento || '')
      setBairro(clienteEditando.bairro || '')
      setCidade({ cidade: clienteEditando.cidade || '', source: null })
      setUf(clienteEditando.uf || '')
      setNomeEmpresa(clienteEditando.empresa || '') // Altere para clienteEditando.empresa
    }
  }, [clienteEditando])

  function tratarSelectAllFromTablePorId(dados) {
    const clientesData = dados
    const clientes = {
      id: clientesData[0].id,
      data: clientesData[0].data,
      nome: clientesData[0].Nome,
      empresa: clientesData[0].Empresa,
      telefone: clientesData[0].Tel,
      sobrenome: clientesData[0].Sobrenome,
      cpf: clientesData[0].CPF,
      tipoPessoa: clientesData[0].Tipo_Pessoa,
      sexo: clientesData[0].Sexo,
      email: clientesData[0].Email,
      cep: clientesData[0].Cep,
      endereco: clientesData[0].Endereco,
      numero: clientesData[0].Numero,
      complemento: clientesData[0].Complemento,
      bairro: clientesData[0].Bairro,
      cidade: clientesData[0].Cidade,
      uf: clientesData[0].UF,
      cnpj: clientesData[0].CNPJ,
      categoria_cliente: clientesData[0].Categoria_Cliente
    }
    return clientes
  }

  useEffect(() => {
    if (isEditing) {
      const fetchClientes = async () => {
        const clientes = await SelectAllFromTablePorId({
          table: 'Cad_G_Cliente',
          Id: clienteId,
          colsTab1: 'A:R',
          selectedSector: 'cadastroClienteG',
          VerificarDados: tratarSelectAllFromTablePorId
        })
        setClienteEditando(clientes)
      }
      fetchClientes()
    }
  }, [clienteId, isEditing])

  const handleSubmit = async e => {
    e.preventDefault()
    setShowAlertGravandoState(true)
    let rota = 'cadastroGeral'
    if (isEditing) {
      rota = 'funcoesGenericas'
    }

    const retorno = await handleForm(
      isEditing,
      {
        Nome: nome,
        Sobrenome: sobrenome,
        Empresa: nomeEmpresa,
        CPF: cpf,
        CNPJ: cnpj,
        Tipo_Pessoa: tipoPessoa,
        Categoria_Cliente: categoria,
        Sexo: sexo,
        Tel: telefone,
        Email: email,
        Cep: cep,
        Endereco: endereco,
        Numero: numero,
        Complemento: complemento,
        Bairro: bairro,
        Cidade: cidade ? cidade[0] : null,
        UF: uf ? getUfValueByName(uf) : null,
        Data: new Date(),
        tenantId: tenantId,
        rota_base: RotaBase
      },
      clienteId,
      clienteData,
      null,
      null,
      {},
      sheetsConfig[selectedSector].sheetUrl,
      sheetsConfig[selectedSector].tabs.clientes,
      'enviar',
      '',
      { rota: rota }
    )

    if (!isEditing) {
      console.log('Retorno de handleForm:', retorno)

      await salvarAssociacaoServicoCliente({
        isPlotagem,
        isCorte,
        isModelagem,
        retorno,
        isAssocieParaTodos: true,
        tenantId
      })
    }

    setShowAlertGravandoState(false)
    setShowAlert(true)

    setTimeout(() => {
      setShowAlert(false)
      setNome('')
      setSobrenome('')
      setBairro('')
      setNumero('')
      setCep('')
      setCpf('')
      setTelefone('')
      setEmail('')
      setComplemento('')
      setEndereco('')
      setCategoria('')
      setTipoPessoa('')
    }, 2000)
  }

  return (
    <div className="d-flex align-items-center form-container" style={{ height: '100vh' }}>
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        {isPlotagem && (
          <div className="title-container">
            <Banner title="PLOTAGEM" />
          </div>
        )}
        {isCorte && (
          <div className="title-container">
            <Banner title="CORTE" />
          </div>
        )}
        {isModelagem && (
          <div className="title-container">
            <Banner title="MODELAGEM" />
          </div>
        )}
        {isCadastroGeral && (
          <div className="title-container">
            <Banner title="CADASTRO GERAL" />
          </div>
        )}
        <h2 style={{ marginBottom: '3rem' }}>
          {showCategory ? 'NOVO CADASTRO GERAL' : 'CADASTRO CLIENTE'}
          {isEditing && (
            <>
              {' - '}
              <span style={{ color: '#F25C05' }}>Modo de Edição</span>
            </>
          )}
        </h2>

        <ShowAlertCPF
          message="Por favor, insira um número de CPF válido"
          showAlert_cpf={showAlert_cpf}
          isBlurred={isBlurred}
          setShowAlertCPF={setShowAlertCPF}
        />
        <ShowAlertCNPJ
          message="Por favor, insira um número de CNPJ válido"
          showAlert_cnpj={showAlert_cnpj}
          isBlurred={isBlurred}
          setShowAlertCNPJ={setShowAlertCNPJ}
        />
        <ShowAlertCep
          message="Por favor, insira um número de CEP válido"
          showAlert_cep={showAlert_cep}
          isBlurred={isBlurred}
          setShowAlertCep={setShowAlertCep}
        />
        <ShowAlertEmail
          message="Por favor, insira um endereço de email válido!"
          showAlert_email={showAlert_email}
          isBlurred={isBlurred}
          setShowAlertEmail={setShowAlertEmail}
        />
        <ShowAlertTel
          message="Por favor, insira um número de telefone válido"
          showAlert_tel={showAlert_tel}
          isBlurred={isBlurred}
          setShowAlertTel={setShowAlertTel}
        />
        {showAlertGravandoState && (
          <AlertMessage
            message={isEditing ? 'Editando dados' : 'Gravando dados'}
            size="x-large"
            customHeight={100}
            variant1="primary"
            variant2="light"
            variantDefault="success"
            isBlinking={false}
          />
        )}
        {showAlert && (
          <Alert variant="success" onClose={handleCloseAlert} dismissible>
            <Alert.Heading>
              {isEditing ? 'Cadastro editado com sucesso!' : 'Cadastro realizado com sucesso!'}
            </Alert.Heading>
          </Alert>
        )}

        <FormGroup>
          <Row>
            <Col md={4} className="mb-3">
              <SelectTipo
                initialValue={clienteEditando && clienteEditando.tipoPessoa}
                tipoPessoa={tipoPessoa}
                setTipoPessoa={setTipoPessoa}
              />
            </Col>

            {showCategory && (
              <Col md={4} className="mb-3">
                <SelectCategoria
                  initialValue={clienteEditando && clienteEditando.categoria_cliente}
                  categoria={categoria}
                  setCategoria={setCategoria}
                />
              </Col>
            )}
            {tipoPessoa === 'Pessoa Física' && (
              <Col md={3} className="mb-3">
                <SelectSexo
                  initialValue={clienteEditando && clienteEditando.sexo}
                  sexo={sexo}
                  setSexo={setSexo}
                />
              </Col>
            )}
          </Row>

          <Row>
            {tipoPessoa === 'Pessoa Física' && (
              <>
                <Col md={4} className="mb-3">
                  <ImputName
                    initialValue={clienteEditando && clienteEditando.nome}
                    nome={nome}
                    setNome={setNome}
                    setSobrenome={setSobrenome}
                    setSexo={setSexo}
                  />
                </Col>
                <Col md={5} style={{ maxWidth: '349px' }} className="mb-3">
                  <SobrenomeImput
                    initialValue={clienteEditando && clienteEditando.sobrenome}
                    sobrenome={sobrenome}
                    setSobrenome={setSobrenome}
                  />
                </Col>
                <Col md={3} style={{ maxWidth: '173px' }} className="mb-3">
                  <InputCPForCNPJ
                    initialValue={
                      clienteEditando &&
                      (clienteEditando.tipoPessoa === 'Pessoa Física'
                        ? clienteEditando.cnpj
                        : clienteEditando.cpf)
                    }
                    cpf={cpf}
                    setCpf={setCpf}
                    cnpj={cnpj}
                    setCnpj={setCnpj}
                    isValidCpf={isValidCpf}
                    setisValidCpf={setisValidCpf}
                    isValidCnpj={isValidCnpj}
                    setisValidCnpj={setisValidCnpj}
                    isBlurred={isBlurred}
                    setisBlurred={setisBlurred}
                    setShowAlertCPF={setShowAlertCPF}
                    showAlert_cpf={showAlert_cpf}
                    setShowAlertCNPJ={setShowAlertCNPJ}
                    showAlert_cnpj={showAlert_cnpj}
                    tipoPessoa={tipoPessoa}
                  />
                </Col>
              </>
            )}

            {tipoPessoa === 'Pessoa Jurídica' && (
              <>
                <Col md={6} className="mb-3">
                  <NomeEmpresaInput
                    initialValue={clienteEditando && clienteEditando.empresa}
                    nomeEmpresa={nomeEmpresa}
                    setNomeEmpresa={setNomeEmpresa}
                  />
                </Col>

                <Col md={3} style={{ maxWidth: '173px' }} className="mb-3">
                  <InputCPForCNPJ
                    initialValue={
                      clienteEditando &&
                      (clienteEditando.tipoPessoa === 'Pessoa Jurídica'
                        ? clienteEditando.cnpj
                        : clienteEditando.cpf)
                    }
                    cpf={cpf}
                    setCpf={setCpf}
                    cnpj={cnpj}
                    setCnpj={setCnpj}
                    isValidCpf={isValidCpf}
                    setisValidCpf={setisValidCpf}
                    isValidCnpj={isValidCnpj}
                    setisValidCnpj={setisValidCnpj}
                    isBlurred={isBlurred}
                    setisBlurred={setisBlurred}
                    setShowAlertCPF={setShowAlertCPF}
                    showAlert_cpf={showAlert_cpf}
                    setShowAlertCNPJ={setShowAlertCNPJ}
                    showAlert_cnpj={showAlert_cnpj}
                    tipoPessoa={tipoPessoa}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col md={4} className="mb-3">
              <TelefoneImput
                initialValue={clienteEditando && clienteEditando.telefone}
                telefone={telefone}
                setTelefone={setTelefone}
                isValidTel={isValidTel}
                setisValidTel={setisValidTel}
                setisBlurred={setisBlurred}
                setShowAlertTel={setShowAlertTel}
              />
            </Col>
            <Col md={7} className="mb-3">
              <EmailImput
                initialValue={clienteEditando && clienteEditando.email}
                email={email}
                setEmail={setEmail}
                isValidEmail={isValidEmail}
                setisValidEmail={setisValidEmail}
                setisBlurred={setisBlurred}
                setShowAlertEmail={setShowAlertEmail}
                originalEmail={originalEmail}
                setOriginalEmail={setOriginalEmail}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2} className="mb-3" style={{ display: 'flex' }}>
              <CepImput
                initialValue={clienteEditando && clienteEditando.cep}
                cep={cep}
                setCep={setCep}
                isValidCep={isValidCep}
                setisValidCep={setisValidCep}
                setShowAlertCep={setShowAlertCep}
                showAlert_cep={showAlert_cep}
                setisBlurred={setisBlurred}
                isBlurred={isBlurred}
                setpegarCep={setpegarCep}
                setCepUpdateCounter={setCepUpdateCounter}
                setAddressUpdateStatus={setAddressUpdateStatus}
                setsearchType={setsearchType}
                searchType={searchType}
              />
            </Col>
          </Row>

          <Row>
            <Col md={5} className="mb-3">
              <EnderecoImput
                initialValue={clienteEditando && clienteEditando.endereco}
                endereco={endereco}
                setEndereco={setEndereco}
                setUf={setUf}
                setCep={setCep}
                setpegarCep={setpegarCep}
                setAddressUpdateStatus={setAddressUpdateStatus}
                setsearchType={setsearchType}
              />
            </Col>
            <Col md={2} className="mb-3">
              <NumeroImput
                initialValue={clienteEditando && clienteEditando.numero}
                numero={numero}
                setNumero={setNumero}
              />
            </Col>
            <Col md={4} className="mb-3">
              <ComplementoImput
                initialValue={clienteEditando && clienteEditando.complemento}
                complemento={complemento}
                setComplemento={setComplemento}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="mb-3">
              <BairroImput
                initialValue={clienteEditando && clienteEditando.bairro}
                bairro={bairro}
                setBairro={setBairro}
              />
            </Col>
            <Col md={4} className="mb-3">
              <MunicipiosSelect
                setCidadeSelecionada={setCidadeSelecionada}
                initialValue={clienteEditando && clienteEditando.cidade}
                Municipios={Municipios}
                title={title}
                setTitle={setTitle}
                cidadeState={cidade}
                MunicipiosParaOSelectSource={MunicipiosParaOSelectSource}
                setMunicipiosParaOSelectSource={setMunicipiosParaOSelectSource}
                cidadeSelecionada={cidadeSelecionada}
                setCidade={setCidade}
                pegarCep={pegarCep}
              />
            </Col>
            <Col md={5} className="mb-3">
              <EstadosSelect
                initialValue={clienteEditando && clienteEditando.uf}
                uf={uf}
                setUf={setUf}
                ufs={ufs}
                Municipios={Municipios}
                setMunicipios={setMunicipios}
                setTitle={setTitle}
                todosMunicipios={todosMunicipios}
                cidade={cidade}
                setpegarCep={setpegarCep}
                setCidadeSelecionada={setCidadeSelecionada}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12} className="mb-3">
              <Button
                variant="success"
                type="submit"
                style={{ width: '40%' }}
                onClick={handleSubmit}
              >
                Cadastrar
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroGeral

async function salvarAssociacaoServicoCliente({
  isPlotagem,
  isCorte,
  isModelagem,
  retorno,
  isAssocieParaTodos,
  tenantId
}) {
  let id_servico

  if (isAssocieParaTodos) {
    id_servico = [1, 3, 4]
  } else {
    if (isPlotagem) {
      id_servico = 4
    }
    if (isCorte) {
      id_servico = 3
    }
    if (isModelagem) {
      id_servico = 1
    }
  }

  let cadastroDados

  if (Array.isArray(id_servico)) {
    cadastroDados = id_servico.map(id => ({
      ID_Cliente: retorno.generatedIds,
      id_servico: id,
      ativo: true
    }))
  } else if (id_servico) {
    cadastroDados = [
      {
        ID_Cliente: retorno.generatedIds,
        id_servico: id_servico,
        ativo: true
      }
    ]
  } else {
    cadastroDados = []
  }

  await SalvarAssociacaoClienteServico(false, cadastroDados, {
    rota: 'cadastroGeral',
    tenantId
  })
}
