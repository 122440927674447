import Banner from '/src/components/pages/CadastroGeral/Banner'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'

import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { useState, useContext, useEffect } from 'react'
//import loadIps from '/src/components/pages/Usuarios/login/login';

import { AuthContext } from '/src/context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { fetchData } from '/src/backend/model'

import { config } from '/src/backend/configConexaoGeral'
import { Navbar, Nav, Tooltip, OverlayTrigger, Container, Row, Col, Button } from 'react-bootstrap'

import logoCentro from './logo_centro.jpg'
function Home() {
  const navigate = useNavigate()
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  return (
    <div>
      <NavbarModelagemcompleta
        setShowAlertMessage={setShowAlertMessage}
        setAlertMessage={setAlertMessage}
        logoTopo={logoTopoModelagemCompleta}
        title={'Modelagem Completa'}
      />
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
      />
      <div style={{ backgroundColor: '#ffeb3b', padding: '10px', textAlign: 'center' }}>
        <strong>Não assinante?</strong> Você tem direito a 7 dias gratuitos, e apos isso 10 usos dos
        serviços por mês. Aproveite!
      </div>

      <div className="banner-container-homeMC">
        <div className="sales-text-block">
          <h2 className="title-vendas">Para a inovação em moda</h2>
          <h1 className="main-chamada">
            aperfeiçoe cada criação e exceda expectativas com a precisão em modelagem da
            ModelagemCompleta
          </h1>
          <p className="detalhes">
            ModelagemCompleta é a escolha dos visionários da moda, transformando conceitos em
            realidade com serviços de modelagem e corte sob medida para uma indústria que valoriza a
            perfeição.
          </p>
          <Button
            variant="success"
            className="btn-experimente-gratis"
            onClick={() => navigate('/cadastro-tenant')}
          >
            começe agora
          </Button>
        </div>
        <div className="planos-circuloMC">
          <div className="preco-chamada">Planos a partir de</div>
          <div className="preco-valor">
            R$<span className="preco-destaque">200</span>,00
          </div>

          <div className="preco-info">saiba mais</div>
        </div>

        <div className="local-banner-centroMC">
          <img src={logoCentro} alt="Logo" className="logo-centro-homeMC" />
        </div>
      </div>
    </div>
  )
}
export default Home

const CustomTooltip = ({ children, setShowAlertMessage, setAlertMessage }) => {
  const [show, setShow] = useState(false)

  const [ip_atual, set_ip_atual] = useState('')
  const [ips, setIps] = useState([])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const handleSubmit = async (
    username,
    password,
    ips,
    ip_atual,
    navigate,
    contextValues // Adiciona os valores do contexto como parâmetro
  ) => {
    const {
      login,
      logout,
      setIdNivelAcesso,
      setNivelAcesso,
      setUser,
      setSenha,
      setIsAuthenticated,
      setSuperUser,
      RotaBase,
      tenantId,
      setTenantId,
      setAcessoModulos,
      setNomeCliente,
      setEmail,
      setIdentificacaoFiscal
    } = contextValues // Desestrutura os valores necessários do contexto

    try {
      let url = `${config.host}/login`

      let dados = {
        username: username.trim(),
        password: password,
        rota_base: RotaBase,
        requisicao: 'login'
      }

      let log = await fetchData(url, dados)
      console.log('log do toltip', log)
      const ipPermitido = ips.some(ip => ip.ip_liberado === ip_atual)

      if (!ipPermitido && log.user.nivel_acesso != 'Administrador') {
        setAlertMessage('IP SEM PERMISSÃO DE ACESSO!')
        setShowAlertMessage(true)
        return
      }

      if (log && log.success) {
        setTenantId(log.user.tenantId)
        setIdNivelAcesso(log.user.id_nivel_acesso)
        setNivelAcesso(log.user.nivel_acesso)
        setUser(log.user.nome_usuario)
        setSenha(log.user.email)
        setAcessoModulos(log.user.acessoModulos)
        setNomeCliente(log.user.nome)
        setEmail(log.user.email)
        setIdentificacaoFiscal(log.user.indentificacaoFiscal)
        login()
        setErrorMessage('')
        navigate('/inicio-mc')
      }
    } catch (error) {
      // A lógica para tratar um superusuário deve ser ajustada caso necessário
      setUser('')
      setSenha('')
      logout()
      setErrorMessage('Erro ao tentar se conectar ao servidor.')
      if (error.message) {
        setAlertMessage('Erro na autenticação.')
        setShowAlertMessage(true)
      }
    }
  }
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  const authContextValues = useContext(AuthContext)
  const { RotaBase, tenantId } = authContextValues

  useEffect(() => {
    // Define uma função assíncrona dentro do useEffect
    const fetchIps = async () => {
      try {
        if (RotaBase) {
          const response = await loadIps(RotaBase) // Chama a funcao que deve retornar os IPs

          setIps(response) // Atualiza o estado com os IPs retornados
        }
      } catch (error) {
        console.error('Ocorreu um erro ao carregar os IPs no customTollTip:', error)
      }
    }

    fetchIps() // Executa a função assíncrona definida
  }, [authContextValues.RotaBase, authContextValues.tenantId]) // Dependências vazias indicam que isso acontece apenas na montagem do componente
  const showTip = () => {
    setShow(true)
  }

  const hideTip = () => {
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    handleSubmit(
      username,
      password,
      ips,
      ip_atual,
      navigate,

      authContextValues // Passa os valores do contexto como argumento para a função
    )
  }
  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      {children}
      {show && (
        <div className="login-container-homeMC">
          <form className="login-formMC">
            <input
              className="inputsLoginMC"
              type="text"
              placeholder="Usuário ou e-mail"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              className="inputsLoginMC"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <a href="#" className="forgot-password">
              Esqueceu sua senha?
            </a>
            <button type="submit" onClick={handleFormSubmit} className="login-button">
              Entrar
            </button>
          </form>
          {/* <div class='social-login'>
            <button class='facebook-login'>
              <img src='facebook-icon.png' alt='Facebook' /> Facebook
            </button>
            <button class='google-login'>
              <img src='google-icon.png' alt='Google' /> Google
            </button>
          </div> */}
        </div>
      )}
    </div>
  )
}

//--------------------------------------------
const loadIps = async RotaBase => {
  let dados = {
    requisicao: 'RetornarTodosIps',
    rota_base: RotaBase
  }
  let url = config.host + '/liberar_ips'

  let response = await fetchData(url, dados)

  return response
  // Store the IPs in the state variable
}

//--------------------------------------------
export const NavbarModelagemcompleta = ({
  setShowAlertMessage,
  setAlertMessage,
  logoTopo,
  title
}) => {
  const navigate = useNavigate()

  return (
    <Navbar bg="light" expand="lg" className="index-top-header-homeMC">
      <div className="d-flex align-items-center">
        <Navbar.Brand href="" className="d-flex align-items-center">
          <div onClick={() => navigate('/home')}>
            <img src={logoTopo} alt="Logo" className="logo-topo-homePF" />
          </div>
        </Navbar.Brand>
        <Banner
          title={title}
          fontFamily="Josefin Sans"
          color="#102a43"
          width="52%"
          fontSize="30px"
          classEfeito3dMouseOver=""
        />
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto menu-items-container">
          <Nav.Link href="#funcionalidades" id="index-link-nav-link">
            Funcionalidades
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/planos-detalhes-pf')} id="index-link-nav-link">
            Planos e preços
          </Nav.Link>
          <Nav.Link href="#afiliado" id="index-link-nav-link">
            Seja um Afiliado
          </Nav.Link>
          <Nav.Link href="#contato" id="index-link-nav-link">
            Fale conosco
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/cadastro-tenant')} className="experimente-gratis-btn">
            Experimente
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <CustomTooltip setShowAlertMessage={setShowAlertMessage} setAlertMessage={setAlertMessage}>
        <div className="entrar-link" onClick={() => navigate('/login')}>
          Entrar
        </div>
      </CustomTooltip>
    </Navbar>
  )
}
