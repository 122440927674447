import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'

function AlertMessage({
  message,
  onClose,
  size,
  customHeight,
  customWidth,
  variant1 = 'warning',
  variant2 = 'secondary',
  variantDefault = 'success',
  isBlinking = false,
  top = '8px',
  left,
  position = 'relative',
  showDots = true
}) {
  const [dots, setDots] = useState('')
  const [blinkColor, setBlinkColor] = useState(isBlinking ? variant1 : variantDefault)

  useEffect(() => {
    setBlinkColor(isBlinking ? variant1 : variantDefault)
  }, [isBlinking, variant1, variantDefault])

  useEffect(() => {
    if (!showDots) return
    const interval = setInterval(() => {
      setDots(dots => (dots.length === 3 ? '' : dots + '.'))
    }, 500)

    if (isBlinking) {
      setBlinkColor(variant1)
    }

    return () => clearInterval(interval)
  }, [isBlinking, variant1, variantDefault])

  useEffect(() => {
    let blinkInterval

    if (isBlinking) {
      blinkInterval = setInterval(() => {
        setBlinkColor(prevColor => (prevColor === variant1 ? variant2 : variant1))
      }, 200)
    }

    return () => {
      if (blinkInterval) {
        clearInterval(blinkInterval)
      }
    }
  }, [variant1, variant2, isBlinking])

  const customSizeStyle = createCustomSizeStyle(customHeight, customWidth)
  const customAlertStyle = {
    marginBottom: 0
  }
  return (
    <Alert
      variant={blinkColor.startsWith('#') ? '' : blinkColor}
      style={{
        ...customAlertStyle,
        position: 'absolute',
        top: top,
        left: left,
        marginLeft: '5px',
        ...customSizeStyle,
        borderRadius: '0.25rem',
        zIndex: 9999
      }}
    >
      <div
        className="alert-background-wrapper"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
          // marginTop: '-5px',
        }}
      >
        {/* <BsArrowLeft style={{ marginLeft: '-50px' }} /> */}

        <Alert.Heading className={sizeToClassName(size)} style={{ marginTop: '-10px' }}>
          {message}
          {showDots && dots}
        </Alert.Heading>
      </div>
    </Alert>
  )
}

export default AlertMessage

function sizeToClassName(size) {
  switch (size) {
    case 'small':
      return 'alert-small'
    case 'medium':
      return 'alert-medium'
    case 'large':
      return 'alert-large'
    case 'x-large':
      return 'alert-extra-large'
    default:
      return ''
  }
}

function createCustomSizeStyle(height, width) {
  return {
    height: height ? `${height}px` : undefined,
    width: width ? `${width}px` : undefined
  }
}
