// // src/components/pages/Payments/SelectPlan.jsx

import { useState, useContext, useEffect } from 'react'
import { initMercadoPago } from '@mercadopago/sdk-react'
import { config } from '../../../backend/configConexaoGeral'
import { fetchData } from '../../../backend/model'
import { AuthContext } from '../../../../src/context/AuthContext'
import { Tooltip } from 'react-tippy'
import { Link } from 'react-router-dom'
// import 'react-tippy/dist/tippy.css' // Importando o CSS do react-tippy
import Modal from 'react-modal'

//import './SelectPlan.css' // Arquivo CSS para estilização

initMercadoPago('TEST-05090b4e-3efd-4eca-8231-d32b94794285')
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}
const MODULOS_DISPONIVEIS = [
  { nome: 'corte', preco: 6 },
  { nome: 'modelagem', preco: 29.9 },
  { nome: 'plotagem', preco: 29.9 } // Adicione outros módulos conforme necessário
]

const SelectPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState('mensal')
  const [selectedModules, setSelectedModules] = useState([])
  const [total, setTotal] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { tenantId, nomeCliente, email, identificacaoFiscal, tipodocumento } =
    useContext(AuthContext)
  const [subscribedModules, setSubscribedModules] = useState([])

  // Função para buscar módulos assinados
  const modulosAssinados = async () => {
    const url = config.host + '/tenants' // Rota fornecida pelo usuário
    const dados = { tenantId, requisicao: 'modulosAssinados' } // Enviando tenantId como parte dos dados

    try {
      const response = await fetchData(url, dados)
      console.log('Módulos assinados:', response)
      // Supondo que a resposta contenha um array de nomes de módulos assinados
      const assinados = response.modules || []
      setSubscribedModules(assinados)
      // setSelectedModules(prev => [...new Set([...prev, ...assinados])]) // Garante que os módulos assinados estejam selecionados
    } catch (error) {
      console.error('Erro ao carregar módulos assinados:', error)
    }
  }

  useEffect(() => {
    modulosAssinados() // Chama a função ao montar o componente
  }, [])

  const handlePlanChange = event => {
    setSelectedPlan(event.target.value)
  }

  // Calcula a soma dos preços dos módulos selecionados
  const somaModulos = selectedModules.reduce((acc, moduloNome) => {
    const modulo = MODULOS_DISPONIVEIS.find(m => m.nome === moduloNome)
    return acc + (modulo ? modulo.preco : 0)
  }, 0)

  // Função para calcular o preço de cada plano
  const calculatePlanPrice = planType => {
    let totalPlano = 0

    if (planType === 'mensal') {
      totalPlano = somaModulos
    } else if (planType === 'trimestral') {
      totalPlano = somaModulos * 3 * 0.9 // 10% de desconto
    } else if (planType === 'semestral') {
      totalPlano = somaModulos * 6 * 0.85 // 15% de desconto
    }

    return totalPlano.toFixed(2)
  }

  useEffect(() => {
    const calcularTotal = () => {
      let totalPlano = 0

      if (selectedPlan === 'mensal') {
        totalPlano = somaModulos
      } else if (selectedPlan === 'trimestral') {
        totalPlano = somaModulos * 3 * 0.9 // 10% de desconto
      } else if (selectedPlan === 'semestral') {
        totalPlano = somaModulos * 6 * 0.85 // 15% de desconto
      }

      setTotal(totalPlano)
    }

    calcularTotal()
  }, [selectedModules, selectedPlan, somaModulos])

  const handlePayment = async () => {
    if (selectedModules.length === 0) {
      alert('Por favor, selecione pelo menos um módulo para assinar.')
      return
    }

    // Cria os itens para cada módulo selecionado com base no plano escolhido
    let items = selectedModules.map(moduloNome => {
      const modulo = MODULOS_DISPONIVEIS.find(m => m.nome === moduloNome)
      let unit_price = 0
      if (selectedPlan === 'mensal') {
        unit_price = modulo.preco
      } else if (selectedPlan === 'trimestral') {
        unit_price = modulo.preco * 3 * 0.9 // 10% de desconto
      } else if (selectedPlan === 'semestral') {
        unit_price = modulo.preco * 6 * 0.85 // 15% de desconto
      }
      return {
        title: `Plano ${capitalize(modulo.nome)}`,
        quantity: 1,
        unit_price: parseFloat(unit_price.toFixed(2))
      }
    })

    const payer = {
      email: email,
      identification: {
        type: tipodocumento,
        number: identificacaoFiscal
      },
      first_name: nomeCliente
    }
    console.log('payer', payer)
    const url = config.host + '/assinaturas'
    const dados = {
      items: items,
      payer: payer,
      payment_methods: {
        installments: 6, // Até 12 parcelas
        excluded_payment_methods: [], // Nenhum método excluído
        excluded_payment_types: [] // Nenhum tipo excluído
      },
      requisicao: 'createPreference',
      clienteId: tenantId,
      selectedPlan: selectedPlan,
      modulos: selectedModules // Envia os módulos selecionados
    }

    try {
      const preference = await fetchData(url, dados)
      console.log('Preferência de pagamento criada:', preference)
      const { init_point } = preference
      window.open(init_point, '_blank')
    } catch (error) {
      console.error('Erro ao criar preferência de pagamento:', error)
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const calculateEquivalentMonthly = planType => {
    if (planType === 'mensal') {
      return somaModulos
    } else if (planType === 'trimestral') {
      return (somaModulos * 3 * 0.9) / 3 // Simplifica para somaModulos * 0.9
    } else if (planType === 'semestral') {
      return (somaModulos * 6 * 0.85) / 6 // Simplifica para somaModulos * 0.85
    }
    return 0
  }
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
  return (
    <div className="plan-container">
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <p>
          <strong>Não assinante?</strong> Aproveite 15 dias gratuitos com uso ilimitado dos
          serviços. Após esse período, você terá 10 usos gratuitos por mês.
        </p>
        <button onClick={openModal} style={{ marginLeft: '10px' }}>
          Saiba mais
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Informações sobre o uso gratuito"
      >
        <h2>Informações sobre o uso gratuito</h2>
        <p>
          Durante os primeiros 7 dias após o cadastro, você pode utilizar todos os serviços de forma
          ilimitada. Após esse período inicial, você terá direito a 10 usos gratuitos por mês. Para
          continuar usando os serviços sem limitações, considere assinar um dos nossos planos.
        </p>
        <button onClick={closeModal}>Fechar</button>
      </Modal>

      <h2 className="plan-title">Escolha seu Plano</h2>
      <div className="plan-options">
        <div className={`plan-card ${selectedPlan === 'mensal' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="mensal"
            name="plan"
            value="mensal"
            checked={selectedPlan === 'mensal'}
            onChange={handlePlanChange}
          />
          <label htmlFor="mensal">
            <h3>Plano Mensal</h3>
            <p>{somaModulos > 0 ? `R$${calculatePlanPrice('mensal')}/mês` : 'R$0,00/mês'}</p>
            <p>Sem compromisso</p>
            <p></p>
          </label>
          <Tooltip
            title="Aproveite 7 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
        <div className={`plan-card ${selectedPlan === 'trimestral' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="trimestral"
            name="plan"
            value="trimestral"
            checked={selectedPlan === 'trimestral'}
            onChange={handlePlanChange}
          />
          <label htmlFor="trimestral">
            <h3>Plano Trimestral</h3>
            <p>
              {somaModulos > 0
                ? `R$${calculatePlanPrice('trimestral')}/trimestre`
                : 'R$0,00/trimestre'}
            </p>
            <p>
              {somaModulos > 0
                ? `(equivalente a R$${calculateEquivalentMonthly('trimestral').toFixed(2)}/mês)`
                : ''}
            </p>

            <p>Economize 10%</p>
          </label>
          <Tooltip
            title="Aproveite 7 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
        <div className={`plan-card ${selectedPlan === 'semestral' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="semestral"
            name="plan"
            value="semestral"
            checked={selectedPlan === 'semestral'}
            onChange={handlePlanChange}
          />
          <label htmlFor="semestral">
            <h3>Plano Semestral</h3>
            <p>
              {somaModulos > 0
                ? `R$${calculatePlanPrice('semestral')}/semestre`
                : 'R$0,00/semestre'}
            </p>
            <p>
              {somaModulos > 0
                ? `(equivalente a R$${calculateEquivalentMonthly('semestral').toFixed(2)}/mês)`
                : ''}
            </p>
            <p>Economize 15%</p>
          </label>
          <Tooltip
            title="Aproveite 15 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
      </div>

      {/* Componente de Seleção de Módulos */}
      <ModuleSelection
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
        subscribedModules={subscribedModules}
      />

      {/* Seção de Total */}
      {/* <div className="total-section">
        <h3>Total: {`R$${total.toFixed(2)}`}</h3>
      </div> */}

      <button onClick={handlePayment} className="plan-button">
        Assinar Plano
      </button>
    </div>
  )
}

export default SelectPlan

// Componente para seleção de módulos
const ModuleSelection = ({ selectedModules, setSelectedModules, subscribedModules }) => {
  // Função auxiliar para capitalizar o nome do módulo
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

  const handleModuleChange = event => {
    const { value, checked } = event.target
    if (checked) {
      setSelectedModules(prev => [...prev, value])
    } else {
      setSelectedModules(prev => prev.filter(modulo => modulo !== value))
    }
  }
  // Função para redirecionar para a página de descrição do módulo
  const goToModuleDescription = modulo => {
    window.location.href = `/module-description?module=${modulo}`
  }

  return (
    <div className="modules-selection">
      <h3>Selecione os Módulos</h3>
      {MODULOS_DISPONIVEIS.map(modulo => {
        const isSubscribed = subscribedModules.includes(modulo.nome) // Verifica se o módulo está assinado
        return (
          <div key={modulo.nome} className="module-option">
            <input
              type="checkbox"
              id={modulo.nome}
              name={modulo.nome}
              value={modulo.nome}
              checked={selectedModules.includes(modulo.nome) || isSubscribed} // Marca o checkbox se estiver selecionado
              onChange={handleModuleChange}
              disabled={isSubscribed} // Desabilita o checkbox se estiver assinado
            />
            <label htmlFor={modulo.nome}>
              <h4>{`Módulo: ${capitalize(modulo.nome)}`}</h4>
              <p>{`Preço: R$${modulo.preco.toFixed(2)}/mês`}</p>
              {isSubscribed && (
                <span style={{ color: 'green', marginLeft: '10px' }}>Módulo Assinado</span> // Exibe "Módulo Assinado" se estiver assinando
              )}
            </label>
            {/* Link "Saiba mais" para redirecionar para a página de descrição do módulo */}

            <Link
              to={`/module-description/${modulo.nome}`}
              className="saiba-mais-link"
              style={{
                marginLeft: '10px',
                textDecoration: 'underline',
                color: '#6c757d',
                cursor: 'pointer'
              }}
            >
              Saiba mais
            </Link>
          </div>
        )
      })}
    </div>
  )
}
