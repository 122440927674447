import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../src/context/AuthContext'
import { fetchData } from '../../../../src/backend/model'
import { config } from '../../../../src/backend/configConexaoGeral'
import Banner from '../../../components/pages/CadastroGeral/Banner'
import { Button } from 'react-bootstrap'
import { ShowAlertGeneric } from '../../../utils/form-utils.jsx'

const ConfigurarValorEmbalagem = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [valorEmbalagemCorte, setValorEmbalagemCorte] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'

  useEffect(() => {
    const fetchValorEmbalagemCorte = async () => {
      try {
        const dados = {
          tenantId: tenantId,
          rota_base: RotaBase,
          requisicao: 'getValorEmbalagemCorte'
        }
        const url = config.host + '/configuracoesGerais'
        const response = await fetchData(url, dados)

        if (response?.valorEmbalagemCorte !== undefined) {
          setValorEmbalagemCorte(response.valorEmbalagemCorte)
        }
      } catch (error) {
        console.error('Erro ao buscar o valor da embalagem do corte:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar o valor da embalagem do corte')
        setShowAlert(true)
      }
    }

    fetchValorEmbalagemCorte()
  }, [RotaBase, tenantId])

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const dados = {
        tenantId: tenantId,
        rota_base: RotaBase,
        valorEmbalagemCorte: parseFloat(valorEmbalagemCorte),
        requisicao: 'saveValorEmbalagemCorte'
      }
      const url = config.host + '/configuracoesGerais'
      const response = await fetchData(url, dados)

      if (response.success) {
        setAlertVariant('success')
        setAlertMessage('Valor da embalagem do corte atualizado com sucesso')
      } else {
        setAlertVariant('danger')
        setAlertMessage('Erro ao atualizar o valor da embalagem do corte')
      }
    } catch (error) {
      console.error('Erro ao atualizar o valor da embalagem do corte:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao atualizar o valor da embalagem do corte')
    } finally {
      setShowAlert(true)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        margin: '40px 0px' // Pequena margem horizontal
      }}
    >
      <Banner title="EMBALAGEM" width="20%" />
      <h2 style={{ textAlign: 'center' }}>Configurar Valor da Embalagem</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '1rem' }}>
          <label>Valor da Embalagem:</label>
          <input
            type="number"
            value={valorEmbalagemCorte}
            onChange={e => setValorEmbalagemCorte(e.target.value)}
            required
            style={{ marginLeft: '0.5rem' }}
          />
        </div>
        <Button variant="primary" type="submit" style={{ alignSelf: 'center' }}>
          Atualizar Valor
        </Button>
      </form>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </div>
  )
}

export default ConfigurarValorEmbalagem
