// // // RouteHandler.js

//************************************************************************************************** */
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'

// Importação dinâmica dos componentes
//const AppModelagemCompleta = React.lazy(() => import('../components/AppModelagemCompleta'))
//const AppPrecoFacilMarket = React.lazy(() => import('../components/AppPrecoFacilMarket'))
import AppModelagemCompleta from '../components/AppModelagemCompleta'
import AppPrecoFacilMarket from '../components/AppPrecoFacilMarket'

import { TipoConexao } from '../backend/configConexaoGeral'

// Funções para determinar se a rota é para Preço Fácil Market ou Modelagem Completa
const isRouteForPrecoFacilMarket = () => {
  const hostname = window.location.hostname
  const currentPath = window.location.pathname
  console.log('hostname:', hostname)
  console.log('currentPath:', currentPath)
  const isHostnameMatched =
    hostname === 'precofacilmarket.com.br' ||
    hostname === 'dev.precofacilmarket.com.br' ||
    hostname === 'www.precofacilmarket.com.br'

  console.log('isHostnameMatched precofacilmarket 3:', isHostnameMatched)
  const knownPaths = [
    '/regra-magalu',
    '/precofacilmarket',
    '/inicio-pf',
    '/cadastro-marketplace',
    '/cadastro-categoria',
    '/calculo-precoPF',
    '/edicao-categoria',
    '/editar-marketplace',
    '/editar-produto',
    '/cadastro-regra-preco-frete',
    '/cadastro-regra-peso-frete',
    '/config-regras-frete-ML',
    '/cadastro-regra-freteAmVv',
    '/config-regras-frete-americanas',
    '/cadastro-imposto',
    '/tenant-marketplaces-config',
    '/verifyemail',
    '/tenants/verifyemail',
    '/email-client'
  ]

  if (isHostnameMatched) {
    return true
  }

  return knownPaths.some(path => currentPath.startsWith(path))
}

const isRouteForModelagemCompleta = () => {
  const currentPath = window.location.pathname
  const hostname = window.location.hostname

  // Lista de rota_bases conhecidas e caminhos conhecidos
  const knownRotaBases = ['modelagemcompleta', 'negocios360']
  const knownPaths = [
    '/modelagemcompleta',
    '/negocios360',
    '/inicio-mc',
    '/cadastro-tenant',
    '/finalizacao-de-cortes',
    '/modelagem-cadastro-servico-modelagem',
    '/plotagem-cadastro-plotagem',
    '/financeiro',
    '/config-regras-frete-ML',
    '/plotagem-cadastro-cliente',
    '/corte-cadastro-corte',
    '/modelagem-cadastro-grau-dificuldade-modelagem',
    '/modelagem-cadastro-precos-modelagem',
    '/editando-modelagems',
    '/finalizacao-de-modelagems',
    '/relatorio-debitos-clientes',
    '/configurar-valor-plotagem',
    '/configurar-valor-embalagem',
    '/resumo-corte',
    '/analise-receita-corte',
    '/gerenciamento-setores',
    '/gerenciamento-cargos',
    '/cadastro-geral',
    '/funcionarios-ativos',
    '/gerenciamento-horarios',
    '/analise-lucro-bruto-corte',
    '/cadastro-pagamentos-funcionarios',
    '/corte-editar-corte',
    '/module-description'
  ]

  let subdominio = ''
  let rota_base = ''
  let matched = false
  let dominio = hostname

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    // Ambiente de desenvolvimento
    const pathParts = currentPath.split('/').filter(Boolean)
    const firstPart = pathParts[0] || ''

    // Verifica se firstPart contém um ponto
    if (firstPart.includes('.')) {
      // Lida com múltiplos pontos
      const firstPartSegments = firstPart.split('.')
      console.log('firstPartSegments:', firstPartSegments)

      const tld = firstPartSegments.slice(-2).join('.')
      // console.log('tld:', tld)

      if (tld === 'com.br') {
        // TLD é 'com.br', extrai rota_base e subdominio adequadamente
        if (firstPartSegments.length >= 3) {
          rota_base = firstPartSegments[firstPartSegments.length - 3]
          subdominio = firstPartSegments.slice(0, firstPartSegments.length - 3).join('.')
        }
      } else {
        // Assume que o último segmento é rota_base
        rota_base = firstPartSegments[firstPartSegments.length - 1]
        subdominio = firstPartSegments.slice(0, firstPartSegments.length - 1).join('.')
      }

      // Verifica se rota_base está nas rota_bases conhecidas
      if (knownRotaBases.includes(rota_base)) {
        matched = true
      } else {
        matched = knownPaths.some(path => currentPath.startsWith(path))
      }
    } else {
      // firstPart não contém ponto
      rota_base = firstPart

      // Verifica se rota_base está nas rota_bases conhecidas
      if (knownRotaBases.includes(rota_base)) {
        matched = true
      } else {
        matched = knownPaths.some(path => currentPath.startsWith(path))
      }
    }
  } else {
    // Ambiente de produção
    const hostnameParts = hostname.split('.')

    // console.log('hostnameParts:', hostnameParts)

    const tld = hostnameParts.slice(-2).join('.')
    //console.log('tld:', tld)

    if (tld === 'com.br') {
      // TLD é 'com.br', extrai rota_base e subdominio adequadamente
      if (hostnameParts.length >= 3) {
        rota_base = hostnameParts[hostnameParts.length - 3]
        subdominio = hostnameParts.slice(0, hostnameParts.length - 3).join('.')
      }
    } else {
      // Assume que o penúltimo segmento é rota_base
      if (hostnameParts.length >= 2) {
        rota_base = hostnameParts[hostnameParts.length - 2]
        subdominio = hostnameParts.slice(0, hostnameParts.length - 2).join('.')
      }
    }

    // Verifica se rota_base está nas rota_bases conhecidas
    if (knownRotaBases.includes(rota_base)) {
      matched = true
    } else {
      matched = knownPaths.some(path => currentPath.startsWith(path))
    }
  }

  return {
    matched,
    dominio,
    rota_base,
    subdominio
  }
}

const RouteHandler = () => {
  const { isAuthenticated, RotaBase, setRotaBase, checkAuth, superUser, acessoModulos } =
    useContext(AuthContext)
  const [authChecked, setAuthChecked] = useState(false)
  // 1 para produção e 2 para desenvolvimento
const tipo = 1;

  useEffect(() => {
    const verifyAuth = async () => {
      if (!superUser) {
        await checkAuth()
      }
      setAuthChecked(true) // Atualiza o estado para indicar que a checagem foi feita
    }
    verifyAuth()
  }, [checkAuth, superUser])

  useEffect(() => {
    console.log('authChecked:', authChecked)
    console.log('isAuthenticated:', isAuthenticated)
    if (authChecked && !isAuthenticated) {
      if (isRouteForPrecoFacilMarket()) {
        TipoConexao({ tipo: tipo, dominio: 'https://precofacilmarket.com.br' })
        setRotaBase('precofacilmarket')
      } else {
        let { matched, dominio, rota_base } = isRouteForModelagemCompleta()

        if (matched) {
          console.log('dominio', dominio)
          TipoConexao({ tipo: tipo, dominio: `https://${dominio}` }) // Usa o domínio retornado
          setRotaBase(rota_base) // Seta o novo parâmetro rota_base do retorno
        } else {
          console.log('Nenhuma rota combinada, resetando para null')
        }
      }
    }
  }, [authChecked, isAuthenticated, setRotaBase, tipo])

  const renderBasedOnRoute = () => {
    console.log('RotaBase:', RotaBase)
    if (RotaBase === 'modelagemcompleta' || RotaBase === 'negocios360') {
      return <AppModelagemCompleta rotaBase={RotaBase} acessoModulos={acessoModulos} /> // Passa RotaBase como prop
    } else if (RotaBase === 'precofacilmarket') {
      return <AppPrecoFacilMarket />
    }

    // Caso adicional para rotas desconhecidas ou usuário não autenticado
    return <div>Usuário não autenticado ou rota desconhecida</div>
  }

  // Verifique se authChecked é true antes de prosseguir com a lógica de renderização
  if (!authChecked) {
    return <div>Verificando autenticação...</div>
  }

  // Se estiver autenticado, renderiza com base na rota
  if (isAuthenticated) {
    return renderBasedOnRoute()
  }

  // Caso não esteja autenticado e o caminho não seja de nenhum sistema conhecido

  return renderBasedOnRoute()
}

export default RouteHandler
