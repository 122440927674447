import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../../src/context/AuthContext'
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { Container, Form } from 'react-bootstrap'
import Banner from '../../../../../src/components/pages/CadastroGeral/Banner'
import DatePicker from 'react-datepicker'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

const AnaliseLucroBruto = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [dataInicio, setDataInicio] = useState(new Date())
  const [dataFim, setDataFim] = useState(new Date())
  const [periodoSelecionado, setPeriodoSelecionado] = useState('7d') // Default to last 7 days
  const [receitaTotal, setReceitaTotal] = useState(0)
  const [custoTotal, setCustoTotal] = useState(0)
  const [lucroBruto, setLucroBruto] = useState(0)
  const [lucroPorCliente, setLucroPorCliente] = useState([])
  const [lucroPorDia, setLucroPorDia] = useState([])
  const [lucroPorDiaDaSemana, setLucroPorDiaDaSemana] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'

  // Estados separados para ordenação e dataKey de cada seção
  const [dataKeyBarDia, setDataKeyBarDia] = useState('percentualLucro')
  const [dataKeyBarSemana, setDataKeyBarSemana] = useState('diaDaSemana')

  // Função para atualizar o intervalo de datas
  const updateDateRange = period => {
    const end = new Date()
    let start = new Date()
    switch (period) {
      case '7d':
        start.setDate(end.getDate() - 7)
        break
      case '15d':
        start.setDate(end.getDate() - 15)
        break
      case '1m':
        start.setMonth(end.getMonth() - 1)
        break
      case '3m':
        start.setMonth(end.getMonth() - 3)
        break
      case '6m':
        start.setMonth(end.getMonth() - 6)
        break
      case '1y':
        start.setFullYear(end.getFullYear() - 1)
        break
      case 'all':
        start = new Date(0) // Start of UNIX time
        break
      case 'custom':
        return
      default:
        return
    }

    setDataInicio(start)
    setDataFim(end)
  }

  // Fetch para dados gerais de lucro
  useEffect(() => {
    updateDateRange('7d')
  }, [])

  useEffect(() => {
    const fetchDadosLucro = async () => {
      setLoading(true)
      try {
        let url = `${config.host}/analiseReceita`

        const receitaResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterReceitaTotalPeriodo'
        })

        url = `${config.host}/custoFuncionarios`
        const custoResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterCustoTotalPeriodo'
        })

        if (custoResponse) {
          setReceitaTotal(receitaResponse.totalReceita || 0)
          setCustoTotal(custoResponse.totalCusto || 0)
          setLucroBruto((receitaResponse.totalReceita || 0) - (custoResponse.totalCusto || 0))
        }
        url = `${config.host}/analiseLucroBruto`
        const lucroPorClienteResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterLucroPorCliente'
        })
        if (lucroPorClienteResponse) {
          setLucroPorCliente(lucroPorClienteResponse.slice(0, 5) || [])
        }

        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar dados de lucro bruto:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar dados de lucro bruto')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchDadosLucro()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  // Funções separadas para buscar os dados de cada seção
  const fetchDadosLucroPorDia = async (ordenar = 'diaMes') => {
    setLoading(true)
    try {
      const url = `${config.host}/analiseLucroBruto`

      const lucroPorDiaResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        dataInicio,
        dataFim,
        ordenar,
        requisicao: 'obterLucroPorDia'
      })

      if (lucroPorDiaResponse) {
        setLucroPorDia(lucroPorDiaResponse || [])
      }

      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar dados de lucro por dia:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar dados de lucro por dia')
      setShowAlert(true)
      setLoading(false)
    }
  }
  //crie um useffect para chamar a função fetchDadosLucroPorDia
  useEffect(() => {
    const fetchData = async () => {
      await fetchDadosLucroPorDia() // Adicionando await para garantir que a função assíncrona seja aguardada
    }

    fetchData()
  }, [dataInicio, dataFim])

  const fetchDadosLucroPorDiaDaSemana = async (ordenar = 'diaDaSemana') => {
    setLoading(true)
    try {
      const url = `${config.host}/analiseLucroBruto`
      const lucroPorDiaDaSemanaResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        dataInicio,
        dataFim,
        ordenar,
        requisicao: 'obterLucroPorDiaDaSemana'
      })

      if (lucroPorDiaDaSemanaResponse) {
        setLucroPorDiaDaSemana(lucroPorDiaDaSemanaResponse || [])
      }
      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar dados de lucro por dia da semana:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar dados de lucro por dia da semana')
      setShowAlert(true)
      setLoading(false)
    }
  }

  // Ordenar os dados de cada gráfico separadamente
  const dadosOrdenadosPorDia = lucroPorDia
    .map(item => ({
      ...item,
      ordemDia: new Date(item.dataCompleta).getDate() // Adiciona a ordem numérica do dia do mês
    }))
    .sort((a, b) => {
      if (dataKeyBarDia === 'diaMes') {
        return a.ordemDia - b.ordemDia
      } else if (dataKeyBarDia === 'lucroBruto') {
        return b.lucroBruto - a.lucroBruto
      } else if (dataKeyBarDia === 'percentualLucro') {
        return b.percentualLucro - a.percentualLucro
      }
      return 0
    })

  const dadosOrdenadosPorSemana = lucroPorDiaDaSemana
    .map(item => ({
      ...item,
      ordemRenderizada: transformarDiaParaNumero(item.diaDaSemana)
    }))
    .sort((a, b) => {
      if (dataKeyBarSemana === 'diaDaSemana') {
        return a.ordemRenderizada - b.ordemRenderizada
      } else if (dataKeyBarSemana === 'lucroBruto') {
        return b.lucroBruto - a.lucroBruto
      } else if (dataKeyBarSemana === 'percentualLucro') {
        return b.percentualLucro - a.percentualLucro
      }
      return 0
    })

  useEffect(() => {
    const fetchData = async () => {
      await fetchDadosLucroPorDiaDaSemana() // Adicionando await para garantir que a função assíncrona seja aguardada
    }

    fetchData()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  const handlePeriodoSelecionado = event => {
    const period = event.target.value

    setPeriodoSelecionado(period)
    if (period !== 'custom') {
      updateDateRange(period)
    }
  }
  // Separar os controles de ordenação para cada seção

  const lucroPorDiaFormatada = lucroPorDia.map(d => ({
    ...d,
    dataCompleta: new Date(d.dataCompleta)
      .toLocaleDateString('pt-BR', {
        day: 'numeric',
        month: 'long', // Exibe o nome completo do mês
        timeZone: 'UTC'
      })
      .replace(/ de /, '/') // Substitui o espaço e a palavra 'de' por uma barra
  }))
  function handleDateChange(dates) {
    const [start, end] = dates
    setDataInicio(start)
    setDataFim(end)
  }
  return (
    <Container
      fluid
      className="p-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '100%', maxWidth: '600px' }}>
        <Banner
          title="Análise de Lucro Bruto (setor corte)"
          width="89%"
          fontSize={28}
          classEfeito3dMouseOver=""
        />
        <Form.Group className="mb-3">
          <h4>Selecione o Período</h4>
          {['7d', '15d', '1m', '3m', '6m', '1y', 'all', 'custom'].map(period => (
            <Form.Check
              key={period}
              type="radio"
              label={periodLabels[period]}
              name="periodoAnalise"
              id={`radio-${period}`}
              value={period}
              checked={periodoSelecionado === period}
              onChange={handlePeriodoSelecionado}
              className="mb-2"
            />
          ))}
          {periodoSelecionado === 'custom' && (
            <DatePicker
              selectsRange={true}
              startDate={dataInicio}
              endDate={dataFim}
              onChange={handleDateChange}
              isClearable={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          )}
        </Form.Group>
      </div>
      {/* Gráficos e Tabelas */}
      <div className="section-container">
        <h2>Lucro Bruto Total</h2>
        <p>{`Receita Total: ${receitaTotal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`}</p>
        <p>{`Custo Total: ${custoTotal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`}</p>
        <p>{`Lucro Bruto: ${lucroBruto.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`}</p>
        <h2 style={{ marginBottom: '0x' }}>Gráfico de Lucro Bruto por Cliente</h2>
        <BarChart
          width={600}
          height={300}
          data={lucroPorCliente}
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
          style={{ height: '320px' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="clienteNome" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey="lucroBruto"
            fill="#82ca9d"
            label={({ x, y, width, value, index }) => {
              // Acessando corretamente o item dos dados com base no índice
              const payload = lucroPorCliente[index]

              const percentualLucro = payload?.percentualLucro ?? 0

              return (
                <g>
                  {/* Renderiza o valor em dinheiro */}
                  <text
                    x={x + width / 2}
                    y={y - 20}
                    fill="#007bff" // Azul para o valor em dinheiro
                    textAnchor="middle"
                    dy={-4}
                    style={{ fontSize: 14, fontWeight: 'bold' }}
                  >
                    {`${value.toFixed(2)} R$`}
                  </text>

                  {/* Renderiza o valor percentual abaixo do valor em dinheiro */}
                  <text
                    x={x + width / 2}
                    y={y}
                    fill="#000000" // Preto para o valor percentual
                    textAnchor="middle"
                    dy={-4}
                    style={{ fontSize: 15, fontWeight: 'bold' }}
                  >
                    {`(${percentualLucro.toFixed(2)}%)`}
                  </text>
                </g>
              )
            }}
          />
        </BarChart>
      </div>
      {/* Seção para Lucro Bruto por Dia */}
      <div className="section-container">
        <h2>Lucro Bruto por Dia</h2>
        <SelectOrdenacao
          onChangeOrdenacao={value => {
            setDataKeyBarDia(value)
            fetchDadosLucroPorDia(value)
          }}
          opcoesOrdenacao={[
            { label: 'Ordenar por Dia do Mês', value: 'diaMes' },
            { label: 'Ordenar por Valor em Dinheiro', value: 'lucroBruto' },
            { label: 'Ordenar por Percentual de Lucro', value: 'percentualLucro' }
          ]}
        />

        <BarChart
          width={800}
          height={400}
          data={lucroPorDiaFormatada} // Certifique-se de estar usando os dados ordenados corretamente
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dataCompleta" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />

          <Legend />
          <Bar
            dataKey={
              dataKeyBarDia === 'lucroBruto' || dataKeyBarDia === 'percentualLucro'
                ? dataKeyBarDia
                : 'lucroBruto'
            }
            fill="#82ca9d"
            label={({ x, y, width, value, index }) => {
              const payload = dadosOrdenadosPorDia[index] || lucroPorDiaFormatada[index]
              const percentualLucro = payload?.percentualLucro ?? 0
              const lucroBruto = payload?.lucroBruto ?? 0

              return (
                <CustomBarLabel
                  x={x}
                  y={y}
                  width={width}
                  lucroBruto={lucroBruto}
                  percentualLucro={percentualLucro}
                  selectedOption={dataKeyBarDia}
                />
              )
            }}
          />
        </BarChart>
      </div>

      {/* Seção para Lucro Bruto por Dia da Semana */}
      <div className="section-container">
        <h2>Lucro Bruto por Dia da Semana</h2>
        <SelectOrdenacao
          onChangeOrdenacao={value => {
            setDataKeyBarSemana(value)
            fetchDadosLucroPorDiaDaSemana(value)
          }}
          opcoesOrdenacao={[
            { label: 'Ordenar por Dia da Semana', value: 'diaDaSemana' },
            { label: 'Ordenar por Valor em Dinheiro', value: 'lucroBruto' },
            { label: 'Ordenar por Percentual de Lucro', value: 'percentualLucro' }
          ]}
        />
        <BarChart
          width={800}
          height={400}
          data={dadosOrdenadosPorSemana}
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="diaDaSemana" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={dataKeyBarSemana === 'diaDaSemana' ? 'ordemRenderizada' : dataKeyBarSemana}
            fill="#82ca9d"
            label={({ x, y, width, value, index }) => {
              const payload = dadosOrdenadosPorSemana[index]
              const percentualLucro = payload?.percentualLucro ?? 0
              const lucroBruto = payload?.lucroBruto ?? 0

              // Define o layout condicionalmente

              return (
                <CustomBarLabel
                  x={x}
                  y={y}
                  width={width}
                  lucroBruto={lucroBruto}
                  percentualLucro={percentualLucro}
                  selectedOption={dataKeyBarSemana}
                />
              )
            }}
          />
        </BarChart>
      </div>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default AnaliseLucroBruto

const periodLabels = {
  '7d': 'Últimos 7 dias',
  '15d': 'Últimos 15 dias',
  '1m': 'Último mês',
  '3m': 'Últimos 3 meses',
  '6m': 'Últimos 6 meses',
  '1y': 'Último ano',
  all: 'Todo o período',
  custom: 'Intervalo personalizado'
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Componente SelectOrdenacao ajustado para receber as opções de cada seção individualmente
const SelectOrdenacao = ({ onChangeOrdenacao, opcoesOrdenacao }) => {
  return (
    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
      <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Ordenar por:</label>
      <select
        style={{
          padding: '8px 12px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          fontSize: '16px',
          cursor: 'pointer'
        }}
        onChange={e => onChangeOrdenacao(e.target.value)}
      >
        {opcoesOrdenacao.map(opcao => (
          <option key={opcao.value} value={opcao.value}>
            {opcao.label}
          </option>
        ))}
      </select>
    </div>
  )
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Função para transformar dia da semana em um valor numérico
const transformarDiaParaNumero = dia => {
  switch (dia) {
    case 'Domingo':
      return 0
    case 'Segunda-feira':
      return 1
    case 'Terça-feira':
      return 2
    case 'Quarta-feira':
      return 3
    case 'Quinta-feira':
      return 4
    case 'Sexta-feira':
      return 5
    case 'Sábado':
      return 6
    default:
      return 7
  }
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const data = payload[0].payload // Acessa o objeto de dados do ponto no gráfico

    return (
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '10px',
          border: '1px solid #ccc'
        }}
      >
        <p style={{ margin: 0, color: 'black' }}>{label}</p>
        <p
          style={{ margin: 0, color: 'green' }}
        >{`Percentual de Lucro: ${data.percentualLucro.toFixed(2)}%`}</p>
        <p style={{ margin: 0, color: 'green' }}>{`Lucro Bruto: R$ ${data.lucroBruto.toFixed(
          2
        )}`}</p>
        <p style={{ margin: 0, color: 'green' }}>{`Receita Total: R$ ${data.totalReceita.toFixed(
          2
        )}`}</p>
        <p style={{ margin: 0, color: 'green' }}>
          {`${
            data?.custoDia !== undefined
              ? 'Custo do Dia'
              : data?.custoTotal !== undefined
              ? 'Custo Total'
              : 'Custo Médio'
          }: R$ ${
            data?.custoDia?.toFixed(2) ??
            data?.custoTotal?.toFixed(2) ??
            data?.custoMedioPorCliente?.toFixed(2) ??
            '0.00'
          }`}
        </p>
      </div>
    )
  }

  return null
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const CustomBarLabel = ({ x, y, width, lucroBruto, percentualLucro, selectedOption }) => {
  // Define o layout condicionalmente
  const isLucroBrutoSelected = selectedOption === 'lucroBruto'
  const isPercentualLucroSelected = selectedOption === 'percentualLucro'

  return (
    <g>
      {isLucroBrutoSelected ? (
        <>
          <text
            x={x + width / 2}
            y={y - 20}
            fill="#007bff"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 16, fontWeight: 'bold' }} // Fonte maior
          >
            {`${lucroBruto.toFixed(2)} R$`}
          </text>
          <text
            x={x + width / 2}
            y={y}
            fill="#000000"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {`(${percentualLucro.toFixed(2)}%)`}
          </text>
        </>
      ) : isPercentualLucroSelected ? (
        <>
          <text
            x={x + width / 2}
            y={y - 20}
            fill="#000000"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 16, fontWeight: 'bold' }} // Fonte maior
          >
            {`(${percentualLucro.toFixed(2)}%)`}
          </text>
          <text
            x={x + width / 2}
            y={y}
            fill="#007bff"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {`${lucroBruto.toFixed(2)} R$`}
          </text>
        </>
      ) : (
        <>
          {/* Exibição padrão caso nenhuma opção específica seja selecionada */}
          <text
            x={x + width / 2}
            y={y - 20}
            fill="#007bff"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {`${lucroBruto.toFixed(2)} R$`}
          </text>
          <text
            x={x + width / 2}
            y={y}
            fill="#000000"
            textAnchor="middle"
            dy={-4}
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {`(${percentualLucro.toFixed(2)}%)`}
          </text>
        </>
      )}
    </g>
  )
}
