import { useState, useEffect, useContext } from 'react'
import { Container, Form, Button, Table, Modal } from 'react-bootstrap'
import { AuthContext } from '../../../../src/context/AuthContext.jsx'
import { fetchData } from '../../../../src/backend/model'
import { config } from '../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../src/utils/form-utils.jsx'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'

const CadastroPagFuncionarios = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [funcionarios, setFuncionarios] = useState([])
  const [pagamentosHoje, setPagamentosHoje] = useState([])
  const [dadosAgrupados, setDadosAgrupados] = useState([])
  const [dadosAgrupadosOriginais, setDadosAgrupadosOriginais] = useState([]) // Armazena todos os dados do servidor

  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState(null) // Estado para armazenar o funcionário selecionado

  const [formData, setFormData] = useState({
    funcionarioId: '',
    valorPagamento: '',
    dataPagamento: new Date().toISOString().slice(0, 10),
    detalhes: ''
  })
  const [isEditMode, setIsEditMode] = useState(false)
  const [editId, setEditId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'
  const [periodoTotal, setPeriodoTotal] = useState(true) // Checkbox para período total
  const [dataInicio, setDataInicio] = useState('') // Data de início para intervalo de datas
  const [dataFim, setDataFim] = useState('') // Data de fim para intervalo de datas
  const [showModal, setShowModal] = useState(false) // Estado para exibir ou ocultar o modal
  const [valorTotalSelecionado, setValorTotalSelecionado] = useState(0) // Estado para armazenar o valor total
  const [diasSelecionados, setDiasSelecionados] = useState([]) // Estado para armazenar os dias selecionados
  const [detalhesFuncionario, setDetalhesFuncionario] = useState([]) // Estado para armazenar os detalhes do funcionário
  const [dataInicioPagamentos, setDataInicioPagamentos] = useState(
    new Date().toISOString().slice(0, 10)
  ) // Data de início para intervalos de pagamentos
  const [dataFimPagamentos, setDataFimPagamentos] = useState(new Date().toISOString().slice(0, 10)) // Data de fim para intervalos de pagamentos
  const [isHistorico, setIsHistorico] = useState(false) // Novo estado para controlar se é histórico

  useEffect(() => {
    fetchFuncionarios()
    fetchPagamentosHoje()
    fetchDadosAgrupados()
  }, [RotaBase, tenantId, periodoTotal, dataInicio, dataFim])

  useEffect(() => {
    fetchPagamentosHoje() // Chama a função quando as datas de início ou fim mudarem
  }, [dataInicioPagamentos, dataFimPagamentos])

  const fetchFuncionarios = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      const result = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'listarFuncionarios'
      })
      setFuncionarios(result || [])
      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar funcionários:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar funcionários')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const fetchPagamentosHoje = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarPagFuncionarios`
      const result = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'listarPagamentosPorData',
        dataInicio: dataInicioPagamentos,
        dataFim: dataFimPagamentos
      })
      setPagamentosHoje(result || [])
      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar pagamentos:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar pagamentos')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const fetchDadosAgrupados = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarPagFuncionarios`
      const result = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'obterDetalhesTrabalhoAgrupados',
        dataInicio: periodoTotal ? null : dataInicio,
        dataFim: periodoTotal ? null : dataFim
      })
      console.log('result:', result)
      setDadosAgrupadosOriginais(result || []) // Armazena todos os dados
      setDadosAgrupados(result || [])
      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar dados agrupados:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar dados agrupados')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handlePeriodoChange = () => {
    setPeriodoTotal(!periodoTotal)
    setDataInicio('')
    setDataFim('')
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const url = `${config.host}/gerenciarPagFuncionarios`
    const requisicao = isEditMode ? 'atualizarPagamento' : 'adicionarPagamento'

    const dados = isEditMode
      ? { requisicao, id: editId, ...formData, tenantId, rota_base: RotaBase }
      : { requisicao, ...formData, tenantId, rota_base: RotaBase }

    console.log('dados:', dados)
    const response = await fetchData(url, dados)

    if (response && response.message && response.message.includes('sucesso')) {
      fetchPagamentosHoje()
      fetchDadosAgrupados() // Adiciona esta chamada para atualizar os dados agrupados após adicionar um pagamento com sucesso
      setFormData({
        funcionarioId: '',
        valorPagamento: '',
        dataPagamento: formData.dataPagamento,
        detalhes: ''
      })
      setIsEditMode(false)
      setEditId(null)
      setAlertVariant('success')
      setAlertMessage('Pagamento registrado com sucesso!')
      setShowAlert(true)
    } else {
      setAlertVariant('danger')
      setAlertMessage('Erro ao registrar pagamento.')
      setShowAlert(true)
    }

    setLoading(false)
  }

  // const handleDelete = async id => {
  //   setLoading(true)
  //   const url = `${config.host}/gerenciarPagFuncionarios`
  //   const response = await fetchData(url, {
  //     requisicao: 'deletarPagamento',
  //     id,
  //     tenantId,
  //     rota_base: RotaBase
  //   })
  //   if (response && response.message && response.message.includes('sucesso')) {
  //     fetchPagamentosHoje()
  //     setAlertVariant('success')
  //     setAlertMessage('Pagamento deletado com sucesso!')
  //     setShowAlert(true)
  //   } else {
  //     setAlertVariant('danger')
  //     setAlertMessage('Erro ao deletar pagamento.')
  //     setShowAlert(true)
  //   }
  //   setLoading(false)
  // }

  const handleDelete = async id => {
    // Encontre o pagamento correspondente para verificar se está no histórico
    const pagamento = pagamentosHoje.find(pag => pag._id === id)

    if (pagamento && pagamento.isHistorico) {
      // Se o pagamento estiver no histórico, exibe uma mensagem de aviso e não permite a exclusão
      alert(
        'Este pagamento já foi transferido para o histórico porque ja foi 100% resolvido para todos os dias que devia, e não pode ser deletado. ' +
          'Para alterar algo, por favor adicione um novo pagamento ou edite apenas os detalhes.'
      )
      return // Sai da função sem realizar a exclusão
    }

    // Se o pagamento não estiver no histórico, prossegue com a exclusão
    setLoading(true)
    const url = `${config.host}/gerenciarPagFuncionarios`
    const response = await fetchData(url, {
      requisicao: 'deletarPagamento',
      id,
      tenantId,
      rota_base: RotaBase
    })

    if (response && response.message && response.message.includes('sucesso')) {
      fetchPagamentosHoje()
      fetchDadosAgrupados() // Atualiza os dados agrupados após a exclusão
      setAlertVariant('success')
      setAlertMessage('Pagamento deletado com sucesso!')
      setShowAlert(true)
    } else {
      setAlertVariant('danger')
      setAlertMessage('Erro ao deletar pagamento.')
      setShowAlert(true)
    }
    setLoading(false)
  }

  const handleEdit = pagamento => {
    if (pagamento.isHistorico) {
      // Verifique se existe um campo que indica se é histórico
      alert(
        'Apenas o campo "Detalhes" pode ser editado, pois o pagamento já foi resolvido e transferido para o histórico.'
      )
      setFormData({
        funcionarioId: pagamento.funcionarioId._id,
        valorPagamento: pagamento.valorPagamento,
        dataPagamento: pagamento.dataPagamento.slice(0, 10),
        detalhes: pagamento.detalhes
      })
      setIsEditMode(true)
      setEditId(pagamento._id)
      setIsHistorico(true) // Indica que o pagamento é do histórico
    } else {
      setFormData({
        funcionarioId: pagamento.funcionarioId._id,
        valorPagamento: pagamento.valorPagamento,
        dataPagamento: pagamento.dataPagamento.slice(0, 10),
        detalhes: pagamento.detalhes
      })
      setIsEditMode(true)
      setEditId(pagamento._id)
      setIsHistorico(false) // Indica que o pagamento não é do histórico
    }
  }

  const toggleMostrarFuncionario = funcionarioId => {
    if (funcionarioSelecionado === funcionarioId) {
      // Se o funcionário já estiver selecionado, redefina para mostrar todos
      setFuncionarioSelecionado(null)
      setDadosAgrupados(dadosAgrupadosOriginais) // Volta a exibir todos os dados
    } else {
      // Mostrar apenas os dados do funcionário selecionado
      setFuncionarioSelecionado(funcionarioId)
      const dadosFiltrados = dadosAgrupadosOriginais.filter(
        dado => dado.funcionarioId === funcionarioId
      )
      setDadosAgrupados(dadosFiltrados) // Exibe os dados filtrados
    }
  }

  const handlePagarFuncionario = dado => {
    setFormData({
      funcionarioId: dado.funcionarioId,
      valorPagamento: (
        dado.totalEmDinheiroHorasTrabalhadas + dado.totalEmDinheiroHorasExtras
      ).toFixed(2), // Carrega o valor total formatado
      dataPagamento: new Date().toISOString().slice(0, 10), // Data atual
      detalhes: `Pagamento referente a ${dado.totalHorasTrabalhadas.toFixed(
        2
      )} horas trabalhadas e ${dado.totalHorasExtras.toFixed(2)} horas extras`
    })
  }

  // Função para abrir o modal com detalhes
  const handleShowDetalhes = (detalhado, funcionarioId) => {
    if (Array.isArray(detalhado)) {
      setDetalhesFuncionario(detalhado)

      setFuncionarioSelecionado(funcionarioId) // Armazena o ID do funcionário selecionado

      setValorTotalSelecionado(0) // Reinicia o valor total selecionado ao abrir o modal
      setDiasSelecionados([]) // Reinicia os dias selecionados
    } else {
      setDetalhesFuncionario([])
    }
    console.log('detalhado:', detalhado)
    setShowModal(true)
  }

  // Função para calcular o valor total ao marcar/desmarcar um checkbox
  const handleCheckboxChange = (valor, isChecked, detalhe) => {
    if (isChecked) {
      setValorTotalSelecionado(prevTotal => prevTotal + valor)
      setDiasSelecionados(prevDias => [...prevDias, detalhe]) // Adiciona o detalhe ao estado
    } else {
      setValorTotalSelecionado(prevTotal => prevTotal - valor)
      setDiasSelecionados(prevDias => prevDias.filter(dia => dia !== detalhe)) // Remove o detalhe
    }
  }

  // Função para fechar o modal e atualizar o formulário de pagamento

  const handleCloseModal = () => {
    setShowModal(false)
    if (valorTotalSelecionado > 0 && funcionarioSelecionado) {
      // Filtrando os detalhes selecionados
      const detalhesFiltrados = detalhesFuncionario.filter(detalhe => {
        const isSelected = diasSelecionados.some(
          diaSelecionado => diaSelecionado.data === detalhe.data
        )

        return isSelected
      })

      // Formatando os detalhes para exibição
      const detalhesFormatados = detalhesFiltrados
        .map(
          detalhe =>
            `Data: ${formatarDataBrasil(detalhe.data)}, Horas: ${formatarNumero(
              detalhe.totalHoras
            )}, Extras: ${formatarNumero(detalhe.horasExtras)}`
        )
        .join('\n\n')

      // Atualizando o estado do formulário com os detalhes formatados
      setFormData(prevFormData => ({
        ...prevFormData,
        funcionarioId: funcionarioSelecionado, // Define o funcionário selecionado
        valorPagamento: valorTotalSelecionado.toFixed(2), // Define o valor total formatado
        detalhes: detalhesFormatados
      }))
    }
  }

  // Função para formatar a data para o formato "dd/mm/yyyy"
  const formatarDataBrasil = dataISO => {
    const [ano, mes, dia] = dataISO.split('-')
    return `${dia}/${mes}/${ano}`
  }

  // Função para formatar números para 2 casas decimais
  const formatarNumero = numero => {
    return Number.isInteger(numero) ? numero : numero.toFixed(2)
  }
  return (
    <Container fluid className="p-4">
      <Banner
        title="Cadastro de Pagamentos de Funcionários"
        width="100%"
        fontSize={39}
        classEfeito3dMouseOver=""
      />
      <div className="row mb-4">
        {/* Checkbox para Período Total */}
        <div className="col-md-3">
          <Form.Check
            type="checkbox"
            label="Todo o Período"
            checked={periodoTotal}
            onChange={handlePeriodoChange}
          />
        </div>

        {/* Seletor de Período de Datas */}
        <div className="col-md-6">
          <Form.Group className="d-flex">
            <Form.Control
              type="date"
              value={dataInicio}
              onChange={e => setDataInicio(e.target.value)}
              disabled={periodoTotal}
              className="me-2"
            />
            <Form.Control
              type="date"
              value={dataFim}
              onChange={e => setDataFim(e.target.value)}
              disabled={periodoTotal}
            />
          </Form.Group>
        </div>
      </div>

      <div className="row">
        {/* Coluna da Tabela de Resumo de Trabalho por Funcionário */}
        <div className="col-md-6 mb-4">
          <h4>Resumo de Trabalho por Funcionário</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Funcionário</th>
                <th>Total Dias</th>
                <th>Horas Normais</th>
                <th>Horas Extras</th>
                <th>$ Horas Normais</th>
                <th>$ Horas Extras</th>
                <th>Valor Total</th>
                <th>Total Pago</th> {/* Nova coluna para o valor total pago */}
                <th>Total a Pagar</th> {/* Nova coluna para o valor a pagar */}
                <th>Visualização</th>
                <th>Pagar</th> {/* Nova coluna para a ação de pagamento */}
                <th>Dias</th>
              </tr>
            </thead>
            <tbody>
              {dadosAgrupados.map(dado => (
                <tr key={dado.funcionarioId}>
                  <td>{dado.nomeFuncionario}</td>
                  <td>{dado.totalDeDias}</td>
                  <td>
                    {dado.totalHorasTrabalhadas !== 0
                      ? Number.isInteger(dado.totalHorasTrabalhadas)
                        ? dado.totalHorasTrabalhadas
                        : dado.totalHorasTrabalhadas.toFixed(2)
                      : ''}
                  </td>
                  <td>
                    {dado.totalHorasExtras !== 0
                      ? Number.isInteger(dado.totalHorasExtras)
                        ? dado.totalHorasExtras
                        : dado.totalHorasExtras.toFixed(2)
                      : ''}
                  </td>
                  <td>
                    {dado.totalEmDinheiroHorasTrabalhadas !== 0
                      ? Number.isInteger(dado.totalEmDinheiroHorasTrabalhadas)
                        ? dado.totalEmDinheiroHorasTrabalhadas.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0
                          })
                        : dado.totalEmDinheiroHorasTrabalhadas.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })
                      : ''}
                  </td>
                  <td>
                    {dado.totalEmDinheiroHorasExtras !== 0
                      ? Number.isInteger(dado.totalEmDinheiroHorasExtras)
                        ? dado.totalEmDinheiroHorasExtras.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0
                          })
                        : dado.totalEmDinheiroHorasExtras.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })
                      : ''}
                  </td>
                  <td>
                    {dado.totalEmDinheiroHorasTrabalhadas + dado.totalEmDinheiroHorasExtras !== 0
                      ? (
                          dado.totalEmDinheiroHorasTrabalhadas + dado.totalEmDinheiroHorasExtras
                        ).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : ''}
                  </td>
                  <td>
                    {dado.valorTotalPago != null && !isNaN(dado.valorTotalPago) // Verifica se o valor total pago não é nulo ou NaN
                      ? dado.valorTotalPago.toLocaleString('pt-BR', {
                          // Converte o valor para o formato de moeda brasileiro
                          style: 'currency', // Define o estilo como moeda
                          currency: 'BRL' // Define a moeda como Real Brasileiro
                        })
                      : 'R$ 0,00'}{' '}
                    {/* Se o valor for nulo, indefinido ou NaN, exibe 'R$ 0,00' */}
                  </td>
                  <td style={{ fontSize: '25px' }}>
                    {dado.valorTotalFaltandoPagar != null && !isNaN(dado.valorTotalFaltandoPagar) // Verifica se o valor total a pagar não é nulo ou NaN
                      ? dado.valorTotalFaltandoPagar.toLocaleString('pt-BR', {
                          // Converte o valor para o formato de moeda brasileiro
                          style: 'currency', // Define o estilo como moeda
                          currency: 'BRL' // Define a moeda como Real Brasileiro
                        })
                      : 'R$ 0,00'}{' '}
                    {/* Se o valor for nulo, indefinido ou NaN, exibe 'R$ 0,00' */}
                  </td>
                  <td>
                    <Button
                      variant={
                        funcionarioSelecionado === dado.funcionarioId
                          ? 'outline-danger'
                          : 'outline-primary'
                      }
                      size="sm"
                      className="px-3 py-1"
                      onClick={() => toggleMostrarFuncionario(dado.funcionarioId)}
                      style={{
                        fontSize: '0.85rem',
                        borderRadius: '4px',
                        boxShadow: 'none'
                      }}
                    >
                      {funcionarioSelecionado === dado.funcionarioId
                        ? 'Mostrar Todos'
                        : 'Apenas esse'}
                    </Button>
                  </td>
                  {/* Nova célula para o botão "Pagar esse" */}
                  <td>
                    <Button
                      variant="outline-success" // Botão verde para pagamento
                      size="sm"
                      className="px-3 py-1"
                      onClick={() => handlePagarFuncionario(dado)} // Função para preencher o formulário
                      style={{
                        fontSize: '0.85rem',
                        borderRadius: '4px',
                        boxShadow: 'none'
                      }}
                    >
                      Pagar esse
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outline-dark" // Usando a cor preta para o botão
                      size="sm"
                      className="px-3 py-1"
                      onClick={() => handleShowDetalhes(dado.Detalhado, dado.funcionarioId)} // Mostra o modal com os detalhes
                      style={{
                        fontSize: '0.85rem',
                        borderRadius: '4px',
                        boxShadow: 'none'
                      }}
                    >
                      Ver todos
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Coluna do Formulário de Cadastro de Pagamentos */}
        <div className="col-md-6">
          <Form onSubmit={handleSubmit} className="mb-4">
            <h4>{isEditMode ? 'Editar Pagamento' : 'Cadastrar Pagamento'}</h4>
            <div className="row">
              {/* Primeira Coluna */}
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Funcionário:</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.funcionarioId}
                    onChange={e => setFormData({ ...formData, funcionarioId: e.target.value })}
                    disabled={isHistorico} // Desabilita se for histórico
                    required
                  >
                    <option value="">Selecione um funcionário</option>
                    {funcionarios.map(func => (
                      <option key={func._id} value={func._id}>
                        {func.Nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Data do Pagamento:</Form.Label>
                  <Form.Control
                    type="date"
                    value={formData.dataPagamento}
                    onChange={e => setFormData({ ...formData, dataPagamento: e.target.value })}
                    disabled={isHistorico} // Desabilita se for histórico
                    required
                  />
                </Form.Group>
              </div>

              {/* Segunda Coluna */}
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Valor do Pagamento:</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.valorPagamento}
                    onChange={e => setFormData({ ...formData, valorPagamento: e.target.value })}
                    disabled={isHistorico} // Desabilita se for histórico
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Detalhes:</Form.Label>

                  <Form.Control
                    as="textarea"
                    value={formData.detalhes}
                    onChange={e => setFormData({ ...formData, detalhes: e.target.value })}
                    style={{ height: '200px' }} // Aumenta a altura para 120px
                    disabled={false} // Permite edição mesmo se for histórico
                  />
                </Form.Group>
              </div>
            </div>
            <Button variant="primary" type="submit">
              {isEditMode ? 'Salvar Alterações' : 'Adicionar Pagamento'}
            </Button>
            {isEditMode && (
              <Button
                variant="secondary"
                className="ms-2"
                onClick={() => {
                  setIsEditMode(false)
                  setEditId(null)
                  setFormData({
                    funcionarioId: '',
                    valorPagamento: '',
                    dataPagamento: new Date().toISOString().slice(0, 10),
                    detalhes: ''
                  })
                  setIsHistorico(false) // Reseta para o modo normal
                }}
              >
                Cancelar
              </Button>
            )}
          </Form>
        </div>
      </div>

      <h4>Pagamentos Registrados</h4>
      <div className="d-flex flex-row align-items-center mb-3">
        <Form.Control
          type="date"
          value={dataInicioPagamentos}
          onChange={e => setDataInicioPagamentos(e.target.value)}
          className="me-3"
          style={{ width: 'auto' }}
        />
        <Form.Control
          type="date"
          value={dataFimPagamentos}
          onChange={e => setDataFimPagamentos(e.target.value)}
          style={{ width: 'auto' }}
        />
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Funcionário</th>
            <th>Valor</th>
            <th>Data</th>
            <th>Detalhes</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {pagamentosHoje.map(pagamento => (
            <tr key={pagamento._id}>
              <td>{pagamento.funcionarioId.Nome}</td>
              <td>
                {pagamento.valorPagamento !== undefined && !isNaN(pagamento.valorPagamento) // Verifica se o valor não é undefined ou NaN
                  ? Number(pagamento.valorPagamento).toLocaleString('pt-BR', {
                      // Converte o valor para número e formata para Real
                      style: 'currency', // Estilo de formatação como moeda
                      currency: 'BRL' // Define a moeda como Real Brasileiro
                    })
                  : ''}{' '}
                {/* Exibe 'R$ 0,00' se o valor for undefined ou NaN */}
              </td>

              <td>{new Date(pagamento.dataPagamento).toLocaleDateString()}</td>
              <td>{pagamento.detalhes}</td>
              <td>
                <Button variant="warning" size="sm" onClick={() => handleEdit(pagamento)}>
                  Alterar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleDelete(pagamento._id)}
                >
                  Deletar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
      {/* Modal para Detalhes */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Trabalho por Dia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>Total Selecionado: </strong>
            {valorTotalSelecionado.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Total Horas</th>
                <th>Valor Horas Trabalhadas</th>
                <th>Horas Extras</th>
                <th>Valor Horas Extras</th>
                <th>Dia da Semana</th>
                <th>Pagar</th> {/* Nova coluna para o checkbox de pagamento */}
              </tr>
            </thead>
            <tbody>
              {detalhesFuncionario.map((detalhe, index) => (
                <tr key={index}>
                  <td>
                    {detalhe.data.split('-').reverse().join('/')}{' '}
                    {/* Converte a data ISO diretamente para o formato dd/mm/yyyy */}
                  </td>
                  <td>
                    {detalhe.totalHoras !== 0
                      ? Number.isInteger(detalhe.totalHoras)
                        ? detalhe.totalHoras
                        : detalhe.totalHoras.toFixed(2)
                      : ''}
                  </td>
                  <td>
                    {detalhe.valorHorasTrabalhadas !== 0
                      ? detalhe.valorHorasTrabalhadas.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : ''}
                  </td>
                  <td>
                    {detalhe.horasExtras !== 0
                      ? Number.isInteger(detalhe.horasExtras)
                        ? detalhe.horasExtras
                        : detalhe.horasExtras.toFixed(2)
                      : ''}
                  </td>
                  <td>
                    {detalhe.valorHorasExtras !== 0
                      ? detalhe.valorHorasExtras.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : ''}
                  </td>
                  <td>{detalhe.diaSemana}</td>
                  {/* Checkbox para selecionar o pagamento */}
                  <td>
                    <Form.Check
                      type="checkbox"
                      onChange={e =>
                        handleCheckboxChange(
                          detalhe.valorHorasTrabalhadas + detalhe.valorHorasExtras,
                          e.target.checked,
                          detalhe // Passa o detalhe do dia como parâmetro
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default CadastroPagFuncionarios
