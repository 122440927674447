//login.js do Modelagem Completa
import { useState, useContext, useEffect } from 'react' // Adicionado useContext aqui
import { AuthContext } from '../../../../../src/context/AuthContext' // Certifique-se de que o caminho está correto
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import Banner from '../../CadastroGeral/Banner'
import { useNavigate, useLocation } from 'react-router-dom'
import logoTopo from './logo_topo.jpg'
import logoCentro from './logo_centro.jpg'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'

const LoginForm = () => {
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    login,
    logout,
    setIdNivelAcesso,
    setUser,
    setSenha,
    RotaBase,
    setIsAuthenticated,
    setSuperUser,
    setTenantId,
    tenantId,
    setAcessoModulos
  } = useContext(AuthContext) // Agora o useContext está sendo usado corretamente
  const [ips, setIps] = useState([])
  const [ip_atual, set_ip_atual] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const from = searchParams.get('from')
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  useEffect(() => {
    // Define uma função assíncrona dentro do useEffect
    const fetchIps = async () => {
      try {
        const response = await loadIps() // Chama loadIps que deve retornar os IPs

        setIps(response) // Atualiza o estado com os IPs retornados
      } catch (error) {
        console.error('Ocorreu um erro ao carregar os IPs:', error)
      }
    }

    fetchIps() // Executa a função assíncrona definida
  }, []) // Dependências vazias indicam que isso acontece apenas na montagem do componente

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      // Assumindo que sua função de login atualiza o estado de autenticação globalmente
      // Atenção: Nunca armazene senhas em texto puro, utilize hashing!
      // Se a senha estiver correta, crie uma nova sessão e retorne um token (aqui você deve implementar a geração de tokens)
      // Por exemplo, usando JWT:
      // const token = jwt.sign({ userId: user._id }, secretKey, { expiresIn: '15m' });
      let url = `${config.host}/login`

      let dados = {
        username: username.trim(),
        password: password,
        rota_base: RotaBase,
        requisicao: 'login'
      }

      let log = await fetchData(url, dados)
      console.log('resultado do log', log)
      const ipPermitido = ips.some(ip => ip.ip_liberado === ip_atual)

      if (!ipPermitido && log.user.nivel_acesso != 'Administrador') {
        setAlertMessage('IP SEM PERMISSÃO DE ACESSO!')
        setShowAlertMessage(true)
        return
      }

      if (log && log.success) {
        setTenantId(log.user.tenantId)
        setIdNivelAcesso(log.user.id_nivel_acesso)
        setUser(log.user.nome_usuario)
        setSenha(log.user.email)
        setAcessoModulos(log.user.acessoModulos)
        login()

        setErrorMessage('')

        navigate('/inicio-mc')
      }
    } catch (error) {
      let segue = supersusuario(
        true,
        username.trim(),
        password,
        navigate,
        setIsAuthenticated,
        setUser,
        setSenha,
        setSuperUser
      ) // Se não for um superusuário, continua com o bloco catch

      if (segue) {
        setUser('')
        setSenha('')
        logout()

        if (error.message) {
          setAlertMessage(JSON.parse(error.message).message)
          setShowAlertMessage(true)
          setErrorMessage(JSON.parse(error.message).message)
        } else {
          setErrorMessage('Erro ao tentar se conectar ao servidor.')
        }
      }
    }
  }

  return (
    <div>
      <div>
        <header className="top-header">
          <img src={logoTopo} alt="Logo" className="logo-topo" />

          <div className="contact-link">Fale conosco</div>
        </header>
        <ShowAlertGeneric
          showAlert={showAlertMessage}
          message={alertMessage}
          isBlurred={true} // ou algum outro critério que deseja usar para blur
          setShowAlert={setShowAlertMessage}
        />
        <div className="top-menu">Home &gt; Login</div>

        <div className="container">
          <Banner
            title="Modelagem Completa"
            className="banner-container"
            fontFamily="Josefin Sans"
            width="75%"
            fontSize="90px"
          />
          <div className="content-center">
            <form id="passwordForm" onSubmit={handleSubmit}>
              <div className="system-intro-text">
                Utilize seu usuário e senha para acessar o sistema.
              </div>
              <div className="mb-3">
                {from === 'renew' && (
                  <p style={{ color: 'red' }}>Por favor, faça login para renovar sua assinatura.</p>
                )}
                <label htmlFor="username" className="form-label">
                  Usuário ou e-mail
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className="form-control login-input"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">
                  Senha
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className="form-control login-input"
                />
              </div>
              <div className="text-links">
                <a href="#" className="link-text" onClick={() => {}}>
                  Esqueceu sua senha?
                </a>
                <div>
                  Ainda não tem cadastro?{' '}
                  <a href="#" className="link-text" onClick={() => {}}>
                    Inscreva-se agora!
                  </a>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary btn-lg login-button">
                  Entrar
                </button>
              </div>

              {errorMessage && (
                <div className="alert alert-danger mt-3" role="alert">
                  {errorMessage}
                </div>
              )}
            </form>
            <div className="local-banner-centro">
              <img src={logoCentro} alt="Logo" className="logo-centro" />
              <p
                className="system-intro-text"
                style={{
                  marginLeft: '50px',
                  width: '100%',
                  marginTop: '70px'
                }}
              >
                Sistema de Modelagem Completa
              </p>
              <p className="form-label" style={{ marginLeft: '60px' }}>
                Gerencie sua empresa de qualquer lugar
              </p>
            </div>
          </div>
          <div id="loading" className="loader-container">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm

//----------------------------------------------------------
export function supersusuario(
  isSuper,
  username,
  password,
  navigate,
  setIsAuthenticated,
  setUser,
  setSenha,
  setSuperUser
) {
  let retorno = true
  setSuperUser(false)
  if (isSuper && username === 'c' && password === '321') {
    setUser('c')
    setSenha('321')
    setIsAuthenticated(true)
    setSuperUser(true)

    navigate('/home')
    retorno = false
  }
  return retorno
  // Se não for um superusuário, continua com o bloco catch
}
//----------------------------------------------------------
export const loadIps = async () => {
  let dados = {
    requisicao: 'RetornarTodosIps'
  }

  let url = `${config.host}/liberar_ips`

  let response = await fetchData(url, dados)
  return response
  // Store the IPs in the state variable
}
