import { useState, useEffect } from 'react'
import { Button, Col, Row, FormGroup, Alert, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { setShowAlertGravando, setShowAlert } from '../../../../src/utils/actions'

import Banner from '../../../../src/components/pages/CadastroGeral/Banner'

import AlertMessage from '../../../../src/components/pages/CadastroPlotagem/AlertMessage'
import { buscarServicos } from '../../../../src/servicos/dadosServicos'

import sheetsConfig from '../../../../src/config/sheetsConfig'
import { handleForm } from '../../../../src/components/pages/CadastroGeral/cadastro/cadastro-fields'
import {
  buscarClientes,
  getServicosAssociados
} from '../../../../src/AssociacaoClienteServico/dados-associacao-cliente-servico'
import { SelectWithLoading } from '../../../../src/components/SelectWithLoading/SelectWithLoading'
import SelectFixedWithLoading from '../../../../src/components/SelectFixedWithLoading/SelectFixedWithLoading'

function AssociacaoClienteServico({ isEditing = false }) {
  const [servicosOptions, setServicosOptions] = useState([])
  const [selectAllServices, setSelectAllServices] = useState(false)
  const [clientesOptions, setClientesOptions] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const showAlertGravando = useSelector(state => state.showAlertGravando)
  const showAlert = useSelector(state => state.showAlert)
  const [isLoadingClientes, setisLoadingClientes] = useState(false)
  const [isLoadingServicos, setisLoadingServicos] = useState(false)
  const [travarCliente, setTravarCliente] = useState(false)
  const [showClientAlert, setShowClientAlert] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    if (showClientAlert) {
      const timer = setTimeout(() => {
        setShowClientAlert(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [showClientAlert])

  const [formState, setFormState] = useState({
    id_cliente: null,
    id_servico: null,
    ativo: true
  })

  //--------------------//
  const toggleSelectAllServices = () => {
    setSelectAllServices(!selectAllServices)
    if (!selectAllServices) {
      updateField('id_servico', servicosOptions)
    } else {
      updateField('id_servico', [])
    }
  }
  //--------------------//
  const cadastroDados = formState.id_servico
    ? formState.id_servico.map(idServico => {
        return {
          id_cliente: formState.id_cliente,
          id_servico: idServico,
          ativo: formState.ativo
        }
      })
    : []

  const getSelectValue = (options, currentValue, isLoaded, isMulti) => {
    if (!isLoaded) {
      return null
    }

    if (isMulti) {
      if (Array.isArray(currentValue)) {
        return currentValue.map(value => options.find(option => option.value === value))
      } else {
        return []
      }
    } else {
      const selectedOption = options.find(option => option.value === currentValue)
      return selectedOption || null
    }
  }

  const [isClearable, setIsClearable] = useState(true)

  useEffect(() => {
    setisLoadingClientes(true)
    Promise.all([buscarClientes(), buscarServicos()]).then(([clientes, servicos]) => {
      const clientesOptions = clientes.map(cliente => {
        return {
          value: cliente.id,
          label: cliente.nome
        }
      })

      const servicosOptions = servicos.map(servico => {
        return {
          value: servico.id_servico,
          label: servico.nome_servico
        }
      })

      setClientesOptions(clientesOptions)
      setServicosOptions(servicosOptions)

      setIsLoaded(true)
      setisLoadingClientes(false)
    })
  }, [])

  const [servicosAssociados, setServicosAssociados] = useState([])

  useEffect(() => {
    buscarServicos().then(servicos => {
      const servicosAssociadosIds = servicosAssociados.map(servico => String(servico.id_servico))

      const servicosOptions = servicos
        .filter(servico => !servicosAssociadosIds.includes(servico.id_servico))
        .map(servico => {
          return {
            value: servico.id_servico,
            label: servico.nome_servico
          }
        })

      setServicosOptions(servicosOptions)

      setisLoadingServicos(false)
    })
  }, [servicosAssociados])

  //
  //-----------------------------------------------

  const clearSelectedOptions = () => {
    updateField('id_servico', [])
  }
  //-----------------------------------------------
  const updateField = (field, selectedOption) => {
    if (typeof selectedOption === 'boolean') {
      setFormState({
        ...formState,
        [field]: selectedOption
      })
    } else {
      if (Array.isArray(selectedOption)) {
        // Handle multiple selections
        const selectedValues = selectedOption.map(option => option.value)
        setFormState({
          ...formState,
          [field]: selectedValues
        })
      } else {
        // Handle single selection
        setFormState({
          ...formState,
          [field]: selectedOption ? selectedOption.value : null
        })
      }
    }
  }

  //-----------------------------------------------
  const isPaidChecked = formState.ativo
  const resetForm = () => {
    setFormState(prevState => ({
      ...Object.keys(formState).reduce((acc, key) => {
        if (key === 'ativo') {
          acc[key] = prevState.ativo
        } else if (key === 'id_cliente' && travarCliente) {
          acc[key] = prevState.id_cliente
        } else {
          acc[key] = null
        }
        return acc
      }, {})
    }))
  }

  //-----------------------------------------------
  const handleCloseAlert = () => {
    dispatch(setShowAlert(false))
  }
  //-----------------------------------------------

  //-----------------------------------------------
  const updateServicosAssociados = async clienteId => {
    try {
      if (clienteId) {
        setisLoadingServicos(true)
        const servicos = await getServicosAssociados(clienteId)
        setServicosAssociados(servicos)
      } else {
        setServicosAssociados([]) // limpar a lista de serviços associados quando o cliente for desmarcado
      }
    } catch (error) {
      console.error('Erro ao buscar serviços associados:', error)
      setisLoadingServicos(false) // Se houver um erro, também queremos desativar o carregamento
    }
  }
  //-----------------------------------------------
  const handleClienteChange = selectedOption => {
    updateField('id_cliente', selectedOption)

    updateServicosAssociados(selectedOption?.value)
  }
  //-----------------------------------------------
  const handleSubmit = async e => {
    e.preventDefault()
    if (!formState.id_cliente) {
      setShowClientAlert(true)
      return
    }
    dispatch(setShowAlertGravando(true))

    await SalvarAssociacaoClienteServico(isEditing, cadastroDados)
    dispatch(setShowAlertGravando(false))
    dispatch(setShowAlert(true))

    setTimeout(() => {
      dispatch(setShowAlert(false))
    }, 2000)

    if (travarCliente) {
      const clienteId = formState.id_cliente // Certifique-se de que isso é o ID do cliente

      await updateServicosAssociados(clienteId)
    }

    resetForm()
  }

  //-----------------------------------------------
  return (
    <div className="d-flex align-items-center form-container" style={{ height: '100vh' }}>
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <Banner title="ASSOCIAÇÃO CLIENTES A SERVIÇOS" fontSize="60px" />
        <h2 style={{ marginBottom: '3rem' }}>NOVA ASSOCIAÇÃO DE CLIENTE A SERVIÇO</h2>
        {showAlertGravando && (
          <AlertMessage
            message="Gravando dados"
            size="x-large"
            customHeight={100}
            variant1="primary"
            variant2="light"
            variantDefault="success"
            isBlinking={false}
          />
        )}
        {showClientAlert && (
          <Alert variant="warning" dismissible>
            <Alert.Heading>Selecione um cliente para associar!</Alert.Heading>
          </Alert>
        )}
        {showAlert && (
          <Alert variant="success" onClose={handleCloseAlert} dismissible>
            <Alert.Heading>Cadastro realizado com sucesso!</Alert.Heading>
          </Alert>
        )}
        <FormGroup>
          <form>
            <Row>
              <Col md={6} className="mb-3">
                <SelectWithLoading
                  id="id_cliente"
                  isClearable={isClearable}
                  options={clientesOptions}
                  value={getSelectValue(clientesOptions, formState.id_cliente, isLoaded, false)}
                  onChange={handleClienteChange}
                  placeholder="Selecione o cliente"
                  isLoading={isLoadingClientes}
                  showLoading={true}
                  variant1="primary"
                  variant2="light"
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px'
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    id="travarCliente"
                    label=""
                    checked={travarCliente}
                    onChange={() => setTravarCliente(!travarCliente)}
                    style={{ position: 'relative', left: '0px' }}
                  />
                  <label htmlFor="travarCliente" style={{ position: 'relative', left: '0px' }}>
                    Travar Cliente
                  </label>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <SelectFixedWithLoading
                  Options={servicosOptions}
                  id="id_servico"
                  name="servicos"
                  value={getSelectValue(servicosOptions, formState.id_servico, isLoaded, true)}
                  variant1="primary"
                  variant2="light"
                  showLoading={true}
                  isLoading={isLoadingServicos}
                  onChange={selectedOption => updateField('id_servico', selectedOption)}
                  placeholder="Selecione o serviço"
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px'
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    id="selectAllServices"
                    label=""
                    checked={selectAllServices}
                    onChange={toggleSelectAllServices}
                    style={{ marginTop: '0px' }}
                  />
                  <label
                    htmlFor="SelecionarTodosServicos"
                    style={{ position: 'relative', left: '0px' }}
                  >
                    Selecionar todos os serviços
                  </label>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 4, offset: 4 }} className="mb-3">
                <Button
                  type="submit"
                  className="form-control btn-success btnSubmit"
                  onClick={handleSubmit}
                >
                  Associar
                </Button>
              </Col>
            </Row>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default AssociacaoClienteServico

export async function SalvarAssociacaoClienteServico(isEditing, cadastroDados, ...extraArgs) {
  const structSheet = 'cadastroClienteServico'
  const planilha = sheetsConfig[structSheet].sheetUrl //shetsConfig é um objeto que contém as configurações de cada planilha, como nome, url, etc
  const pagina = sheetsConfig[structSheet].tabs.clientes_servicos

  const todasColunasSheet = ['id', 'Data', 'ID_Cliente ', 'id_servico', 'Ativo']

  const colunasParaGravarSheet = ['id', 'Data', 'ID_Cliente ', 'id_servico', 'Ativo']

  const columnAliases = {
    // se nao tiver nada para trocar, deixe vazio. Quando usar isso, o nome da coluna na planilha deve ser igual ao nome do campo no formulario, e pode acontecer de o nome do campo no formulario ser diferente do nome da coluna na planilha, mas nao quer alterar o nome da coluna na planilha, nem no formulario
    id: 'id'
    // ... Adicione outros mapeamentos, se necessário
  }

  //-----------------------------------------------

  return await handleForm(
    // essa funcao faz o tratamento dos dados do formulario, e envia para a funcao que grava na planilha
    isEditing,
    cadastroDados,
    null,
    null,
    todasColunasSheet,
    colunasParaGravarSheet,
    columnAliases,
    planilha,
    pagina,
    'enviar_servicos_cliente',
    null,
    ...extraArgs
  )
}
