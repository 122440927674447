import PropTypes from 'prop-types'

import { Row, Col, Alert } from 'react-bootstrap'
//import moment from "moment";

import { Card } from 'react-bootstrap'

export function Totalizador({ valor, width = '100%', visibilidade = 'visible', className = '' }) {
  Totalizador.propTypes = {
    valor: PropTypes.number.isRequired, // Valida que valor é um número e é obrigatório
    width: PropTypes.string, // Valida que width é uma string
    visibilidade: PropTypes.string, // Valida que visibilidade é uma string
    className: PropTypes.string // Valida que className é uma string
  }

  const formatado = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor)

  const cardStyle = {
    width: width,
    visibility: visibilidade
  }
  const headerStyle = {
    fontSize: '1.2em' // Ajuste este valor para aumentar ou diminuir o tamanho da fonte conforme necessário.
  }
  const TitleStyle = {
    fontSize: '1.5em' // Ajuste este valor para aumentar ou diminuir o tamanho da fonte conforme necessário.
  }

  return (
    <Card className={'text-center mt-4 ' + className} style={cardStyle}>
      <Card.Header style={headerStyle}>Total geral</Card.Header>
      <Card.Body>
        <Card.Title style={TitleStyle}>{formatado}</Card.Title>
      </Card.Body>
    </Card>
  )
}
//---------------------------------------------------------------------------------
export const CheckboxControl = ({ checked, onChange, label }) => {
  CheckboxControl.propTypes = {
    checked: PropTypes.bool.isRequired, // Valida que checked é um boolean e é obrigatório
    onChange: PropTypes.func.isRequired, // Valida que onChange é uma função e é obrigatório
    label: PropTypes.string.isRequired // Valida que label é uma string e é obrigatório
  }
  return (
    <Row>
      <Col>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="enableDrag"
            checked={checked}
            onChange={onChange}
          />
          <label className="form-check-label" htmlFor="enableDrag">
            {label}
          </label>
        </div>
      </Col>
    </Row>
  )
}
//---------------------------------------------------------------------------------

export const ShowAlertGeneric = ({
  showAlert,
  message,
  isBlurred = true,
  setShowAlert,
  variant = 'danger',
  className = '',
  fontSize = '1.5em',
  dismissible = true, // Propriedade adicionada para controle do botão de fechar
  timeOut = 3000 // Propriedade adicionada para controle do tempo de exibição do alerta
}) => {
  ShowAlertGeneric.propTypes = {
    showAlert: PropTypes.bool,
    message: PropTypes.string, // Valida que message é uma string
    isBlurred: PropTypes.bool, // Valida que isBlurred é um boolean
    setShowAlert: PropTypes.func, // Valida que setShowAlert é uma função
    variant: PropTypes.string, // Valida que variant é uma string
    className: PropTypes.string, // Valida que className é uma string
    fontSize: PropTypes.string, // Valida que fontSize é uma string
    dismissible: PropTypes.bool, // Valida que dismissible é um boolean
    timeOut: PropTypes.number // Valida que timeOut é um número
  }

  if (showAlert && isBlurred) {
    // Fecha o alerta após 3 segundos
    setTimeout(() => {
      setShowAlert(false)
    }, timeOut)

    return (
      <div
        style={{
          display: 'flex',
          zIndex: 1000,
          position: 'absolute',
          top: '10%', // Ajustado para evitar sobreposição com a barra de navegação
          left: '50%',
          transform: 'translate(-50%, -50%)'
          // Outros estilos podem ser adicionados aqui
        }}
      >
        <Alert
          variant={variant}
          className={className}
          style={{ position: 'relative', paddingRight: '2rem' }} // Necessário para posicionar o botão e ajustar o padding
        >
          <span style={{ fontSize: fontSize }}>{message}</span>
          {dismissible && (
            <button
              type="button"
              className="close custom-close"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
              style={{
                position: 'absolute',
                top: '1%',
                right: '-10px', // Ajuste este valor conforme necessário
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                fontSize: '2rem',
                lineHeight: '1',
                color: '#000',
                opacity: '0.5'
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </Alert>
      </div>
    )
  }
  return null
}
//---------------------------------------------------------------------------------
