import { format, parseISO, parse, isValid } from 'date-fns'
import { da, ptBR } from 'date-fns/locale'
// import pt from 'date-fns/locale/pt';

import React, { useState, useEffect } from 'react'

import { DraggableContainer } from '/src/components/DraggableContainer/draggable-container'
import DatePicker from 'react-datepicker'

export function gerarData(dataSelecionada) {
  if (dataSelecionada) {
    return dataSelecionada
  } else {
    const dataAtual = new Date()
    const dia = String(dataAtual.getDate()).padStart(2, '0')
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0')
    const ano = dataAtual.getFullYear()
    return `${dia}/${mes}/${ano}`
  }
}

export function formatDate(dateString) {
  if (!dateString) {
    return ''
  }
  let dataretorno

  // Verifica se a data está no formato "dd/mm/yyyy"
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return dateString
  }
  // Verifica se a data está no formato "yyyy-mm-dd" e converte para "dd/mm/yyyy"
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-')
    dataretorno = `${day}/${month}/${year}`

    return dataretorno
  }

  const date = new Date(dateString)

  if (isNaN(date.getTime())) {
    return ''
  }

  // Ajuste para considerar a data como UTC
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const year = String(date.getUTCFullYear())
  dataretorno = `${day}/${month}/${year}`

  return dataretorno
}
//--------------------------------------------------------------------------
export const formatDateForInput = dateString => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return date.toISOString().split('T')[0]
}

//-------------------------------------------------------------

export function autoFormatDate(input) {
  input = input.replace(/\D/g, '')
  if (input.length > 4) {
    input = input.substring(0, 2) + '/' + input.substring(2, 4) + '/' + input.substring(4)
  } else if (input.length > 2) {
    input = input.substring(0, 2) + '/' + input.substring(2)
  }
  return input
}

//--------------------------------------------------------------------------

export const DatePickerCustom = ({
  draggableId = null,
  components = null,
  data,
  id,
  placeholder,
  selected,
  onChange,
  autoClearOnMount = false, /// HACK: Esta propriedade é utilizada para automaticamente "limpar" a data na montagem do componente.
  // A intenção por trás disso é forçar uma re-renderização do DatePicker, corrigindo um problema
  // onde a data atual não aparece estilizada na primeira vez que o componente é aberto.
  width = 'auto',
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const clearDate = () => {
    onChange(old => ({ ...old, [id]: null }))
    setIsCleared(true)
  }

  useEffect(() => {
    if (autoClearOnMount) {
      clearDate()
    }
  }, [autoClearOnMount])

  const isDateStringValid = dateString => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/
    const match = dateString.match(regex)
    if (!match) return false

    const day = parseInt(match[1], 10)
    const month = parseInt(match[2], 10) - 1
    const year = parseInt(match[3], 10)

    const parsedDate = new Date(year, month, day)
    return !isNaN(parsedDate.getTime())
  }

  const handleInputChange = e => {
    let formattedInput = autoFormatDate(e.target.value)
    e.target.value = formattedInput
    setInputValue(formattedInput)

    if (isDateStringValid(formattedInput)) {
      const parsedDate = parse(formattedInput, 'dd/MM/yyyy', new Date())
      onChange(old => ({ ...old, [id]: parsedDate }))
    }
  }

  useEffect(() => {
    if (isValid(data)) {
      const formattedValue = format(data, 'dd/MM/yyyy')
      setInputValue(formattedValue)
    } else {
      setInputValue('')
    }
  }, [data])

  const handleFocus = e => {
    const rowElement = e.target.closest('.row')
    if (rowElement) rowElement.classList.add('selected-z-index')
  }

  const handleBlur = e => {
    const rowElement = e.target.closest('.row')
    if (rowElement) rowElement.classList.remove('selected-z-index')
  }

  let selectedDate = typeof selected === 'string' ? formatDate(selected) : selected || null

  const renderDatePickerContent = () => (
    <div className="form-field-wrapper" style={{ width: width }}>
      {selected && <label className="label-above-field">{placeholder}</label>}
      <DatePicker
        id={id}
        selected={selectedDate}
        onFocus={handleFocus}
        isClearable={true}
        onSelect={date => onChange(old => ({ ...old, [id]: date }))}
        onChange={date => {
          if (date === null) {
            setIsCleared(true)
            onChange(old => ({ ...old, [id]: null }))
          } else {
            setIsCleared(false)
            // seu código existente para tratar a seleção de uma data
          }
        }}
        onBlur={handleBlur}
        locale={ptBR}
        customInput={
          <input
            id={`input-${id}`}
            className="form-control"
            onInput={handleInputChange}
            // autoComplete='off'

            type="text"
            autoComplete="new-password"
          />
        }
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        {...rest}
      />
    </div>
  )

  if (draggableId && components) {
    return (
      <DraggableContainer
        id={draggableId}
        components={components}
        width={width}
        positionConfig={rest.positionConfig}
      >
        {renderDatePickerContent()}
      </DraggableContainer>
    )
  }

  return renderDatePickerContent()
}
//-------------------------------------------------------------------------
function isIsoDate(value) {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/

  if (value instanceof Date) {
    return isoRegex.exec(value.toISOString())
  }

  return null
}

export function formatIsoDates(obj) {
  const formattedObj = {}

  Object.keys(obj).forEach(key => {
    if (isIsoDate(obj[key])) {
      formattedObj[key] = formatDate(obj[key])
    } else {
      formattedObj[key] = obj[key]
    }
  })

  return formattedObj
}
//------------------------------------------------------------------------------------
export const formatCurrentTime = inputDate => {
  let date
  // Uso:

  // Se inputDate for fornecido, converta-o para um objeto Date; caso contrário, use a data/hora atual
  if (inputDate) {
    if (typeof inputDate === 'string') {
      date = new Date(inputDate)
    } else if (inputDate instanceof Date) {
      date = inputDate
    } else {
      throw new Error('O argumento fornecido não é uma string ou objeto Date válido.')
    }
  } else {
    date = new Date()
  }

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}
//------------------------------------------------------------------------------------
export function convertToDateObject(dateString) {
  // Verifica se a string de data está no formato "DD/MM/YYYY"

  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    // Separa a string de data em dia, mês e ano
    const parts = dateString.split('/')

    // Reordena as partes para o formato "YYYY-MM-DD"
    const isoFormatted = `${parts[2]}-${parts[1]}-${parts[0]}`

    // Cria e retorna um objeto Date
    return new Date(isoFormatted)
  } else {
    // Se não estiver no formato esperado, retorna null ou lança um erro
    console.error('Formato de data inválido:', dateString)
    return null
  }
}

// export function ajustarDataParaSalvar(dataInput) {
//   // Criando um novo objeto Date com base na data de entrada
//   let dataCorte = new Date(dataInput)

//   // Obtendo a data e hora atuais
//   const now = new Date()

//   // Ajustando a hora para a hora local atual
//   dataCorte.setHours(now.getHours(), now.getMinutes(), now.getSeconds())

//   // Ajustando a data para refletir a hora local, mas mantendo como se fosse UTC
//   const dataCorteParaSalvar = new Date(
//     Date.UTC(
//       dataCorte.getFullYear(),
//       dataCorte.getMonth(),
//       dataCorte.getDate(),
//       dataCorte.getHours(),
//       dataCorte.getMinutes(),
//       dataCorte.getSeconds()
//     )
//   )

//   // Retorna a data ajustada, pronta para ser salva no MongoDB
//   return dataCorteParaSalvar
// }
export function ajustarDataParaSalvar(dataInput, formato) {
  // Criando um novo objeto Date com base na data de entrada
  let dataCorte = new Date(dataInput)

  // Obtendo a data e hora atuais
  const now = new Date()

  // Ajustando a hora para a hora local atual
  dataCorte.setHours(now.getHours(), now.getMinutes(), now.getSeconds())

  // Ajustando a data para refletir a hora local, mas mantendo como se fosse UTC
  const dataCorteParaSalvar = new Date(
    Date.UTC(
      dataCorte.getFullYear(),
      dataCorte.getMonth(),
      dataCorte.getDate(),
      dataCorte.getHours(),
      dataCorte.getMinutes(),
      dataCorte.getSeconds()
    )
  )

  // Verifica se o formato foi passado e é "dd/mm/yyyy"
  if (formato === 'dd/mm/yyyy') {
    const dia = String(dataCorteParaSalvar.getUTCDate()).padStart(2, '0')
    const mes = String(dataCorteParaSalvar.getUTCMonth() + 1).padStart(2, '0')
    const ano = dataCorteParaSalvar.getUTCFullYear()

    return `${dia}/${mes}/${ano}`
  }

  // Retorna a data ajustada, pronta para ser salva no MongoDB
  return dataCorteParaSalvar
}
