import { useState, useEffect, useRef, useContext } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
// import EmailTemplate from '/src/components/pages/Financeiro/financeiro-envio-por-email';
import { useQuery } from 'react-query'
import { Form, Table, Container, Card, Button } from 'react-bootstrap'
import { carregarClientesDoServico } from '../../../../src/servicos/dadosServicos'
import { DarBaixaNoServico, requisitar } from '../../../../src/backend/model'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'
import { formatDate, formatCurrentTime } from '../../../../src/utils/date-utils'
//import { verificarDadosPlotagem } from '/src/components/pages/CadastroPlotagem/EditarPlotagem'
import { CustomField } from '../../../../src/components/CustomField/custom-field'
import styled from 'styled-components'
import { ConfirmationModal } from '../../../../src/components/DraggableContainer/ConfirmationModal/confirmation-modal'
//import { ButtonWithAlerts } from '/src/components/ButtonWithAlerts/button-with-alerts';
import CustomTable from '../../CustomTable/custom-table'
import AlertMessage from '../../../../src/components/pages/CadastroPlotagem/AlertMessage'
// import html2canvas from 'html2canvas'
//const Html2Canvas = React.lazy(() => import('html2canvas'))
import { imprimirFinanceiro } from '../../../../src/components/pages/Financeiro/financeiro-impressao'
import {
  NomeSobrenomeEmpresaClientes,
  sumByKey,
  customStyles
  //handleSelectedClienteChange,
} from '../../../../src/utils/form-utils'
import { CustomSelectWithLoading } from '../../../../src/components/SelectWithLoading/SelectWithLoading'
import { SalvarEdicao, SelectFromTablePorMultiplosIds } from '../../../../src/backend/model'
import sheetsConfig from '../../../../src/config/sheetsConfig'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
//import { TouchBackend } from 'react-dnd-touch-backend';
import {
  DraggableContainer,
  DroppableArea,
  useArrastar
} from '../../../../src/components/DraggableContainer/draggable-container'
import { AuthContext } from '../../../../src/context/AuthContext'
import { config } from '../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../src/backend/model'
export default function Financeiro() {
  // const [formData, setFormData] = useState({
  //   nome: '',
  //   email: ''
  // })

  const { handleDrop, components } = useArrastar(3, 5) // o primeiro parametro é o id do formulario e o segundo o numero de componentes que tera na tela atual
  const [SelectedCliente, setSelectedCliente] = useState([])
  const [cortes, setCortes] = useState([])
  const [isLoadingCortes, set_isLoadingCortes] = useState(true)

  const [plotagems, setPlotagems] = useState([])
  const [isLoadingPlotagems, set_isLoadingPlotagems] = useState(true)

  const [modelagens, setModelagens] = useState([])
  const [isLoadingModelagens, set_isLoadingModelagens] = useState(true)

  const [showPagamentos, setShowPagamentos] = useState(false)
  const [pagamentosFeitos, setPagamentosFeitos] = useState(null)
  const containerRef = useRef(null)
  const [editableTotal, setEditableTotal] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)

  const [positionConfig, setPositionConfig] = useState({
    1: { x: -664, y: 73 },
    2: { x: 83, y: 22 },
    3: { x: 625, y: 23 },
    4: { x: -254, y: 496 },
    5: { x: 239, y: 343 }
  })

  const togglePagamentos = () => {
    setShowPagamentos(!showPagamentos)
  }
  let { cliente, clienteId } = useParams()

  cliente = tratarString(cliente)

  useEffect(() => {
    if (cliente) {
      // Supondo que 'cliente' é um objeto { label: nome, value: id }

      setSelectedCliente({ label: cliente, value: clienteId })
      carregarDadosDoCliente(
        clienteId,
        tenantId,
        setCortes,
        setPlotagems,
        setModelagens,
        setPagamentosFeitos,
        setShowPagamentos,
        setEditableTotal,
        set_isLoadingCortes,
        set_isLoadingPlotagems,
        set_isLoadingModelagens
      )
    }
  }, [cliente])

  //---------------------------------------------------------------------------
  const {
    data: clientesBrutos,
    isLoading: isLoadingClientes,
    isError: isErrorClientes
  } = useQuery('clientes', () => carregarClientesDoServico(1, tenantId, RotaBase), {
    staleTime: 5000
  })

  const clientes = NomeSobrenomeEmpresaClientes(clientesBrutos || [])

  //--------------------------------------------------------------------------------------------

  //--------------------------------------------------------------------------------------------
  const handleSelectedClienteChange = async selectedOption => {
    setEditableTotal('')
    setSelectedCliente(selectedOption)
    //console.log('selectedOption', selectedOption)
    setEditableTotal('')
    setSelectedCliente(selectedOption)
    await carregarDadosDoCliente(
      selectedOption ? selectedOption.value : null,
      tenantId,
      setCortes,
      setPlotagems,
      setModelagens,
      setPagamentosFeitos,
      setShowPagamentos,
      setEditableTotal,
      set_isLoadingCortes,
      set_isLoadingPlotagems,
      set_isLoadingModelagens
    )
  }

  //-----------------------------------------------------------

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}> */}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Banner
          fontSize={60}
          title="Gestão Financeira do Cliente"
          width="44%"
          classEfeito3dMouseOver=""
        />
      </div>

      <div ref={containerRef} className={`my-6 ${isVisible ? '' : 'not-rendered'}`}>
        <DroppableArea onDrop={handleDrop}>
          <Container style={{ width: '37%', margin: '0 auto' }}>
            <DraggableContainer id={1} components={components} positionConfig={positionConfig}>
              <ResumoFinanceiro
                SelectedCliente={SelectedCliente}
                clientes={clientes}
                isLoadingClientes={isLoadingClientes}
                customStyles={customStyles}
                setSelectedCliente={setSelectedCliente}
                handleSelectedClienteChange={handleSelectedClienteChange}
                togglePagamentos={togglePagamentos}
                cortes={cortes}
                plotagems={plotagems}
                modelagens={modelagens}
                setPagamentosFeitos={setPagamentosFeitos}
                pagamentosFeitos={pagamentosFeitos}
                isLoadingCortes={isLoadingCortes}
                isLoadingPlotagems={isLoadingPlotagems}
                setPlotagems={setPlotagems}
                setCortes={setCortes}
                setModelagens={setModelagens}
                containerRef={containerRef}
                clientesBrutos={clientesBrutos}
                setEditableTotal={setEditableTotal}
                editableTotal={editableTotal}
              />
            </DraggableContainer>
          </Container>
          {cortes && cortes.length > 0 && (
            <Container style={{ width: '35%', margin: '0 auto' }}>
              <DraggableContainer id={2} components={components} positionConfig={positionConfig}>
                <div style={{ width: '50%' }}>
                  <Banner fontSize={40} title="CORTE" />
                </div>
                <Card>
                  <Card.Body>
                    <Form>
                      <Table responsive className="table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: '2%' }}>ID</th>
                            <th style={{ width: '2%' }}>Data</th>
                            <th style={{ width: '20%' }}>Descrição</th>
                            <th style={{ width: '5%' }}>Tecido</th>
                            <th style={{ width: '5%' }}>Peças</th>
                            <th style={{ width: '2%' }}>Valor</th>
                            <th style={{ width: '2%' }}>Embalagem</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cortes.map((corte, index) => (
                            <tr key={index}>
                              <td style={{ fontSize: '12px' }}>{corte.id}</td>
                              <td>{formatDate(corte.data)}</td>
                              <td>{corte.descricao}</td>
                              <td>{corte.tecido}</td>
                              <td>{corte.total_pecas}</td>
                              <td>
                                {
                                  corte.a_receber
                                    ? corte.a_receber.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      })
                                    : 'R$ 0,00' // Valor padrão a ser exibido caso a_receber seja nulo ou indefinido
                                }
                              </td>

                              <td>{corte.embalagem}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form>
                  </Card.Body>
                </Card>
              </DraggableContainer>
            </Container>
          )}
          {modelagens && modelagens.length > 0 && (
            <Container style={{ width: '20%', margin: '0 auto' }}>
              <DraggableContainer id={5} components={components} positionConfig={positionConfig}>
                <div style={{ width: '50%' }}>
                  <Banner fontSize={40} title="MODELAGENS" />
                </div>
                <Card>
                  <Card.Body>
                    <Table responsive className="table table-striped">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Modelagem</th>
                          <th>Unidades</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modelagens
                          .filter(modelagem => modelagem.itens.length > 0)
                          .flatMap(modelagem =>
                            modelagem.itens.map((item, itemIndex) => (
                              <tr key={`${modelagem._id}-${itemIndex}`}>
                                <td>{formatDate(modelagem.data)}</td>
                                <td>
                                  {/* Você precisa implementar uma maneira de exibir o nome ou ID da modelagem. 
          Supondo que `id_modelagem` seja o identificador, você pode usar `item.id_modelagem`. 
          Se você tiver um método para buscar o nome da modelagem usando este ID, seria ideal. */}
                                  {item.id_modelagem}
                                </td>
                                <td>{item.unidades}</td>
                                <td>
                                  {item.valor.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })}
                                </td>
                              </tr>
                            ))
                          )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </DraggableContainer>
            </Container>
          )}

          {plotagems && plotagems.length > 0 && (
            <Container style={{ width: '20%', margin: '0 auto' }}>
              <DraggableContainer id={3} components={components} positionConfig={positionConfig}>
                <div style={{ width: '50%' }}>
                  <Banner fontSize={40} title="PLOTAGEM" />
                </div>
                <Card>
                  <Card.Body>
                    <Form>
                      <Table responsive className="table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: '2%' }}>ID</th>
                            <th style={{ width: '2%' }}>Data</th>
                            <th style={{ width: '5%' }}>Metros</th>
                            <th style={{ width: '5%' }}>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {plotagems.map((plotagem, index) => (
                            <tr key={index}>
                              <td style={{ fontSize: '12px' }}>{plotagem.id}</td>
                              <td>{formatDate(plotagem.data)}</td>
                              <td>{plotagem.metros.toFixed(2)}</td>
                              <td>
                                {plotagem.apagar
                                  ? plotagem.apagar.toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : 'R$ 0,00'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form>
                  </Card.Body>
                </Card>
              </DraggableContainer>
            </Container>
          )}
          {showPagamentos && (
            <PagamentosFeitosContainer
              pagamentos={pagamentosFeitos}
              components={components}
              positionConfig={positionConfig}
            />
          )}
        </DroppableArea>
      </div>
    </DndProvider>
  )
}
//----------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------
function ResumoFinanceiro({
  clientes,
  SelectedCliente,
  isLoadingClientes,
  customStyles,
  setSelectedCliente,
  handleSelectedClienteChange,
  togglePagamentos,
  cortes,
  plotagems,
  modelagens,
  setPagamentosFeitos,
  pagamentosFeitos,
  isLoadingCortes,
  isLoadingPlotagems,
  isLoadingModelagens,
  setPlotagems,
  setCortes,
  setModelagens,
  containerRef,
  clientesBrutos,
  setEditableTotal,
  editableTotal
}) {
  const [editableTotal2, setEditableTotal2] = useState(0)
  const [showCustomField, setShowCustomField] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [showAlertGravando, setShowAlertGravando] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showButtonWithAlerts, setShowButtonWithAlerts] = useState(false)

  const [isLoadingPagamentos, setIsLoadingPagamentos] = useState(false)
  const [isErrorPagamentos, setIsErrorPagamentos] = useState(false)
  const [valorDevedorFinalizado, set_valorDevedorFinalizado] = useState(false)
  const [faltaPagar, set_faltaPagar] = useState(0)
  const [totalPagamentos, set_totalPagamentos] = useState(0)
  const [totalPago, set_totalPago] = useState(0)
  const [totalAPagar, setTotalAPagar] = useState(0) // referente a plotagem
  const [totalAReceber, setTotalAReceber] = useState(0) // referente a corte
  const [totalAReceberModelagem, setTotalAReceberModelagem] = useState(0) // referente a modelagem
  const [totalEmbalagem, setTotalEmbalagem] = useState(0)
  const [totaisAreceber, setTotaisAreceber] = useState([])
  const [confirmacaoEmail, set_confirmacaoEmail] = useState(false)
  const [mensagemProcesso, set_mensagemProcesso] = useState()
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [valorEmbalagemCorte, setValorEmbalagemCorte] = useState(0)

  useEffect(() => {
    const fetchValorEmbalagemCorte = async () => {
      try {
        const dados = {
          tenantId: tenantId,
          rota_base: RotaBase,
          requisicao: 'getValorEmbalagemCorte'
        }
        const url = config.host + '/configuracoesGerais'
        const response = await fetchData(url, dados)

        if (response.success && response.valorEmbalagemCorte !== undefined) {
          setValorEmbalagemCorte(response.valorEmbalagemCorte)
        } else {
          // setAlertVariant('danger')
          // setAlertMessage('Erro ao buscar o valor da embalagem do corte')
          // setShowAlert(true)
        }
      } catch (error) {
        console.error('Erro ao buscar o valor da embalagem do corte:', error)
        // setAlertVariant('danger')
        // setAlertMessage('Erro ao buscar o valor da embalagem do corte')
        // setShowAlert(true)
      }
    }

    fetchValorEmbalagemCorte()
  }, [RotaBase, tenantId])

  useEffect(() => {
    let totais = {}

    let localvalorPlotagem = 0
    if (plotagems) localvalorPlotagem = sumByKey(plotagems, 'valor')

    let localTotalAReceber = 0,
      localTotalEmbalagem = 0,
      localTotalAReceberModelagem = 0

    if (cortes) {
      localTotalAReceber = sumByKey(cortes, 'a_receber')
      localTotalEmbalagem = sumByKey(cortes, 'embalagem') * valorEmbalagemCorte
    }
    if (modelagens) {
      localTotalAReceberModelagem = sumByKey(modelagens, 'valor', 'itens') // itens siginifica que o valor sera somado dentro do array itens
    }
    setTotalAReceber(localTotalAReceber)
    setTotalEmbalagem(localTotalEmbalagem)
    setTotalAPagar(localvalorPlotagem)
    setTotalAReceberModelagem(localTotalAReceberModelagem)
    totais = calcularTotais(
      localTotalAReceber,
      localTotalEmbalagem,
      localvalorPlotagem,
      localTotalAReceberModelagem
    )

    setTotaisAreceber(totais)
  }, [cortes, plotagems, modelagens])
  //-----------------------------------------------------------------------------

  useEffect(() => {
    // Função assíncrona dentro do useEffect
    const carregarDados = async () => {
      if (SelectedCliente && 'value' in SelectedCliente && SelectedCliente.value) {
        setIsLoadingPagamentos(true)
        try {
          const data = await CarregarPagamentosFeitos(SelectedCliente.value, '')

          if (data !== undefined) {
            setPagamentosFeitos(data)
          } else {
            setPagamentosFeitos(0)
          }
          setIsLoadingPagamentos(false)
        } catch (error) {
          console.error(error)
          setIsErrorPagamentos(true)
          setIsLoadingPagamentos(false)
        }
      } else {
        // Lógica para quando SelectedCliente é null ou undefined
        setPagamentosFeitos(null) // Ou algum valor inicial
        setIsLoadingPagamentos(false)
        setIsErrorPagamentos(false) // Ou algum valor inicial
      }
    }

    carregarDados() // Chame a função assíncrona
  }, [SelectedCliente])

  // Reexecuta o useEffect sempre que SelectedCliente.value mudar

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false)
      setShowConfirmationModal(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [showAlert])

  //-----------------------------------------------------
  useEffect(() => {
    if (pagamentosFeitos || SelectedCliente) {
      let totalPagmentosFeitos = totaisPagamentos(pagamentosFeitos).totalRegistros

      set_totalPagamentos(totalPagmentosFeitos)

      set_totalPago(totaisPagamentos(pagamentosFeitos).valorTotaldePagamentos)
    }
    if (editableTotal == faltaPagar) {
      set_valorDevedorFinalizado(true)
    } else {
      set_valorDevedorFinalizado(false)
    }
  }, [pagamentosFeitos])

  useEffect(() => {
    let fp = totaisPagamentos(pagamentosFeitos, totaisAreceber.total).valorFaltandoPagar

    set_faltaPagar(fp)
  }, [totaisAreceber.total, pagamentosFeitos, editableTotal])
  //----------------------------------------------------------------
  function getEmailById(clientes, id) {
    for (let i = 0; i < clientes?.length; i++) {
      if (clientes[i].id === id) {
        return clientes[i].email
      }
    }
    return null
  }
  function getWhastsAppById(clientes, id) {
    for (let i = 0; i < clientes.length; i++) {
      if (clientes[i].id === id) {
        return clientes[i].telefone
      }
    }
    return null
  }

  //----------------------------------------------------------------------------

  //-----------------------------------------------------------------------

  //---------------------------------------------------------------------------
  const handleDarBaixaClick = () => {
    if (editableTotal == '' || editableTotal == null) {
      if (faltaPagar > 0) {
        setEditableTotal(faltaPagar)
      } else setEditableTotal(totaisAreceber.total) // copia o total apenas se editableTotal estiver vazio ou nulo
    }
    setShowCustomField(true)
    if (!isExpanded) {
      setIsExpanded(true)
    }

    // Se isExpanded for verdadeiro e editableTotal for maior que zero, mostre o ConfirmationModal
    if (isExpanded && editableTotal > 0) {
      setShowConfirmationModal(true)
    }

    isSalvar.current = isExpanded && editableTotal > 0 ? true : false
  }

  const handleCloseClick = () => {
    setIsExpanded(false)
    setShowCustomField(false)
  }
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  //-----------------------------------------------------------------------------------
  function criarDados(options) {
    const {
      collection, // Nome da aba na planilha que você quer manipular
      sector, // setor
      id_1, // Chave para identificar o início do intervalo de IDs que você quer atualizar ( id do cliente aqui no caso)
      valor_id_1,
      compareType_value_id_1,
      id_2, // Chave para identificar o fim do intervalo de IDs que você quer atualizar (coluna pago no caso)
      valor_id_2,
      compareType_value_id_2,
      valorAdicional, // no caso da aba pagamentos quando quer colocar um ok na coluna status coloca nesse parametro
      posicaoAdicional, // por exemplo no caso da planilha de pagamentos aqui fica sendo a coluna onde o ok sera gravado
      posicaoDado = null, // Valor que você quer usar para atualizar a coluna especificada por posicaoAdicional (opcional)
      sheetDestino,
      requisicao = 'atualizarColunaPorId_v2',
      rota = 'financeiro'
    } = options

    const dadosExtra = {
      sheet_dos_dados: collection,

      idsObj: {
        [id_1]: { value: valor_id_1, compareType: compareType_value_id_1 },
        [id_2]: { value: valor_id_2, compareType: compareType_value_id_2 }
      },
      isFrontendCall: false, // isFrontendCall é para indicar que a funcao esta sendo chamado pelo lado client, porque ela tambem é chamada pelo lado servidor, e quando é pelo lado servidor o tipo do return dela é diferente
      selectedSector: sector,
      funcaoDeRetorno: RetornoPagamentoFeitos
    }

    const dados = {
      dadosExtra: dadosExtra,

      requisicao: requisicao,
      rota: rota,
      valorAdicional: valorAdicional,
      posicaoAdicional: posicaoAdicional,
      posicaoDado: posicaoDado,
      sheetDestino: sheetDestino
    }

    return dados
  }
  //----------------------------------------------------------------------------------------

  async function DarBaixaGeral() {
    let dados, options

    set_mensagemProcesso('Dando baixa nas plotagens')
    dados = {
      collectionPai: sheetsConfig.plotagem.tabs.servicos,
      collectionFilha: sheetsConfig.plotagem.tabs.itens_plotagens,
      requisicao: 'DarBaixaNasPlotagens',
      rota: 'financeiro',
      criteriosDeAtualizacao: {
        criteriosCollectionPai: {
          id_cliente: SelectedCliente.value,
          apagar: { $gt: 0 },
          tenantId: tenantId
        },
        criteriosCollectionFilha: {
          id_plotagem: '_idPai',
          tenantId: tenantId
        }
      },

      CollectionPaiDestino: sheetsConfig.plotagem.tabs.servicos_hist,
      CollectionFilhaDestino: sheetsConfig.plotagem.tabs.itens_plotagens_hist
    }
    await DarBaixaNoServico(dados) // atualizo aqui a planilha de valores das plotagens pendentes
    set_mensagemProcesso('Dando baixa nas modelagens')
    dados = {
      collectionPai: sheetsConfig.modelagem.tabs.cad_serv_modelagem,
      collectionFilha: sheetsConfig.modelagem.tabs.itens_cad_serv_modelagem,
      requisicao: 'DarBaixaNasModelagens',
      rota: 'financeiro',
      criteriosDeAtualizacao: {
        criteriosCollectionPai: {
          id_cliente: SelectedCliente.value,
          entregue: { $ne: null },
          tenantId: tenantId
        },
        criteriosCollectionFilha: {
          id_serv_mod: '_idPai',
          tenantId: tenantId
        }
      },

      CollectionPaiDestino: sheetsConfig.modelagem.tabs.cad_serv_modelagem_hist,
      CollectionFilhaDestino: sheetsConfig.modelagem.tabs.itens_cad_serv_modelagem_hist
    }
    await DarBaixaNoServico(dados) // atualizo aqui a planilha de valores das modelagens pendentes

    set_mensagemProcesso('Dando baixa nos cortes')
    dados = {
      collectionOrigem: sheetsConfig.cortes.tabs.servicos,
      collectionDestino: sheetsConfig.cortes.tabs.servicos_hist,
      requisicao: 'DarBaixaNosCortes',
      rota: 'financeiro',
      criteriosDeAtualizacao: {
        criteriosDeBusca: {
          id_cliente: SelectedCliente.value,
          a_receber: { $gt: 0 },
          tenantId: tenantId
        }
      }
    }

    await DarBaixaNoServico(dados) // atualizo aqui a planilha de valores dos cortes pendentes

    // COLOCANDO OK NA COLUNA STATUS DA TABELA PAGAMENTOS
    set_mensagemProcesso('Dando baixa no(s) pagamento(s)')
    dados = {
      collection: 'pagamentos',
      requisicao: 'ColocarOkNosPagamentos',
      rota: 'financeiro',
      criteriosDeAtualizacao: {
        criteriosDeBusca: {
          idcliente: SelectedCliente.value,
          status: '',
          tenantId: tenantId,
          rota_base: RotaBase
        },
        atualizacao: {
          $set: { status: 'ok' }
        }
      }
    }

    await DarBaixaNoServico(dados) // atualizo aqui a collection de pagamentos feitos
  }

  async function gravarDados() {
    isSalvar.current = isExpanded && editableTotal > 0 ? true : false
    setShowAlertGravando(true)
    const dados = {
      data: new Date(),
      hora: formatCurrentTime(),
      idcliente: SelectedCliente.value,
      valor: editableTotal,
      status: '',
      rota_base: RotaBase,
      tenantId: tenantId
    }

    try {
      set_mensagemProcesso('Gravando pagamento')
      await SalvarEdicao(
        {
          configKeySheet: 'pagamentos',
          tab: 'pagamentos',
          cadastroDados: dados,
          isEditing: false,
          requisicao: 'enviar'
        },
        {
          id_pk: 0,
          rota: 'funcoesGenericas'
        }
      )

      // Exibir mensagem de sucesso

      // limparCampos();
    } catch (error) {
      // Tratar erro
      console.error(error)
    }

    if (editableTotal >= faltaPagar) {
      valorDevedorFinalizadoRef.current = true
    } else {
      valorDevedorFinalizadoRef.current = false
    }

    if (valorDevedorFinalizadoRef.current) {
      await DarBaixaGeral()
      setPlotagems(null)
      setCortes(null)
      setModelagens(null)
      setPagamentosFeitos(null)
    }
    setShowAlertGravando(false)
    setShowAlert(true)
  }
  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  //-------------------------------------------------------------------
  // useEffect(() => {
  //   setEditableTotal(editableTotal2);
  // }, [editableTotal2]);

  const valorDevedorFinalizadoRef = useRef(false)
  //const editableTotalRef = useRef(0)
  const handleEditableTotalChange = e => {
    const inputValue = e.target.value // Pega o valor digitado como string

    if (!isNaN(inputValue)) {
      if (inputValue > faltaPagar) {
        // Se for maior que faltaPagar, definimos para faltaPagar, mas não limitamos a entrada contínua.

        setEditableTotal(faltaPagar)

        valorDevedorFinalizadoRef.current = true
      } else if (inputValue <= faltaPagar) {
        // Se for exatamente igual, aceitamos o valor e definimos o ref para true.
        setEditableTotal(inputValue) // mantém o valor que o usuário digitou

        valorDevedorFinalizadoRef.current = false
      }
    }
    isSalvar.current = isExpanded && editableTotal > 0 ? true : false
  }

  // useEffect(() => {
  //   set_valorDevedorFinalizado(valorDevedorFinalizadoRef.current);
  // }, [valorDevedorFinalizadoRef.current]);
  const isSalvar = useRef(false)

  isSalvar.current = isExpanded && editableTotal > 0

  const navigate = useNavigate()
  async function enviarEmail() {
    setShowAlertGravando(true)
    await html2canvas(containerRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png')

      const to = getEmailById(clientesBrutos, SelectedCliente.value)
      const subject = 'Relatorio Financeiro de ' + SelectedCliente.label
      const html = `
        <h1>Olá, ${SelectedCliente.label}</h1>
        <p>Aqui está o seu relatório financeiro.</p>
      `
      sendEmail(to, subject, html, imgData)
    })
    setTimeout(() => {
      setShowAlertGravando(false)
      set_confirmacaoEmail(false)
    }, 3000)
  }

  const handleSendEmailClick = () => {
    set_confirmacaoEmail(true)
  }
  //---------------------------------------------------------------------
  const whatsappLinkRef = useRef(null)
  const handleSendWhatsAppClick = () => {
    const phoneNumber = getWhastsAppById(clientesBrutos, SelectedCliente.value)

    const message =
      SelectedCliente.label +
      ' Relatorio financeiro:\n\n' +
      'Cortes:' +
      totaisAreceber.cortes.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\nEmbalagem: ' +
      totaisAreceber.embalagens.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\nPlotagens: ' +
      totaisAreceber.riscos.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\nTotal: ' +
      totaisAreceber.total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\nTotal Pago: ' +
      totalPago.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\nFalta Pagar: ' +
      faltaPagar.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) +
      '\n\nFoi enviado um detalhado dos cortes e plotagens para o seu email \n\nMensagem enviada pelo sistema'

    const url =
      'https://api.whatsapp.com/send?phone=' + phoneNumber + '&text=' + encodeURIComponent(message)

    const whatsappLink = whatsappLinkRef.current
    whatsappLink.href = url
    whatsappLink.click()
  }
  //--------------------------------------------------------------------------
  function handlePrintClick() {
    imprimirFinanceiro(
      SelectedCliente.label,
      totaisAreceber,
      totalPago,
      faltaPagar,
      cortes,
      plotagems
    )
  }

  return (
    <ResumoContainer className={totaisAreceber.total > 0 ? 'expanded' : ''}>
      <a
        ref={whatsappLinkRef}
        id="whatsapp-link"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'none' }}
      >
        Enviar mensagem
      </a>
      <TopAlignedContainer>
        <CustomSelectWithLoading
          id="cliente"
          options={clientes}
          isClearable={true}
          placeholder="Selecione o cliente"
          onChange={handleSelectedClienteChange}
          value={SelectedCliente}
          isLoading={isLoadingClientes}
          showLoading={true}
          width="650px"
          styles={customStyles}
        />
      </TopAlignedContainer>
      <LeftAlignedContainer>
        {totaisAreceber.total > 0 && (
          <>
            {totaisAreceber && totaisAreceber.cortes > 0 && (
              <TotalLine>
                CORTES:
                <ValorPagamentos>
                  {' '}
                  {totaisAreceber.cortes.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </ValorPagamentos>
              </TotalLine>
            )}
            {totaisAreceber && totaisAreceber.modelagens > 0 && (
              <TotalLine>
                MODELAGENS:
                <ValorPagamentos>
                  {' '}
                  {totaisAreceber.modelagens.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </ValorPagamentos>
              </TotalLine>
            )}
            {totaisAreceber && totaisAreceber.embalagens > 0 && (
              <TotalLine>
                EMBALAGENS:{' '}
                <ValorPagamentos>
                  {totaisAreceber.embalagens.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </ValorPagamentos>
              </TotalLine>
            )}
            {totaisAreceber && totaisAreceber.riscos > 0 && (
              <TotalLine>
                PLOTAGENS:{' '}
                <ValorPagamentos>
                  {totaisAreceber.riscos.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </ValorPagamentos>
              </TotalLine>
            )}

            <TotalLine>
              TOTAL:{' '}
              <ValorPagamentos>
                {totaisAreceber.total.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </ValorPagamentos>
            </TotalLine>

            <ConfirmationModal
              show={confirmacaoEmail}
              botaoComAlerta={true}
              buttonWithAlertsProps={{
                showAlertGravando: showAlertGravando,
                showAlert: showAlert,
                buttonLabel: 'Sim',
                width: '70',
                handleSubmit: enviarEmail,
                message: 'Enviando Email'
              }}
              onHide={() => {
                set_confirmacaoEmail(false)
                setShowButtonWithAlerts(false) // resetar para o padrão após fechar o modal
              }}
              title="Confirmação"
              message={
                <div>
                  Enviar Email para{' '}
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '1.2em'
                    }}
                  >
                    {SelectedCliente ? getEmailById(clientesBrutos, SelectedCliente.value) : null}
                  </span>
                </div>
              }
              confirmLabel="Sim"
              cancelLabel="Não"
            ></ConfirmationModal>

            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                variant="primary"
                style={{ width: '170px', backgroundColor: '#2e2e2e' }}
                onClick={handleSendEmailClick}
              >
                Email <i className="fa fa-envelope"></i>
              </Button>
              <Button
                variant="primary"
                style={{
                  width: '170px',
                  backgroundColor: '#ffffff',
                  color: '#2e2e2e'
                }}
                onClick={handleSendWhatsAppClick}
              >
                Whats <i className="fa fa-whatsapp"></i>
              </Button>
              <Button
                variant="primary"
                style={{
                  width: '170px',
                  backgroundColor: '#ffffff',
                  color: '#2e2e2e'
                }}
                onClick={handlePrintClick}
              >
                Imprimir <i className="fa fa-print"></i>
              </Button>
            </div>
          </>
        )}
      </LeftAlignedContainer>
      {totaisAreceber.total > 0 && (
        <CenteredContainer>
          {(isLoadingPagamentos ||
            isLoadingCortes ||
            isLoadingPlotagems ||
            isLoadingModelagens) && (
            <AlertMessage
              message="Carregando"
              size="alert-small"
              customHeight={150}
              customWidth={180}
              top={'90px'}
              left={'270px'}
              variant1="warning"
              variant2="light"
              isBlinking={true}
              position={'absolute'}
            />
          )}

          <ChildResumoContainer $isExpanded={isExpanded}>
            <Button
              variant={isExpanded && editableTotal > 0 ? 'success' : 'primary'}
              style={{ marginBottom: '40px', width: '100px' }}
              onClick={handleDarBaixaClick}
            >
              {isSalvar.current ? 'Salvar' : 'Dar Baixa'}
            </Button>

            {showCustomField && (
              <CustomField
                label="Valor"
                placeholder="Valor"
                width="130px"
                value={editableTotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}
                onChange={handleEditableTotalChange}
                onClear={() => setEditableTotal('')}
              />
            )}
            {isExpanded && <CloseButton onClick={handleCloseClick}>x</CloseButton>}
          </ChildResumoContainer>

          <ConfirmationModal
            show={showConfirmationModal}
            botaoComAlerta={true}
            buttonWithAlertsProps={{
              showAlertGravando: showAlertGravando,
              showAlert: showAlert,
              buttonLabel: 'Sim',
              width: '70',
              handleSubmit: gravarDados,
              message: mensagemProcesso,
              size: 'small'
            }}
            onHide={() => {
              setShowConfirmationModal(false)
              setShowButtonWithAlerts(false) // resetar para o padrão após fechar o modal
            }}
            title="Confirmação"
            message="Tem certeza de que deseja prosseguir?"
            confirmLabel="Sim"
            cancelLabel="Não"
          ></ConfirmationModal>
        </CenteredContainer>
      )}
      <RightAlignedContainer>
        {totaisAreceber && totaisAreceber.total > 0 && (
          <>
            {totalPagamentos > 0 && (
              <>
                <PagamentosLine>
                  <Button
                    variant="light"
                    onClick={togglePagamentos}
                    style={{ backgroundColor: 'white', color: '#a9a9a9' }}
                  >
                    PAGAMENTOS FEITOS:
                  </Button>
                  <ValorPagamentos>{totalPagamentos || ''}</ValorPagamentos>
                </PagamentosLine>
                <PagamentosLine>
                  {' '}
                  TOTAL PAGO:{' '}
                  <ValorPagamentos>
                    {totalPago.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }) || ''}
                  </ValorPagamentos>
                </PagamentosLine>
              </>
            )}
            <PagamentosLine>
              {' '}
              FALTA PAGAR:{' '}
              <ValorPagamentos>
                {' '}
                {faltaPagar.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }) || ''}
              </ValorPagamentos>
            </PagamentosLine>
          </>
        )}
      </RightAlignedContainer>
    </ResumoContainer>
  )
}

//-------------------------------------------------------------------------------------
export const CarregarPagamentosFeitos = async (idcliente, status) => {
  const dadosExtra = {
    sheet_dos_dados: 'pagamentos',

    criteriosDeBusca: {
      idcliente: idcliente,
      $or: [{ status: 0 }, { status: { $exists: false } }, { status: null }, { status: '' }]
    },
    isFrontendCall: true, // isFrontendCall é para indicar que a funcao esta sendo chamado pelo lado client, porque ela tambem é chamada pelo lado servidor, e quando é pelo lado servidor o tipo do return dela é diferente
    selectedSector: 'pagamentos',
    funcaoDeRetorno: RetornoPagamentoFeitos,

    rota: 'funcoesGenericas'
  }

  const resultado = await SelectFromTablePorMultiplosIds({ dadosExtra })

  return resultado
}
//---------------------------------------------------------------------------
export function RetornoPagamentoFeitos(dadosretorno) {
  const PagamentosData = dadosretorno

  if (!PagamentosData) {
    return []
  }

  const pagamentos = PagamentosData.map(pagamento => {
    return {
      id: pagamento.id,
      data: pagamento.data,
      hora: pagamento.hora,
      idcliente: pagamento.idcliente,
      valor: pagamento.valor,
      status: pagamento.status
    }
  })

  return pagamentos
}
//-------------------------------------------------------------------------------------------------
function totaisPagamentos(pagamentosFeitos, valorTotal = 0) {
  let valorTotaldePagamentos = 0,
    valorFaltandoPagar = 0,
    totalRegistros = 0

  if (pagamentosFeitos) {
    totalRegistros = pagamentosFeitos.length
    valorTotaldePagamentos = pagamentosFeitos.reduce((total, pagamento) => {
      return total + pagamento.valor
    }, 0)
  }
  valorFaltandoPagar = valorTotal - valorTotaldePagamentos

  return {
    totalRegistros: totalRegistros,
    valorTotaldePagamentos: valorTotaldePagamentos,
    valorFaltandoPagar: valorFaltandoPagar
  }
}
//--------------------------------------------------------------------------------------------

const PagamentosFeitosContainer = ({ pagamentos, components, positionConfig }) => {
  const [updatedData, setUpdatedData] = useState(pagamentos)
  const [dadosEdicao, setDadosEdicao] = useState({})
  const headerCells = [
    { label: 'ID', dataKey: 'id', width: '5%', edit: false },
    {
      label: 'Data',
      dataKey: 'data',
      width: '20%',
      fetchFunction: formatDate,
      isFormatDate: true,
      edit: false
    },
    { label: 'Hora', dataKey: 'hora', width: '17%', edit: false },
    { label: 'Valor', dataKey: 'valor', width: '20%', isFormatMoeda: true, edit: true }
  ]

  useEffect(() => {
    setUpdatedData(pagamentos)
  }, [pagamentos])

  return (
    <Container style={{ width: '25%', margin: '0 auto', marginTop: '20px' }}>
      <DraggableContainer id={4} components={components} positionConfig={positionConfig}>
        <div style={{ width: '100%' }}>
          <Banner fontSize={27} className="bannerGren" title="PAGAMENTOS FEITOS" />
        </div>
        <Card>
          <Card.Body>
            <CustomTable
              headerCells={headerCells}
              data={pagamentos}
              updatedData={updatedData}
              setUpdatedData={setUpdatedData}
              dadosEdicao={dadosEdicao}
              setDadosEdicao={setDadosEdicao}
              isDeleteEdit={{
                configKeySheet: 'pagamentos',
                exclusaoConfig: {
                  tab: 'pagamentos',
                  invalidateQueries: 'pagamentos'
                },
                deletaDoBanco: true
              }}
              width="100%"
              tableHeight="400px"
              showBorder={false}
            />
          </Card.Body>
        </Card>
      </DraggableContainer>
    </Container>
  )
}

//------------------------------------------------------------------------------------
const ResumoContainer = styled.div`
  display: flex;
  flex-direction: column; // Muda o layout para linha
  align-items: flex-start; // Centraliza verticalmente

  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s;
  width: 672px; // Ajuste conforme a necessidade
  background-color: #f9f9f9;

  &.expanded {
    min-height: 320px; // Ajuste conforme a necessidade
  }
`

const LeftAlignedContainer = styled.div`
  width: 250px;
  margin-top: 10px;
`

const TopAlignedContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 250px;
  margin-top: 0px;
  // position: absolute;
`

const RightAlignedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-top: -136px;
`
const ChildResumoContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s;
  height: ${props => (props.$isExpanded ? '137px' : '57px')}; // Altura padrão e altura expandida
  transform: translateY(40px);
  background-color: #f9f9f9;
  margin-top: -225px;
  margin-left: 260px;
`

const TotalLine = styled.p`
  font-weight: bold;
  margin: 5px 0;
  color: #a9a9a9;
`
const PagamentosLine = styled.p`
  font-weight: bold;
  margin: 5px 0;
  color: #a9a9a9;
`

const ValorPagamentos = styled.span`
  color: black; /* Ajuste para a cor desejada */
`

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px; // fornece um espaço entre os valores e o ResumoContainer filho
`

const CloseButton = styled.button`
  position: absolute;
  top: -6px;
  right: -3px;
  background-color: transparent;
  border: none;

  font-size: 18px;
  cursor: pointer;
  outline: none;

  &:hover {
    color: darkred;
  }
`

export async function sendEmail(to, subject, html, imgData) {
  const dados = {
    to: to,
    subject: subject,
    html: html,
    imgData: imgData,
    requisicao: 'sendEmail'
  }

  try {
    const response = await fetch(linkGoogleScript, {
      method: 'post',

      body: JSON.stringify(dados)
    })

    if (!response.ok) {
      throw new Error('Houve um problema ao enviar o email: ' + response.status)
    }
  } catch (error) {
    console.error('Erro ao enviar email:', error)
  }
}

//--------------------------------------------------------------------------------------------
export async function PlotagensPendentes(
  id_cliente,
  tenantId,
  setPlotagems,
  set_isLoadingPlotagems
) {
  set_isLoadingPlotagems(true)

  let criteriosDeBusca = {
    tenantId: tenantId,
    $or: [{ pago: 0 }, { pago: { $exists: false } }, { pago: null }, { pago: '' }]
  }

  // Incluir o filtro por id_cliente apenas se for fornecido
  if (id_cliente) {
    criteriosDeBusca.id_cliente = id_cliente
  }

  const plotagemsretorno = await requisitar({
    pagina1: sheetsConfig.plotagem.tabs.servicos,
    query: criteriosDeBusca
  })

  setPlotagems(plotagemsretorno)
  set_isLoadingPlotagems(false)
}
//--------------------------------------------------------------------------------------------
export async function ModelagensPendentes(
  id_cliente,
  tenantId,
  setModelagens,
  set_isLoadingModelagens
) {
  set_isLoadingModelagens(true)
  const dadosExtra = {
    sheet_dos_dados: sheetsConfig.modelagem.tabs.cad_serv_modelagem,

    criteriosDeBusca: {
      entregue: {
        $exists: true, // O campo 'entregue' deve existir
        $ne: null, // O campo 'entregue' não deve ser nulo
        $nin: ['', 0] // O campo 'entregue' não deve estar na lista de valores proibidos (string vazia ou 0)
      },
      tenantId: tenantId
    },

    funcaoDeRetorno: MapearDadosModelagem,
    isFrontendCall: true, // isFrontendCall é para indicar que a funcao esta sendo chamado pelo lado client, porque ela tambem é chamada pelo lado servidor, e quando é pelo lado servidor o tipo do return dela é diferente
    selectedSector: 'modelagem',

    rota: 'funcoesGenericas',
    collectionFilha1: 'cadastro_preco_molde'
  }

  if (id_cliente) {
    dadosExtra.criteriosDeBusca.id_cliente = id_cliente
  }

  const modelagemsretorno = await SelectFromTablePorMultiplosIds({
    dadosExtra
  })

  setModelagens(modelagemsretorno)
  set_isLoadingModelagens(false)
}
//--------------------------------------------------------------------------------------------
function MapearDadosModelagem(dados) {
  const ModelagensData = dados

  const modelagems = ModelagensData.map(modelagem => {
    return {
      id: modelagem.id,
      data: modelagem.data,
      modelagem: modelagem.modelagem,
      unidades: modelagem.unidades,
      valor: modelagem.valor
    }
  })

  return modelagems
}
//--------------------------------------------------------------------------------------------
export async function CortesPendentes(id_cliente, tenantId, setCortes, set_isLoadingCortes) {
  set_isLoadingCortes(true)

  const criteriosDeBusca = {
    a_receber: { $gt: 0 },
    tenantId: tenantId
  }

  if (id_cliente) {
    criteriosDeBusca.id_cliente = id_cliente
  }

  const cortesretorno = await requisitar({
    pagina1: 'Corte_Geral',
    query: criteriosDeBusca
  })
  //console.log('cortesretorno', cortesretorno)
  setCortes(cortesretorno)
  set_isLoadingCortes(false)
}
//--------------------------------------------------------------------------------------------
export function tratarString(entrada) {
  if (!entrada) {
    return ''
  }

  let resultado = entrada.replace(/\|/g, '-')
  resultado = resultado.replace(/Empresa:/g, '')

  // Remove espaços extras
  resultado = resultado.replace(/\s+/g, ' ').trim()

  // Verifica se a string termina com espaço seguido de '-', e remove
  resultado = resultado.replace(/\s-\s*$/, '')

  // Verifica se a string termina com espaço e remove
  resultado = resultado.replace(/\s+$/, '')

  return resultado
}
//--------------------------------------------------------------------------------------------
export const calcularTotais = (
  totalAReceber,
  totalEmbalagem,
  totalAPagar,
  localTotalAReceberModelagem
) => {
  const totalGeral = calcularTotalGeral(
    totalAReceber, // corte
    totalEmbalagem, //embalagem
    totalAPagar, //risco
    localTotalAReceberModelagem // modelagem
  )

  return {
    cortes: totalAReceber,
    modelagens: localTotalAReceberModelagem,
    embalagens: totalEmbalagem,
    riscos: totalAPagar,
    total: totalGeral
  }
}
//--------------------------------------------------------------------------------------------
function calcularTotalGeral(cortes, embalagens, riscos, modelagens) {
  return cortes + modelagens + embalagens + riscos
}
//--------------------------------------------------------------------------------------------
export const carregarDadosDoCliente = async (
  clienteId,
  tenantId,
  setCortes,
  setPlotagems,
  setModelagens,
  setPagamentosFeitos,
  setShowPagamentos,
  setEditableTotal,
  set_isLoadingCortes,
  set_isLoadingPlotagems,
  set_isLoadingModelagens
) => {
  if (clienteId) {
    await CortesPendentes(clienteId, tenantId, setCortes, set_isLoadingCortes)
    await PlotagensPendentes(clienteId, tenantId, setPlotagems, set_isLoadingPlotagems)
    await ModelagensPendentes(clienteId, tenantId, setModelagens, set_isLoadingModelagens)
  } else {
    setCortes([])
    setPlotagems([])
    setModelagens([])
    setPagamentosFeitos([])
    setShowPagamentos(false)
    setEditableTotal('')
  }
}
