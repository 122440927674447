//app.js é o arquivo principal do projeto, ele é o primeiro arquivo a ser executado quando o projeto é iniciado
import { useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppRoutes from '../routes/routeModelagemCompleta'

//import ChatWindow from './ChatWindow/ChatWindow'
import { useState, useContext } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

import { AuthContext } from '../context/AuthContext'
import { useInactivityLogout } from './useInactivityLogout/useInactivityLogout'
//import { fetchData } from '../backend/model'

//import { config } from '../backend/configConexaoGeral'
//import { PrivateRoute } from '../routes/routeModelagemCompleta'

import { isSystemOwner, deslogar } from '../utils/AppMenuFunctions'
const queryClient = new QueryClient()
const RESTRICTED_MENUS = ['CADASTRAR MARKETPLACE', 'GERENCIAR MARKETPLACE', 'Regras']

// const PrivateMenuItem = ({ children, tela }) => {
//   PrivateMenuItem.propTypes = {
//     children: PropTypes.node,
//     tela: PropTypes.string.isRequired
//   }

//   const {
//     isAuthenticated,
//     user,
//     idNivelAcesso,
//     nivel_acesso,
//     telasPermissao,
//     password,
//     acessoModulos
//   } = useContext(AuthContext)
//   console.log('acessoModulos', acessoModulos)

//   const [isOwner, setIsOwner] = useState(false)
//   let podeAcessar
//   useEffect(() => {
//     const checkSystemOwner = async () => {
//       if (user) {
//         const ownerStatus = await isSystemOwner(user, password)

//         setIsOwner(ownerStatus)
//       }
//     }
//     checkSystemOwner()
//   }, [user, password])

//   if (!isAuthenticated) {
//     return null
//   }

//   if (isOwner) {
//     return children
//   }

//   if (RESTRICTED_MENUS.includes(tela)) {
//     return null
//   }

//   if (tela) {
//     if (nivel_acesso && nivel_acesso?.toLowerCase() == 'administrador') {
//       podeAcessar = true
//     } else if (telasPermissao?.length > 0) {
//       podeAcessar = telasPermissao.some(
//         permissao => permissao.tela === tela && permissao.id_nivel === idNivelAcesso
//       )
//     }

//     if (!podeAcessar) {
//       return null
//     }
//   }

//   if (podeAcessar) return children
// }

// Defina as telas que requerem verificação de acessoModulos
const TELAS_COM_ACESSO_MODULOS = ['corte', 'modelagem', 'plotagem']

const PrivateMenuItem = ({ children, tela }) => {
  PrivateMenuItem.propTypes = {
    children: PropTypes.node,
    tela: PropTypes.string.isRequired
  }

  const {
    isAuthenticated,
    user,
    idNivelAcesso,
    nivel_acesso,
    telasPermissao,
    password,
    acessoModulos
  } = useContext(AuthContext)

  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    const checkSystemOwner = async () => {
      if (user) {
        const ownerStatus = await isSystemOwner(user, password)
        setIsOwner(ownerStatus)
      }
    }
    checkSystemOwner()
  }, [user, password])

  // Se o usuário não estiver autenticado, não renderiza nada
  if (!isAuthenticated) {
    return null
  }

  // Se o usuário for o proprietário do sistema, permite o acesso a todos os menus
  if (isOwner) {
    return children
  }

  // Verifica se a tela está na lista de menus restritos
  if (RESTRICTED_MENUS.includes(tela.toLowerCase())) {
    return null
  }

  let podeAcessar = false

  if (tela) {
    const telaMinuscula = tela.toLowerCase()

    if (TELAS_COM_ACESSO_MODULOS.includes(telaMinuscula)) {
      // Apenas para telas específicas, define podeAcessar com base em acessoModulos
      // console.log('acessoModulos', acessoModulos)
      if (acessoModulos && acessoModulos[telaMinuscula] === true) {
        podeAcessar = true
      } else {
        podeAcessar = false
      }
    } else {
      // Para outras telas, usa a lógica de permissões existente
      if (nivel_acesso && nivel_acesso.toLowerCase() === 'administrador') {
        podeAcessar = true
      } else if (telasPermissao && telasPermissao.length > 0) {
        podeAcessar = telasPermissao.some(
          permissao =>
            permissao.tela.toLowerCase() === telaMinuscula && permissao.id_nivel === idNivelAcesso
        )
      }
    }

    // Se após as verificações, o usuário não tem permissão, não renderiza nada
    if (!podeAcessar) {
      return null
    }
  }

  // Se o usuário tem permissão, renderiza os children
  return podeAcessar ? children : null
}
const AppModelagemCompleta = ({ rotaBase, acessoModulos }) => {
  // const [hasCheckedBackup, setHasCheckedBackup] = useState(false);

  AppModelagemCompleta.propTypes = {
    rotaBase: PropTypes.string.isRequired
  }
  let { isAuthenticated, logout, assinaturaStatus } = useContext(AuthContext)

  useInactivityLogout()
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/funcionarios-ativos', { state: { origem: 'Corte' } })
  }
  // const [showChat, setShowChat] = useState(false)
  // const [subdomain, setSubdomain] = useState('')

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        {isAuthenticated && (
          <Navbar bg="dark" expand="lg">
            <div className="spacer"></div>
            <Navbar.Brand
              href="/"
              className="logo"
              style={{
                fontSize: '1.2rem'
              }}
            >
              MODELAGEM COMPLETA
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav">
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                <PrivateMenuItem tela="Relatórios">
                  <NavDropdown title="Relatórios" id="relatorios-dropdown">
                    <NavDropdown
                      title="Corte"
                      id="relatorios-corte-dropdown"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item as={Link} to="/resumo-corte" className="nav-dropdown-item">
                        Resumo do Corte
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/analise-receita-corte"
                        className="nav-dropdown-item"
                      >
                        Análise de Receita
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/analise-lucro-bruto-corte"
                        className="nav-dropdown-item"
                      >
                        Análise de Lucro Bruto
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown.Item
                      as={Link}
                      to="/relatorios-modelagem"
                      className="nav-dropdown-item"
                    >
                      Modelagem
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/relatorios-plotagem"
                      className="nav-dropdown-item"
                    >
                      Plotagem
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/relatorios-financeiro"
                      className="nav-dropdown-item"
                    >
                      Financeiro
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Marketplaces">
                  <NavDropdown title="Marketplaces" id="marketplaces-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/pagamentos-marketplaces"
                      className="nav-dropdown-item"
                    >
                      Gerenciamento de Pagamentos
                    </NavDropdown.Item>

                    <NavDropdown
                      title="Mercado Livre"
                      id="mercado-livre-dropdown"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/listagem-produtos-mercadolivre"
                        className="dropdown-item custom-dropdown-item"
                      >
                        Listagem de Produtos
                      </NavDropdown.Item>
                    </NavDropdown>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Financeiro">
                  <NavDropdown title="Financeiro" id="financeiro-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/relatorio-debitos-clientes"
                      className="nav-dropdown-item"
                    >
                      Financeiro Geral
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/financeiro" className="nav-dropdown-item">
                      Gestão Financeira do Cliente
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Sub Sistemas">
                  <NavDropdown title="Sub Sistema" id="sub-sistema-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-sub-sistema"
                      className="nav-dropdown-item"
                    >
                      Cadastro de Sub Sistema
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Usuário">
                  <NavDropdown title="Usuario" id="usuario-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-usuario"
                      className="nav-dropdown-item"
                    >
                      CADASTRO USUÁRIO
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-nivel-acesso"
                      className="nav-dropdown-item"
                    >
                      CADASTRO NIVEL ACESSO
                    </NavDropdown.Item>
                    <PrivateMenuItem tela="Configuração de Níveis de Acesso">
                      <NavDropdown.Item
                        as={Link}
                        to="/configuracao-usuario"
                        className="nav-dropdown-item"
                      >
                        CONFIGURAÇÃO DE NIVEL DE ACESSO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <NavDropdown.Item
                      as={Link}
                      to="/gerenciador-de-ips"
                      className="nav-dropdown-item"
                    >
                      GERENCIAR IPS DE ACESSO
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Cadastro Geral">
                  <NavDropdown title="Cadastro Geral" id="cadastro-geral-dropdown">
                    <NavDropdown.Item as={Link} to="/cadastro-geral" className="nav-dropdown-item">
                      CADASTRO
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-servico"
                      className="nav-dropdown-item"
                    >
                      CADASTRO SERVIÇO
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-categoria"
                      className="nav-dropdown-item"
                    >
                      CADASTRO CATEGORIA
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-produto"
                      className="nav-dropdown-item"
                    >
                      CADASTRO PRODUTO
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/visualizacao-categorias"
                      className="nav-dropdown-item"
                    >
                      VISUALIZAÇÃO e EDIÇAO DE CATEGORIAS
                    </NavDropdown.Item>
                    <PrivateMenuItem tela="Cadastro Associação Serviço Categoria">
                      <NavDropdown.Item
                        as={Link}
                        to="/associacao-servico-categoria"
                        className="nav-dropdown-item"
                      >
                        ASSOCIAÇÃO SERVIÇO-CATEGORIA
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Gerenciamento de Setores">
                      <NavDropdown.Item
                        as={Link}
                        to="/gerenciamento-setores"
                        className="nav-dropdown-item"
                      >
                        GERENCIAMENTO DE SETORES
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Gerenciamento de Cargos">
                      <NavDropdown.Item
                        as={Link}
                        to="/gerenciamento-cargos"
                        className="nav-dropdown-item"
                      >
                        GERENCIAMENTO DE CARGOS
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Gerenciamento de Horarios">
                      <NavDropdown.Item
                        as={Link}
                        to="/gerenciamento-horarios"
                        className="nav-dropdown-item"
                      >
                        GERENCIAMENTO DE HORARIOS
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Gerenciamento de Presença">
                      <NavDropdown.Item
                        as={Link}
                        to="/funcionarios-ativos"
                        className="nav-dropdown-item"
                      >
                        GERENCIAMENTO DE PRESENÇA
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    {/* <NavDropdown.Item
                  as={Link}
                  to='/associacao-cliente-servico'
                  className='nav-dropdown-item'
                >
                  ASSOCIAÇÃO CLIENTE-SERVIÇO
                </NavDropdown.Item> */}
                  </NavDropdown>
                </PrivateMenuItem>
                {/* Cadastro de PLOTAGENS */}
                <PrivateMenuItem tela="Plotagem">
                  <NavDropdown title="Plotagem" id="plotagem-dropdown">
                    <PrivateMenuItem tela="Cadastro Cliente Plotagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/plotagem-cadastro-cliente"
                        className="nav-dropdown-item"
                      >
                        CADASTRO CLIENTE
                      </NavDropdown.Item>
                    </PrivateMenuItem>

                    <PrivateMenuItem tela="Plotagem Pesquisa e Edição Cliente">
                      <NavDropdown.Item
                        as={Link}
                        to="/plotagem-pesquisa-e-edicao-cliente"
                        className="nav-dropdown-item"
                      >
                        PESQUISA E EDIÇÃO DE CLIENTE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Cadastro de Plotagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/plotagem-cadastro-plotagem"
                        className="nav-dropdown-item"
                      >
                        CADASTRO PLOTAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Editar Plotagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/editando-plotagems"
                        className="nav-dropdown-item"
                      >
                        EDITAR PLOTAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Configurar Valor Plotagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/configurar-valor-plotagem"
                        className="nav-dropdown-item"
                      >
                        CONFIGURAR VALOR PLOTAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>
                {/* Cadastro de CORTES */}
                <PrivateMenuItem tela="Corte">
                  <NavDropdown title="Corte" id="corte-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/corte-cadastro-cliente"
                      className="nav-dropdown-item"
                    >
                      CADASTRO CLIENTE
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/corte-pesquisa-e-edicao-cliente"
                      className="nav-dropdown-item"
                    >
                      PESQUISA E EDIÇÃO DE CLIENTE
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/corte-cadastro-corte"
                      className="nav-dropdown-item"
                    >
                      CADASTRO CORTE
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/corte-editar-corte"
                      className="nav-dropdown-item"
                    >
                      EDITAR CORTE
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/finalizacao-de-cortes"
                      className="nav-dropdown-item"
                    >
                      FINALIZAÇAO DE CORTES
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/configurar-valor-embalagem"
                      className="nav-dropdown-item"
                    >
                      CONNFIGURAR VALOR EMBALAGEM
                    </NavDropdown.Item>
                    <NavDropdown
                      title="FUNCIONÁRIOS"
                      id="funcionarios-dropdown"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/cadastro-pagamentos-funcionarios"
                        className="nav-dropdown-item"
                      >
                        Cadastrar Pagamentos
                      </NavDropdown.Item>
                      <PrivateMenuItem tela="Gerenciamento de Presença">
                        <NavDropdown.Item onClick={handleNavigate} className="nav-dropdown-item">
                          Gerenciamento de Presença
                        </NavDropdown.Item>
                      </PrivateMenuItem>
                    </NavDropdown>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Modelagem">
                  <NavDropdown title="Modelagem" id="corte-dropdown">
                    <PrivateMenuItem tela="Cadastro Cliente Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-cadastro-cliente"
                        className="nav-dropdown-item"
                      >
                        CADASTRO CLIENTE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Modelagem Pesquisa e Edição Cliente">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-pesquisa-e-edicao-cliente"
                        className="nav-dropdown-item"
                      >
                        PESQUISA E EDIÇÃO DE CLIENTE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Cadastro Serviço Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-cadastro-servico-modelagem"
                        className="nav-dropdown-item"
                      >
                        CADASTRO DE PEDIDO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Cadastro Grau Dificuldade Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-cadastro-grau-dificuldade-modelagem"
                        className="nav-dropdown-item"
                      >
                        CADASTRO GRAU DIFICULDADE MODELAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Cadastro Preços Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-cadastro-precos-modelagem"
                        className="nav-dropdown-item"
                      >
                        CADASTRO PREÇOS MODELAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Editar Preços Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/modelagem-editar-precos-modelagem"
                        className="nav-dropdown-item"
                      >
                        EDITAR PREÇOS MODELAGEM
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Editar Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/editando-modelagems"
                        className="nav-dropdown-item"
                      >
                        EDITAR PEDIDO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="Finalização de Modelagem">
                      <NavDropdown.Item
                        as={Link}
                        to="/finalizacao-de-modelagems"
                        className="nav-dropdown-item"
                      >
                        FINALIZAÇAO DE MODELAGENS
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Assinar Plano">
                  {assinaturaStatus !== 'ativa' ? (
                    <Nav.Link as={Link} to="/assinar-plano" className="cta-button">
                      Assinar Plano
                    </Nav.Link>
                  ) : (
                    <Nav.Link as={Link} to="/assinar-plano" className="cta-button-secondary">
                      Adicionar Módulos
                    </Nav.Link>
                  )}
                </PrivateMenuItem>
                <Nav.Link onClick={() => deslogar(logout, 'modelagemcompleta')}>Sair</Nav.Link>
              </Nav>
            </Navbar.Collapse>

            <div className="spacer"></div>
          </Navbar>
        )}

        <AppRoutes rotaBase={rotaBase} />
        {/* {showChat && <ChatWindow />}
        <button
          className="chat-button"
          onClick={() => setShowChat((prevShowChat) => !prevShowChat)}>
          Chat
        </button> */}
      </div>
    </QueryClientProvider>
  )
}

export default AppModelagemCompleta
