import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../../src/context/AuthContext'
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { Table, Container, Spinner, Form, Button } from 'react-bootstrap'
import Banner from '../../../../../src/components/pages/CadastroGeral/Banner'
import DatePicker from 'react-datepicker'
const RelatoriosCorte = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [dataInicio, setDataInicio] = useState(new Date())
  const [dataFim, setDataFim] = useState(new Date())
  const [periodoSelecionado, setPeriodoSelecionado] = useState('7d') // Default to last 7 days
  const [receitaTotal, setReceitaTotal] = useState(0)
  const [receitaPorCliente, setReceitaPorCliente] = useState([])
  const [eficienciaMateriais, setEficienciaMateriais] = useState([])
  const [pontualidadeEntregas, setPontualidadeEntregas] = useState(0)
  const [totalEntregas, setTotalEntregas] = useState(0)
  const [mediaCortesPorDia, setMediaCortesPorDia] = useState(0)
  const [totalDiasTrabalhados, setTotalDiasTrabalhados] = useState(0)
  const [diasTrabalhadosDetalhado, setDiasTrabalhadosDetalhado] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'
  const [showMoreDeliveries, setShowMoreDeliveries] = useState(false)
  const [showDetailedReport, setShowDetailedReport] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const updateDateRange = period => {
    const end = new Date()
    let start = new Date()
    switch (period) {
      case '7d':
        start.setDate(end.getDate() - 7)
        break
      case '15d':
        start.setDate(end.getDate() - 15)
        break
      case '1m':
        start.setMonth(end.getMonth() - 1)
        break
      case '3m':
        start.setMonth(end.getMonth() - 3)
        break
      case '6m':
        start.setMonth(end.getMonth() - 6)
        break
      case '1y':
        start.setFullYear(end.getFullYear() - 1)
        break
      case 'all':
        start = new Date(0) // Start of UNIX time
        break
      case 'custom':
        // Keep current custom range
        return
      default:
        return
    }

    setDataInicio(start)
    setDataFim(end)
  }

  const handlePeriodoSelecionado = event => {
    const period = event.target.value
    setPeriodoSelecionado(period)
    if (period !== 'custom') {
      updateDateRange(period)
    }
  }

  useEffect(() => {
    let dados
    if (!isInitialized) {
      // Verifica se já foi inicializado
      updateDateRange('7d') // Define o intervalo de 7 dias na inicialização
      setIsInitialized(true) // Marca como inicializado
    }
    const fetchRelatorios = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/relatoriosCorte`
        dados = {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterReceitaTotal'
        }
        // Fetch total revenue
        const receitaTotalResponse = await fetchData(url, dados)
        if (receitaTotalResponse) {
          setReceitaTotal(receitaTotalResponse.receitaTotal || 0)
        }
        dados = {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterReceitaPorCliente'
        }
        // Fetch revenue by client
        const receitaPorClienteResponse = await fetchData(url, dados)

        if (receitaPorClienteResponse) {
          setReceitaPorCliente(receitaPorClienteResponse || [])
        }

        // Fetch materials efficiency
        dados = {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterEficienciaMateriais'
        }
        const eficienciaMateriaisResponse = await fetchData(url, dados)

        if (eficienciaMateriaisResponse) {
          setEficienciaMateriais(eficienciaMateriaisResponse || [])
        }

        // Fetch delivery timeliness
        dados = {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterPontualidadeEntregas'
        }
        const pontualidadeEntregasResponse = await fetchData(url, dados)

        if (pontualidadeEntregasResponse) {
          setPontualidadeEntregas(pontualidadeEntregasResponse.percentualEntregasNoPrazo || 0)
          setTotalEntregas(pontualidadeEntregasResponse.totalEntregas || 0)
        }

        // Fetch average cuts per day
        dados.requisicao = 'obterMediaCortesPorDia'
        const mediaCortesPorDiaResponse = await fetchData(url, dados)
        if (mediaCortesPorDiaResponse) {
          setMediaCortesPorDia(mediaCortesPorDiaResponse.mediaCortesPorDia || 0)
          setTotalDiasTrabalhados(mediaCortesPorDiaResponse.totalDiasTrabalhados || 0)
          setDiasTrabalhadosDetalhado(mediaCortesPorDiaResponse.diasTrabalhadosDetalhado || [])
        }

        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar dados dos relatórios:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar dados dos relatórios')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchRelatorios()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  if (loading) {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </Container>
    )
  }

  const renderDetalhado = () => {
    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ]

    return (
      <div className="detalhado-list">
        {Object.entries(diasTrabalhadosDetalhado).map(([mes, dias]) => (
          <p key={mes}>{`${meses[mes]}: ${dias} dias`}</p>
        ))}
      </div>
    )
  }
  const handleDateChange = dates => {
    const [start, end] = dates // Extrai as datas de início e fim do array
    setDataInicio(start) // Atualiza o estado da data de início
    setDataFim(end) // Atualiza o estado da data de fim
  }

  return (
    <Container fluid className="p-4">
      <div
        className="d-flex flex-column align-items-center form-container"
        style={{ marginTop: '30px' }}
      >
        <Banner title="Resumo do Corte" width="27%" fontSize={60} classEfeito3dMouseOver="" />
      </div>

      {/* Container Grid */}
      <div className="grid-container">
        {/* Painel de Seleção de Período */}
        <div className="period-selection">
          <Form.Group className="mb-3">
            <h4 className="mb-3">Selecione o Período</h4>
            <div className="d-flex flex-column">
              {['7d', '15d', '1m', '3m', '6m', '1y', 'all', 'custom'].map(period => (
                <Form.Check
                  key={period}
                  type="radio"
                  label={periodLabels(period)}
                  name="periodoRelatorio"
                  id={`radio-${period}`}
                  value={period}
                  checked={periodoSelecionado === period}
                  onChange={handlePeriodoSelecionado}
                  className="mb-2"
                />
              ))}
            </div>
            {periodoSelecionado === 'custom' && (
              <DatePicker
                selectsRange={true}
                startDate={dataInicio}
                endDate={dataFim}
                onChange={date => handleDateChange(date)}
                isClearable={true}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            )}
          </Form.Group>
        </div>

        {/* Conteúdo das Tabelas */}
        <div className="content-tables">
          <TableSection
            title="Receita por Cliente"
            data={receitaPorCliente}
            columns={['Cliente', 'Receita Total (R$)', 'Total de Peças']}
            classColumns={['title-columnRC', 'narrow-columnRC', 'narrow-columnRC']}
            dataInicio={dataInicio}
            dataFim={dataFim}
            RotaBase={RotaBase}
            tenantId={tenantId}
          />
          <TableSection
            title="Eficiência dos Materiais"
            data={eficienciaMateriais}
            columns={['Tecido', 'Total de Rolos', 'Total de Peças']}
            classColumns={['title-columnRC', 'narrow-columnRC', 'narrow-columnRC']}
            dataInicio={dataInicio}
            dataFim={dataFim}
            RotaBase={RotaBase}
            tenantId={tenantId}
          />
          <div className="section-container mt-4">
            <h2 className="mb-3">Pontualidade das Entregas</h2>
            <div className="delivery-timeliness-box p-3 mb-3">
              <p className="mb-1">{`Entregas no prazo: ${pontualidadeEntregas.toFixed(2)}%`}</p>
              {showMoreDeliveries && (
                <>
                  <p className="mb-0">{`Número total de cortes: ${totalEntregas}`}</p>
                  <p className="mb-1">
                    {`Total de dias trabalhados: ${totalDiasTrabalhados}`}
                    <Button
                      variant="link"
                      onClick={() => setShowDetailedReport(prev => !prev)}
                      style={{ marginLeft: '10px' }}
                    >
                      {showDetailedReport ? 'Ocultar relatório' : 'Mostrar relatório'}
                    </Button>
                  </p>
                  {showDetailedReport && renderDetalhado()}
                  <p className="mb-0">{`Média de cortes por dia: ${mediaCortesPorDia.toFixed(
                    2
                  )}`}</p>
                </>
              )}
              <Button variant="link" onClick={() => setShowMoreDeliveries(prev => !prev)}>
                {showMoreDeliveries ? 'Mostrar menos' : 'Mostrar mais'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default RelatoriosCorte

function periodLabels(period) {
  return {
    '7d': 'Últimos 7 dias',
    '15d': 'Últimos 15 dias',
    '1m': 'Último mês',
    '3m': 'Últimos 3 meses',
    '6m': 'Últimos 6 meses',
    '1y': 'Último ano',
    all: 'Todo o período',
    custom: 'Intervalo personalizado'
  }[period]
}

function TableSection({
  title,
  data,
  columns,
  classColumns,
  dataInicio,
  dataFim,
  RotaBase,
  tenantId
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [detailedData, setDetailedData] = useState([])

  // Mapeia nomes de colunas para chaves dos dados se forem diferentes
  const columnKeys = {
    Cliente: 'clienteNome',
    Tecido: '_id',
    'Receita Total (R$)': 'totalReceita',
    'Total de Peças': 'totalPecas',
    'Total de Rolos': 'totalRolos'
  }

  // Função para verificar se deve exibir mais dados
  const shouldShowMore = () => {
    if (title.includes('Cliente') && data.some(item => item.totalReceita > 0)) {
      return true
    } else if (title.includes('Materiais') && data.some(item => item.totalRolos > 0)) {
      return true
    }
    return false
  }

  const toggleExpand = async () => {
    setIsExpanded(prev => !prev)

    if (!isExpanded && detailedData.length === 0) {
      const url = `${config.host}/relatoriosCorte`
      let bodyData = {
        tenantId,
        rota_base: RotaBase,
        dataInicio,
        dataFim
      }

      try {
        if (title.includes('Receita por Cliente')) {
          bodyData.requisicao = 'obterReceitaPorClienteIndividual'
        } else if (title.includes('Materiais')) {
          bodyData.requisicao = 'obterEficienciaMateriaisPorTecido'
        }

        const fetchedData = await fetchData(url, bodyData)
        if (fetchedData) {
          setDetailedData(fetchedData)
        }
      } catch (error) {
        console.error('Erro ao carregar dados detalhados:', error)
      }
    }
  }

  return (
    <div>
      <h2 className="titulo-secaoRC">{title}</h2>
      <Table striped bordered hover className="text-center custom-tableRC">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={col} className={classColumns[index]}>
                {index === 0 && shouldShowMore() ? (
                  <>
                    {col}
                    <Button variant="link" onClick={toggleExpand} style={{ marginLeft: '10px' }}>
                      {isExpanded ? 'Resumir' : 'Mostrar Todos'}
                    </Button>
                  </>
                ) : (
                  col
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(isExpanded ? detailedData : data).map((item, index) => (
            <tr key={index}>
              {columns.map((col, colIndex) => {
                const key = columnKeys[col] || col
                const value = item[key] !== undefined ? item[key] : 'Todos'
                return (
                  <td key={`${col}-${index}`} className={classColumns[colIndex]}>
                    {col === 'Receita Total (R$)'
                      ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                      : value}
                  </td>
                )
              })}
            </tr>
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={columns.length}>Nenhum dado disponível</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}
