import { useState } from 'react'
import { AuthContext } from '../../../../../src/context/AuthContext'
import { useContext } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import Banner from '../../../../../src/components/pages/CadastroGeral/Banner'
import { ConfirmationModal } from '../../../../../src/components/DraggableContainer/ConfirmationModal/confirmation-modal'
import { enviar } from '../../../../../src/backend/model'
const AccessLevelRegistration = () => {
  // Estados para os campos do formulário
  const [nomeNivel, setNomeNivel] = useState('')
  const [descricao, setDescricao] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)
  // Função para lidar com o envio do formulário
  const handleSubmit = async e => {
    e.preventDefault()

    if (nomeNivel) {
      setShowConfirmationModal(true)
    }
  }
  //-----------------------------------------------
  const CadastrarNivelAcesso = async () => {
    const dadosNivelAcesso = {
      informacoes: {
        nome: nomeNivel,
        descricao: descricao,
        rota_base: RotaBase,
        tenantId: tenantId
      },
      pagina: 'nivel_acesso'
    }

    await enviar(dadosNivelAcesso)
  }
  //-----------------------Função para enviar os dados para o backend-----------------------
  const confirmCadastro = () => {
    CadastrarNivelAcesso()

    setShowConfirmationModal(false) // Fecha o modal após a ação
    setNomeNivel('')
    setDescricao('')
  }
  return (
    <Container fluid className="d-flex align-items-center form-container_subSistema">
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <div className="title-container" style={{ marginTop: '6rem' }}>
          <Banner title="Cadastro de Nível de Acesso" />
        </div>
        <h2 className="mb-4">Cadastro de Nível de Acesso do Sistema</h2>
        <ConfirmationModal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          title="Confirmação de Cadastro"
          message="Tem certeza que deseja cadastrar este nivel de acesso?"
          confirmLabel="Confirmar"
          cancelLabel="Cancelar"
          onConfirm={confirmCadastro} // Você deve passar a função de confirmação aqui
        />
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formNomeNivel">
                <Form.Label>Nome do Nível de Acesso</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o nome do nível de acesso"
                  value={nomeNivel}
                  onChange={e => setNomeNivel(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group controlId="formDescricaoNivel">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Digite a descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="success" type="submit" style={{ width: '30%' }}>
            Cadastrar
          </Button>
        </Form>
      </div>
    </Container>
  )
}

export default AccessLevelRegistration
