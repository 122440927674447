// usuarios
// arquivo compartilhado
import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import bcrypt from 'bcryptjs'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { CPFUtils } from '/src/utils/cpf-utils'
import { masks } from '/src/utils/masks'
import { enviar, requisitar } from '/src/backend/model'
import { ConfirmationModal } from '/src/components/DraggableContainer/ConfirmationModal/confirmation-modal'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'
import { validateEmail, validateTelefone } from '/src/utils/form-utils.js'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import {
  ShowAlertCPF,
  ShowAlertCep,
  ShowAlertEmail,
  ShowAlertTel
} from '/src/components/pages/CadastroGeral/cadastro/cadastro-alerts'

const UserRegistration = props => {
  // Variáveis de estado
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [cpf, setCpf] = useState('')
  const [isValidCpf, setisValidCpf] = useState(true)
  const [tipoPessoa, setTipoPessoa] = useState('')
  const [categoria, setCategoria] = useState('')
  const [sexo, setSexo] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [isValidEmail, setisValidEmail] = useState(true)
  const [cep, setCep] = useState('')
  const [isValidCep, setisValidCep] = useState(true)
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [uf, setUf] = useState('')
  const [isBlurred, setisBlurred] = useState(false)
  const [showAlertCPF, setShowAlertCPF] = useState(false)
  const [showAlertCep, setShowAlertCep] = useState(false)
  const [showAlertEmail, setShowAlertEmail] = useState(false)
  const [showAlertTel, setShowAlertTel] = useState(false)
  const [dataAdmissao, setDataAdmissao] = useState('')
  const [dataDemissao, setDataDemissao] = useState('')
  const [dataNascimento, setDataNascimento] = useState('')
  const [cargo, setCargo] = useState('')
  const [nomeUsuario, setNomeUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [nivelAcesso, setNivelAcesso] = useState('')
  const [idNivelAcesso, setIdNivelAcesso] = useState('')
  const [ativo, setAtivo] = useState(true)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isTelefoneObrigatorio, setIsTelefoneObrigatorio] = useState(false)
  const [isEmailObrigatorio, setIsEmailObrigatorio] = useState(false)
  const [isCepObrigatorio, setIsCepObrigatorio] = useState(false)
  const [isCpfObrigatorio, setIsCpfObrigatorio] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [niveisDeAcesso, setNiveisDeAcesso] = useState([])
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isFirstOption, setIsFirstOption] = useState(true)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const handleOnChange = e => {
    let id_nivel = getIdNivelAcesso(niveisDeAcesso, e.target.value)
    setIdNivelAcesso(id_nivel)

    setNivelAcesso(e.target.value)
    setIsFirstOption(false)
  }
  //----------------------------------------------------
  useEffect(() => {
    setNivelAcesso('')
    setIsFirstOption(true)
  }, [])

  useEffect(() => {
    if (props.isEditing) {
      setIsEditMode(true)
      setIsEditMode(true)
      // Carregar os dados do usuário para edição
    }
    if (!niveisDeAcesso.length) {
      getNiveisDeAcesso(setNiveisDeAcesso, RotaBase, tenantId)
    }
  }, [])
  //----------------------------------------------------
  const handleSubmit = async e => {
    e.preventDefault()

    // Verifica a validação dos campos
    const isCpfValido = isCpfObrigatorio
      ? CPFUtils.validarCPF(cpf)
      : cpf
      ? CPFUtils.validarCPF(cpf)
      : true

    const isEmailValido = isEmailObrigatorio
      ? validateEmail(email)
      : email
      ? validateEmail(email)
      : true

    const isTelefoneValido = isTelefoneObrigatorio
      ? validateTelefone(telefone)
      : telefone
      ? validateTelefone(telefone)
      : true

    // Atualiza os estados de alerta com a validade atual dos campos
    if (!isCpfValido) {
      setShowAlertCPF(true)
    }
    if (!isEmailValido) {
      setShowAlertEmail(true)
    }
    if (!isTelefoneValido) {
      setShowAlertTel(true)
    }

    // Se todos os campos obrigatórios estiverem válidos, prossegue com o cadastro
    if (isCpfValido && isEmailValido && isTelefoneValido) {
      setShowConfirmationModal(true)
    } else {
      // Aqui você pode adicionar uma lógica adicional se nenhum campo for válido
    }
  }

  const handleCpfChange = e => {
    const value = e.target.value
    setCpf(CPFUtils.formatCPF(value)) // Aqui usamos diretamente setCpf para atualizar o estado
  }

  // Função para tratar o evento onBlur no campo CPF
  const handleCpfBlur = e => {
    const value = e.target.value
    setisBlurred(true)
    if (value !== '') {
      setisValidCpf(CPFUtils.validarCPF(value))
      setShowAlertCPF(!CPFUtils.validarCPF(value))
    } else {
      setisValidCpf(true)
      setShowAlertCPF(false)
    }
  }
  //--------------------------
  const handleEmailChange = e => {
    const value = e.target.value

    setEmail(value)
    // Remova a validação e a atualização do estado do alerta daqui
  }

  // -------------------------
  const handleEmailBlur = () => {
    if (email) {
      const isValid = validateEmail(email)
      setisValidEmail(isValid)
      setShowAlertEmail(!isValid)
    } else {
      setShowAlertEmail(false)
    }
  }

  //--------------------------
  const handleDataNascimentoChange = e => {
    setDataNascimento(e.target.value)
  }
  //--------------------------
  const handleCepChange = event => {
    const value = event.target.value
    const maskedValue = masks.cep(value)

    setFormData({ ...formData, cep: maskedValue || value })
    setisValidCep(maskedValue && maskedValue.length === 9)
  }
  async function CadastrarUsuario() {
    // let myPlaintextPassword = senha;
    let senhaencriptada
    const saltRounds = 10
    const hashPassword = async senha => {
      const salt = await bcrypt.genSalt(saltRounds)
      return bcrypt.hash(senha, salt)
    }
    senhaencriptada = await hashPassword(senha)

    const dados = {
      informacoes: {
        nome: nome,
        sobrenome: sobrenome,
        cpf: cpf,
        data_cadastro: new Date(),
        data_admicao: dataAdmissao,
        data_demicao: dataDemissao,
        data_nascimento: dataNascimento,
        cargo: cargo,
        nome_usuario: nomeUsuario.trim(),
        senha: senhaencriptada,
        nivel_acesso: nivelAcesso,
        id_nivel_acesso: idNivelAcesso,
        ativo: ativo,
        email: email,
        telefone: telefone,
        rota_base: RotaBase,
        tenantId: tenantId
      },
      pagina: 'usuarios'
    }

    await enviar(dados)
  }
  //--------------------------
  const handleTelefoneChange = e => {
    const value = e.target.value

    setTelefone(value)
  }

  const handleTelefoneBlur = () => {
    setisBlurred(true)
    if (telefone) {
      const isValid = validateTelefone(telefone)
      setShowAlertTel(!isValid)
    } else {
      setShowAlertTel(false)
    }
  }
  //--------------------------
  const confirmCadastro = async () => {
    // Aqui você coloca a lógica para cadastrar o usuário após a confirmação.
    await CadastrarUsuario()

    // Reseta os estados para limpar os campos do formulário
    setNome('')
    setSobrenome('')
    setCpf('')
    setTipoPessoa('')
    setCategoria('')
    setSexo('')
    setTelefone('')
    setEmail('')
    setCep('')
    setEndereco('')
    setNumero('')
    setComplemento('')
    setBairro('')
    setCidade('')
    setUf('')
    setDataAdmissao('')
    setDataDemissao('')
    setDataNascimento('')
    setCargo('')
    setNomeUsuario('')
    setSenha('')
    setNivelAcesso('')
    setIdNivelAcesso('')
    setAtivo(true)
    setShowConfirmationModal(false) // Fecha o modal após a ação
    setAlertMessage('USUARIO CADASTRADO!')
    setShowAlertMessage(true)
  }

  return (
    <Container fluid className="d-flex align-items-center form-container_subSistema">
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true} // ou algum outro critério que deseja usar para blur
        setShowAlert={setShowAlertMessage}
        variant="success"
      />
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <div className="title-container" style={{ marginTop: '6rem' }}>
          <Banner title="Cadastro de Usuário" />
        </div>
        <h2 className="mb-4">Cadastro de Usuário do Sistema</h2>

        <ShowAlertCPF
          message="Por favor, insira um número de CPF válido"
          showAlert_cpf={showAlertCPF}
          isBlurred={isBlurred}
          setShowAlertCPF={setShowAlertCPF}
          isCpfObrigatorio={isCpfObrigatorio}
        />
        <ShowAlertCep
          message="Por favor, insira um número de CEP válido"
          showAlert_cep={!isValidCep}
          isBlurred={isBlurred}
          setShowAlertCep={setShowAlertCep}
          isCepObrigatorio={isCepObrigatorio}
        />
        <ShowAlertEmail
          message="Por favor, insira um endereço de email válido!"
          showAlert_email={showAlertEmail}
          isBlurred={isBlurred}
          setShowAlertEmail={setShowAlertEmail}
          isEmailObrigatorio={isEmailObrigatorio}
        />
        <ShowAlertTel
          message="Por favor, insira um número de telefone válido"
          showAlert_tel={showAlertTel}
          isBlurred={isBlurred}
          setShowAlertTel={setShowAlertTel}
          isTelefoneObrigatorio={isTelefoneObrigatorio}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          title="Confirmação de Cadastro"
          message="Tem certeza que deseja cadastrar este usuário?"
          confirmLabel="Confirmar"
          cancelLabel="Cancelar"
          onConfirm={confirmCadastro} // Você deve passar a função de confirmação aqui
        />

        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="firstName">Nome</Form.Label>
              <Form.Control
                id="firstName"
                type="text"
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="lastName">Sobrenome</Form.Label>
              <Form.Control
                id="lastName"
                type="text"
                value={sobrenome}
                onChange={e => setSobrenome(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="mb-3">
              <Form.Label htmlFor="cpf">CPF</Form.Label>
              <Form.Control
                id="cpf"
                type="text"
                value={cpf}
                onChange={handleCpfChange}
                onBlur={handleCpfBlur}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="telefone">Telefone</Form.Label>
              <Form.Control
                id="telefone"
                type="text"
                value={telefone}
                onChange={handleTelefoneChange}
                onBlur={handleTelefoneBlur}
              />
            </Col>
          </Row>

          {/* Continuação do formulário com os demais campos, seguindo o padrão acima */}

          <Row>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="dataAdmissao">Data de Admissão</Form.Label>
              <Form.Control
                id="dataAdmissao"
                type="date"
                value={dataAdmissao}
                onChange={e => setDataAdmissao(e.target.value)}
              />
            </Col>
            {isEditMode && (
              <Col md={4} className="mb-3">
                <Form.Label htmlFor="dataDemissao">Data de Demissão</Form.Label>
                <Form.Control
                  id="dataDemissao"
                  type="date"
                  // Atribuir e manipular a data de demissão conforme necessário
                />
              </Col>
            )}
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="dataNascimento">Data de Nascimento</Form.Label>
              <Form.Control
                id="dataNascimento"
                type="date"
                value={dataNascimento}
                onChange={handleDataNascimentoChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="cargo">Cargo</Form.Label>
              <Form.Control
                id="cargo"
                type="text"
                value={cargo}
                onChange={e => setCargo(e.target.value)}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="nomeUsuario">Nome de Usuário</Form.Label>
              <Form.Control
                id="nomeUsuario"
                type="text"
                value={nomeUsuario}
                onChange={e => setNomeUsuario(e.target.value)}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="senha">Senha</Form.Label>
              <Form.Control
                id="senha"
                type="password"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mb-3">
              <Form.Label htmlFor="nivelAcesso">Nível de Acesso</Form.Label>
              <Form.Control
                as="select"
                id="nivelAcesso"
                value={nivelAcesso}
                onChange={handleOnChange}
                style={isFirstOption ? { color: 'grey' } : {}}
              >
                {/* Opção inicial desabilitada como placeholder */}
                <option value="" disabled selected>
                  Selecione um nível de acesso
                </option>
                {/* Gera as opções de nível de acesso */}
                {niveisDeAcesso.map(nivel => (
                  <option key={nivel.value} value={nivel.label}>
                    {nivel.label}
                  </option>
                ))}
              </Form.Control>
            </Col>
            {isEditMode && (
              <Col md={4} className="mb-3">
                <Form.Label htmlFor="ativo">Ativo</Form.Label>
                <Form.Check
                  id="ativo"
                  type="checkbox"
                  label="Usuário Ativo"
                  checked={ativo}
                  onChange={e => setAtivo(e.target.checked)}
                />
              </Col>
            )}
          </Row>

          {/* Botão de cadastro */}
          <Row>
            <Col md={12} className="mb-3">
              <Button variant="success" type="submit" style={{ width: '40%' }}>
                Cadastrar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  )
}

export default UserRegistration

export async function getNiveisDeAcesso(setNiveisDeAcesso, rota_base, tenantId) {
  try {
    const dados = {
      pagina1: 'nivel_acesso', // Nome da coleção no MongoDB
      query: { rota_base: rota_base, tenantId: tenantId }
    }

    const response = await requisitar(dados)

    if (response && Array.isArray(response)) {
      const niveisDeAcesso = response.map(nivel => ({
        label: nivel.nome,
        value: nivel.id
      }))

      setNiveisDeAcesso(niveisDeAcesso)
    } else {
      console.error('Erro ao buscar dados 2:', response)
      setNiveisDeAcesso([])
    }
  } catch (error) {
    console.error('Erro ao buscar níveis de acesso:', error)
    setNiveisDeAcesso([])
  }
}
//-------------------------------------------------------------------------------------------------
function getIdNivelAcesso(niveis_acesso, nomeAcesso) {
  // Encontrar o objeto que corresponde ao nome do acesso
  const acesso = niveis_acesso.find(acesso => acesso.label === nomeAcesso)

  // Se o objeto for encontrado, retorna o valor da chave 'value'
  // Caso contrário, retorna null ou algum valor padrão
  return acesso ? acesso.value : null
}
