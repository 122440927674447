/* eslint-disable no-useless-escape */
import dayjs from 'dayjs'
import { useState } from 'react'

export function mapearColunasParaGravar(colunasParaGravarSheet, todasColunasSheet) {
  const colunas = todasColunasSheet.map(nome => normalizeKey(nome.trim())) //tira os espaços em branco , tirar acentos e colocar tudo em caixa baixa

  const mapeamentoColunas = {}

  colunasParaGravarSheet.forEach(campo => {
    const campoNormalizado = normalizeKey(campo.trim())

    const index = colunas.indexOf(campoNormalizado)

    mapeamentoColunas[campoNormalizado] = index >= 0 ? index + 1 : undefined
  })

  return mapeamentoColunas
}

function normalizeKey(key) {
  return key
    .normalize('NFD') // Adicionado para remover acentos
    .replace(/[\u0300-\u036f]/g, '') // Adicionado para remover acentos
    .replace(/\s+/g, '') // Adicionado para remover espaços em branco
    .toLowerCase() // Adicionado para deixar tudo em caixa baixa
}

//-------------------------------------------------
export function removeEmptyItems(obj) {
  const newObj = {}

  for (const key in obj) {
    // Verifica se é objeto mas NÃO é Date
    if (typeof obj[key] === 'object' && !(obj[key] instanceof Date) && obj[key] !== null) {
      newObj[key] = removeEmptyItems(obj[key])
    } else if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

export function validateAndShowAlerts(messages, showAlertFunction) {
  let isValid = true

  for (let key in messages) {
    if (messages[key]) {
      showAlertFunction(key, true)
      isValid = false
    }
  }

  if (!isValid) {
    // Exibir os alerts por 2 segundos
    setTimeout(() => {
      for (let key in messages) {
        showAlertFunction(key, false)
      }
    }, 2000)
  }

  return isValid
}

export const NomeSobrenomeEmpresaClientes = clientesData => {
  if (!Array.isArray(clientesData)) {
    return []
  }
  return clientesData.map(cliente => {
    let { nome, sobrenome, empresa } = cliente
    nome = nome !== undefined ? nome.trim() : ''
    sobrenome = sobrenome !== undefined ? sobrenome.trim() : ''
    empresa = empresa !== undefined ? empresa.trim() : ''

    let label = [nome, sobrenome, empresa].filter(Boolean).join(' ')
    label = label.replace('undefined', '').replace(/ -$/, '')

    return {
      value: cliente.id,
      label: label !== '' ? label : 'Nome não informado'
    }
  })
}

export function mapProdutosToOptions(isLoadingProdutos, produtos) {
  if (!isLoadingProdutos && produtos && produtos.length) {
    return produtos.map(produto => ({
      value: produto.id,
      label: produto.nome
    }))
  }
  return []
}

export function mapCategoriasToOptions(isLoadingCategorias, categorias) {
  if (!isLoadingCategorias && categorias && categorias.length) {
    return categorias.map(categoria => ({
      value: categoria.id_categoria,
      label: categoria.nome_categoria
    }))
  }
  return []
}
export function mapGrausToOptions(isLoadingGraus, graus) {
  if (!isLoadingGraus && graus && graus.length) {
    return graus.map(grau => ({
      value: grau.id,
      label: grau.grau
    }))
  }
  return []
}

export function atualizarDadosNaoEditados(dadosEdicao, cortes, editandoCorte) {
  const corte = cortes.find(c => c.id === editandoCorte)

  Object.keys(corte).forEach(key => {
    // Verifica se a chave existe em ambos os objetos
    if (Object.hasOwn(dadosEdicao, key) && Object.hasOwn(corte, key)) {
      // Apenas restaura o valor se o campo estiver `undefined` ou `null`, mas não quando é um valor falsy intencional como ''
      if (dadosEdicao[key] === undefined || dadosEdicao[key] === null) {
        dadosEdicao[key] = corte[key]
      }
    }
  })

  return dadosEdicao
}

export const pegaDado = (dados, idProcurado, dadoRetorno, idDado) => {
  if (!dados || !Array.isArray(dados)) {
    return null
  }

  const objetoEncontrado = dados.find(obj => {
    return String(obj[idDado]) === String(idProcurado)
  })

  return objetoEncontrado ? objetoEncontrado[dadoRetorno] : null
}

export const pegaIdCliente = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.id_cliente : null
}
export const pegaIdTecido = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.id_tecido : null
}
export const pegaFolhas = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.folhas : null
}
export const pegaDescricao = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.descricao : null
}
export const pegaValorPago = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.pago : null
}
export const pegaValorDoCorte = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.valor_do_corte : null
}
export const pegaValorAreceber = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.a_receber : null
}
export const pegaTotalPecas = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.total_pecas : null
}
export const pegaTecido = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
  return objetoEncontrado ? objetoEncontrado.tecido : null
}
export const pegaCliente = (dados, idProcurado) => {
  const objetoEncontrado = dados.find(obj => obj.id === idProcurado)

  if (!objetoEncontrado || !objetoEncontrado.cliente) {
    return null
  }

  let nome
  if (objetoEncontrado.cliente.includes('|')) {
    // Extrai o nome do cliente até o primeiro '|'
    nome = objetoEncontrado.cliente.split('|')[0]
  } else {
    // Extrai o nome do cliente até o primeiro '-'
    nome = objetoEncontrado.cliente.split('-')[0]
  }

  // Retorna o nome sem espaços no início ou no final
  return nome.trim()
}
//-------------------------------------------------------------------------------
export function useDynamicState(initialStateOrKey = {}) {
  const initialState =
    typeof initialStateOrKey === 'string' ? { [initialStateOrKey]: '' } : initialStateOrKey
  const [state, setState] = useState(initialState)

  // Agora `setDynamicState` pode atualizar um campo ou muitos
  const setDynamicState = (keyOrObject, value) => {
    if (typeof keyOrObject === 'object') {
      // Se um objeto for fornecido, mescla com o estado anterior
      setState(prevState => ({ ...prevState, ...keyOrObject }))
    } else {
      // Caso contrário, atualize a chave específica com o valor fornecido
      setState(prevState => ({ ...prevState, [keyOrObject]: value }))
    }
  }

  return [state, setDynamicState]
}
//----------------------------------------------------------------------
export const showAlertFunction = (setAlerts, key, value) => {
  setAlerts(prev => ({ ...prev, [key]: value }))
}

function isSameDay(date1, date2) {
  return dayjs(date1).isSame(dayjs(date2), 'day')
}
export function getValidationMessages(fields, state, camposEspeciais, itensLength, selectedDates) {
  if (!fields || !Array.isArray(fields) || !state) {
    return {}
  }

  let messages = fields.reduce((acc, field) => {
    if (field.validate) {
      acc[field.label] = !state[field.label] ? `Complete o campo ${field.label.toLowerCase()}` : ''
    }

    if (selectedDates?.prevista && isSameDay(selectedDates?.prevista, new Date())) {
      // messages['dataPrevista'] =
      //   'A data prevista é igual à data atual. Deseja continuar?';
    }

    const retorno = mergeValidationMessages(acc, camposEspeciais)
    return retorno
  }, {})

  if (itensLength === 0) {
    messages['modelagem'] = 'Selecione alguma modelagem'
  }

  // Nova validação para verificar se a data prevista é igual à data atual

  return messages
}

//----------------------------------------------------------------------
function mergeValidationMessages(objMessages, arrMessages) {
  const newMessages = { ...objMessages }

  arrMessages.forEach(key => {
    newMessages[key] = `Complete o campo ${key}`
  })

  return newMessages
}
//---------------------------------------------------------------------------------------
export const handleSelectedClienteChange = (selectedOption, setSelectedCliente) => {
  setSelectedCliente(selectedOption)
}

export function sumByKey(list, key, nestedKey = null) {
  if (!list) return 0

  if (nestedKey === 'itens') {
    return list.reduce((acc, item) => {
      if (Array.isArray(item[nestedKey])) {
        return (
          acc +
          item[nestedKey].reduce(
            (innerAcc, innerItem) => innerAcc + (Number(innerItem[key]) || 0),
            0
          )
        )
      }
      return acc
    }, 0)
  } else {
    return list.reduce((acc, item) => acc + (Number(item[key]) || 0), 0)
  }
}
export const customStyles = {
  singleValue: (provided, state) => {
    return state.isFocused || state.hasValue
      ? { ...provided, fontSize: '35px' } // Aumenta a fonte quando o item está focado ou tem um valor
      : { ...provided }
  }
}

export const validateEmail = email => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
//---------------------------------------------------------------------------------
export const validateTelefone = telefone => {
  // Remove caracteres não numéricos para a validação
  const cleanTelefone = telefone.replace(/\D/g, '')

  // Verifica se o número tem 10 ou 11 dígitos (formato comum no Brasil)
  return cleanTelefone.match(/^(?:\d{10}|\d{11})$/)
}
//---------------------------------------------------------------------------------
export function converterPreco(preco) {
  if (typeof preco === 'string') {
    return parseFloat(preco.replace(',', '.'))
  }
  return preco
}
//---------------------------------------------------------------------------------
export function formatarComoMoeda(valor) {
  return Number(valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}
